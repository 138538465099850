import React, { useEffect, useRef, useState } from "react";
import BootstrapAccordion from "../BootstrapComponents/Accordion/BootstrapAccordion";
import { getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import SetupField, { LabelWithHelpIcon } from "../ReactGridComponents/SetupSheetWindow/SetupField";
import SetupSheetWindow from "../ReactGridComponents/SetupSheetWindow/SetupSheetWindow";
import { SetupSheetWindowsWithPassedData } from "../ReactGridComponents/Body/SetupSheetPanel/SetupSheetPanel";
import { ExpandIcon, LinkIcon, TrashIcon, XIcon } from "../BootstrapComponents/Icons/Icons";
import { updateChangeData } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { RemoveCircle } from "@mui/icons-material";
import { useTrackedState } from "../../utils/store";
import { sortByReference } from "../../utils/Referencing";

const HarvestedObjectView = ({
	obj,
	hierarchyRecord,
	updateRow,
	removeObject: _removeObject,
	nodeClicked: _nodeClicked,
	dataUpdates,
}) => {
	const [mfi, setMfi] = useState([]);

	const hierarchyRecordRef = useRef(hierarchyRecord);

	const sharedState = useTrackedState();

	useEffect(() => {
		//Load the objects MFI and set the fields
		getMfi();
	}, [obj.uuid, obj.versionUuid]);

	useEffect(() => {
		let { subObjectUpdates, hierarchyUpdates } = sharedState.subObjectUpdates;
		if (!subObjectUpdates) return;

		//For each update check if we have a matching row in this components data
		subObjectUpdates.forEach((update) => {
			let match = mfi.find((row) => row.uuid === update.uuid);
			if (match) {
				match.title = update.title;
				match.versionUuid = update.versionUuid;
				match.value = update.value;
			}

			if (obj.uuid === update.uuid) {
				obj.title = update.title;
				obj.versionUuid = update.versionUuid;
				obj.value = update.value;

				//Update hierarchy records
				hierarchyRecordRef.current = hierarchyUpdates.find(
					(row) => row.descendantStandardObjectUuid === obj.uuid
				);
			}
		});
	}, [sharedState.subObjectUpdates?.subObjectUpdates, sharedState.subObjectUpdates?.subObjectUpdates?.length]);

	const getMfi = async () => {
		let res = await getSingleLevelObjectMfi(obj.uuid, obj.versionUuid, null, null, {
			...obj,
			objectHierarchy: [hierarchyRecordRef.current],
		});

		if (res) setMfi(res);
	};

	const nodeClicked = (e, row) => {
		if (obj.isObject) {
			if (e.stopPropagation) e.stopPropagation();
			if (row) _nodeClicked(row);
			else _nodeClicked(obj.uuid);
		}
	};

	const titleChanged = (newTitle) => {
		if (obj.title === newTitle) return;

		obj.title = newTitle;

		updateRow(newTitle);
	};

	const removeObject = () => {
		_removeObject(obj);
	};

	const mfiUpdates = (update) => {
		let uuidsToRemove = [];
		if (update.deletedRows) uuidsToRemove = update.deletedRows.map((row) => row.uuid);

		uuidsToRemove = [...uuidsToRemove, ...update.objectRows.map((row) => row.uuid)];
		//Update the mfi with the update passed in?
		setMfi([...mfi.filter((row) => !uuidsToRemove.includes(row.uuid)), ...update.objectRows].sort(sortByReference));
	};

	return (
		<BootstrapAccordion
			titles={[
				<LabelWithHelpIcon
					reference={obj.reference}
					title={obj.title}
					description={obj.description}
					labelStyles={{ marginBottom: "0px" }}
				/>,
			]}
			object={obj}
			editTitleButton={true}
			titleLink={true}
			titleLinkClicked={nodeClicked}
			deleteClicked={removeObject}
			handleBlur={titleChanged}
			// activeIndex={0}
		>
			{[
				//Render each field as a setup field
				<SetupSheetWindowsWithPassedData
					top={obj}
					mfi={mfi}
					updateRow={(row) => console.log("update row", row)}
					harvestedWindow={true}
					mfiUpdates={mfiUpdates}
					dataUpdates={dataUpdates}
					nodeSelected={nodeClicked}
				/>,
			]}
		</BootstrapAccordion>
	);
};

export default HarvestedObjectView;
