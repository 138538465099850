import React, { useState, useEffect } from "react";
import Panel from "../../Panel/Panel";
import ReactGrid from "../../../ReactGrid/ReactGrid";
import BasicTreeWithSearch from "../../../Tree/BasicTreeWithSearch";
import { getSingleLevelObjectMfi } from "../../../../utils/ApiUtils";
import { useDispatch, useTrackedState } from "../../../../utils/store";
import { updateChangeData } from "../NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { v4 as uuidv4 } from "uuid";

/**
 * Renders a panel with a grid inside of it
 * @param
 * @constructor
 */
const DestinationPanel = ({}) => {
	//state variables
	const [dataWarehouseMFI, setDataWarehouseMFI] = useState([]);

	//Global state used to store the existing objects that can be shared across components
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	return (
		<Panel
			panelTitle={"Destination"}
			menuItems={[{ id: "destination-add-panel", label: "Add Panel", onClick: "" }]}
			panelGroup={"main"}
		>
			<ReactGrid
				layout={[
					//Accessible Models Panel
					{
						x: 0,
						y: 0,
						h: 1,
						w: 420,
						dynamicHeight: true,
						resizable: true,
						isBounded: true,
						i: "b0acca9f-e570-44d6-a599-30ed3029e050",
					},
				]}
				dynamicHeight={true}
				draggableHandle={".panelHeader.destination"}
				draggable={sharedState.resizePanels && !sharedState.fullScreenVisualRep}
			>
				<div key={"b0acca9f-e570-44d6-a599-30ed3029e050"}>
					<AccessibleModelsPanel>
						<BasicTreeWithSearch
							data={sharedState.destinationModel}
							treeTitle={"Models"}
							treeProps={{
								droppable: false,
								draggable: true,
								origin: "models-panel",
								getMdMfiOnDrop: true,
								getObjMfiOnDrop: true,
								allowSmallRef: true,
								showVersions: true,
							}}
						/>
					</AccessibleModelsPanel>
				</div>
				<div key={"4cb3c770-1ea1-4723-aab6-c9eec2d5d79c"}>
					<DataWarehousePanel>
						<BasicTreeWithSearch
							data={dataWarehouseMFI}
							treeTitle={"Data Warehouse"}
							treeProps={{
								droppable: false,
								draggable: true,
								origin: "datawarehouse-panel",
								showVersions: true,
								getObjMfiOnDrop: true,
								addChangedRows: (data) => {
									//TODO - VERIFY
									updateChangeData(dispatch, {
										mfiRows: data.filter((row) => row.mfiRow),
										objectRows: data.filter((row) => !row.mfiRow),
									});
								},
								onDoubleClick: (e, row) => {
									dispatch({ type: "OPEN_OBJECT_FOR_EDITING", data: row });
								},
								// getObjectCopyToPutInDataWarehouse: createObjectCopyToPutInWarehouse
							}}
						/>
					</DataWarehousePanel>
				</div>
			</ReactGrid>
		</Panel>
	);
};

export default DestinationPanel;

export const DestinationModelTree = ({ rowSelected, allowDrop = false }) => {
	const [data, setData] = useState([]);

	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	useEffect(() => {
		let { destinationModel } = sharedState;
		if (destinationModel) setData(destinationModel);
	}, [sharedState.destinationModel, sharedState.destinationModel?.length]);

	// const addChangedRows = (rows) => {
	//     if(!allowDrop)
	//         return;
	//
	//     updateChangeData(
	//         dispatch,
	//         {
	//             mfiRows: rows.filter(row => row.mfiRow),
	//             objectRows: rows.filter(row => !row.mfiRow),
	//             objectHierarchy: rows.objectHierarchy,
	//         }
	//     );
	// };

	return (
		<BasicTreeWithSearch
			data={data}
			treeTitle={"Models"}
			treeProps={{
				droppable: allowDrop,
				draggable: true,
				origin: "models-panel",
				getMdMfiOnDrop: true,
				getObjMfiOnDrop: true,
				allowSmallRef: true,
				showVersions: true,
				onDoubleClick: (e, row) => {
					dispatch({
						type: "OPEN_OBJECT_FOR_EDITING",
						data: {
							...row,
							uuid: row.standardObjectUuid,
							versionUuid: row.standardObjectVersionUuid,
						},
					});
				},
			}}
			searchMfiForObjects={true}
			rowSelected={rowSelected}
		/>
	);
};

export const DestinationObjectPanel = ({}) => {
	const [destination, setDestination] = useState({});
	const [mfi, setMfi] = useState([]);

	const sharedState = useTrackedState();

	useEffect(() => {
		let { contextDestinationObject: destinationObject } = sharedState;

		//If we have a new destination grab the mfi and update the state
		if (
			destinationObject?.uuid !== destination.uuid ||
			destinationObject?.verisonUuid !== destination.versionUuid
		) {
			//I'm not sure if I can do this, but I'll assume that the destination object will always be a top that resides in the reference manual, so no attachment point or anything
			getSingleLevelObjectMfi(destinationObject.uuid, destinationObject.versionUuid).then((rows) => setMfi(rows));
			setDestination(destinationObject);
		}
	}, [sharedState.contextDestinationObject?.uuid, sharedState.sharedState.contextDestinationObject?.versionUuid]);

	return (
		<BasicTreeWithSearch
			data={mfi}
			treeTitle={destination.title}
			treeProps={{
				droppable: false,
				draggable: true,
				origin: "models-panel",
				getMdMfiOnDrop: true,
				getObjMfiOnDrop: true,
				allowSmallRef: true,
				showVersions: true,
			}}
		/>
	);
};

const AccessibleModelsPanel = ({ children, ...other }) => {
	return (
		<Panel
			panelTitle={"Destination Model"}
			menuItems={[{ id: "destination-model-add-panel", label: "Add Panel", onClick: "" }]}
			panelGroup={"destination"}
		>
			{children}
		</Panel>
	);
};

const DataWarehousePanel = ({ children, ...other }) => {
	return (
		<Panel
			panelTitle={"Data Warehouse"}
			menuItems={[{ id: "data-warehouse-add-panel", label: "Add Panel", onClick: "" }]}
			panelGroup={"destination"}
		>
			{children}
		</Panel>
	);
};
