import {
	ArchiveIcon,
	BinderIcon,
	BoxIcon,
	CalendarIcon,
	CheckboxIcon,
	CodeSlashIcon,
	CubeIcon,
	FileIcon,
	FileTextIcon,
	FileUploadIcon,
	FolderIcon,
	FormulaIcon,
	LetterIcon,
	LinkIcon,
	ListIcon,
	NumberIcon,
	PlusIcon,
	RegexIcon,
	RichTextIcon,
} from "../BootstrapComponents/Icons/Icons";
const TreeNodeIcon = ({ row }) => {
	// If the row is an object, it should have an archive icon
	if (row?.isPacket)
		return (
			<span style={{ paddingRight: "5px" }}>
				<BinderIcon width={"16px"} height={"16px"} />
			</span>
		);

	if (row?.isObject) {
		return (
			<span style={{ paddingRight: "5px" }}>
				<CubeIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	// row.isObject does not tell whether something is an object while in the MFI so we have to get creative and find out by checking if it has a sourceObjectTemplateUuid while at the same time not have a value TODO fix the isObject so it's consistent
	// If the row is an object and it has children, it should have a special archive icon that indicates it is an object, but also has children TODO
	if (row?.sourceObjectTemplateUuid && row?.value === undefined && row.hasChildren) {
		return (
			<span style={{ paddingRight: "5px" }}>
				<CubeIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	// If the row is an object, it should have an archive icon
	if (row?.sourceObjectTemplateUuid && row?.value === undefined) {
		return (
			<span style={{ paddingRight: "5px" }}>
				<CubeIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	// If the row has children, it should have a folder icon
	if (row?.hasChildren) {
		return (
			<span style={{ paddingRight: "5px" }}>
				<FolderIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	// If the row has a setup type, set the icon the match the setup type
	if (row?.inputType === "file-upload") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<FileUploadIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "date") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<CalendarIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "list") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<PlusIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "link") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<LinkIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "source-code") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<CodeSlashIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "include-special-characters") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<RegexIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "excel-spreadsheet") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<FormulaIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "drop-down") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<ListIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "alpha-numeric") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<LetterIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "numeric") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<NumberIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "text-area") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<FileTextIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "rich-text-area") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<RichTextIcon width={"14px"} height={"14px"} />
			</span>
		);
	}
	if (row?.inputType === "check-box") {
		return (
			<span style={{ paddingRight: "5px" }}>
				<CheckboxIcon width={"14px"} height={"14px"} />
			</span>
		);
	}

	// If no conditions match, there should be a generic file icon
	return (
		<span style={{ paddingRight: "5px" }}>
			<FileIcon width={"14px"} height={"14px"} />
		</span>
	);
};

export default TreeNodeIcon;
