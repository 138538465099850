import React, { useEffect, useRef, useState } from "react";
import DraggableDialog from "./DraggableDialog/Dialog";

import "./ChangeRequestSelectionDialog.scss";
import BasicTreeWithSearch from "../Tree/BasicTreeWithSearch";
import HorizontalTabs from "../ReactGridComponents/HorizontalTabs/HorizontalTabs";
import RecursiveTreeView from "../Tree/Tree";
import { convertListToMap, convertListToRealJsMap } from "../../utils/StandardObject";

/**
 * This Dialog will be used to transfer things from one object to another. This is how we will attach an object and put different pieces in different places
 * This will probably evolve into our harvest dialog, but to start with it will only be for attaching packets to each other.
 * @param treeData1
 * @param treeData2
 * @param onTree2Drop
 * @param _open
 * @returns {JSX.Element}
 * @constructor
 */
export const TwoTreeDialog = ({ treeData1, treeData2, saveChanges, cancel, open: _open }) => {
	const [open, setOpen] = useState(false);

	const changes = useRef([]);

	//On mount, grab the Data Warehouse MFI
	useEffect(() => {
		setOpen(_open);
	}, [_open]);

	const handleClose = () => {
		setOpen(false);
		cancel();
	};

	const handleSave = () => {
		setOpen(false);
		//Call the save passed in, it should accept the rows being added / changed in the second MFI
		saveChanges(convertListToRealJsMap(changes.current));
	};

	const addChangedRows = (rows) => {
		console.log("rows changed", rows);
		changes.current = [...changes.current, ...rows];
	};

	return (
		<>
			<DraggableDialog
				style={{ minHeight: "900px", minWidth: "1200px" }}
				PaperProps={{ style: { minWidth: "1200px", width: "75%", minHeight: "700px" } }}
				header={`Attach ${treeData1[0]?.title} to ${treeData2[0]?.title}`}
				showDialog={open}
				handleClose={handleClose}
				handleSave={handleSave}
				saveButtonText={"Save"}
			>
				<div className={"d-flex"} style={{ height: "100%" }}>
					<RecursiveTreeView
						data={treeData1}
						topNode={treeData1[0]}
						topNodeId={treeData1[0]?.uuid}
						treeTitle={"Tree 1"}
						droppable={false}
						draggable={true}
						getObjMfiOnDrop={false}
						origin={"harvest-left"}
						treeHeight={500}
						treeDivStyles={{
							boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
							padding: "15px",
							height: "100%",
						}}
					/>
					<RecursiveTreeView
						data={treeData2}
						topNode={treeData2[0]}
						topNodeId={treeData2[0]?.uuid}
						treeTitle={"Tree 2"}
						droppable={true}
						draggableIds={treeData1.map((row) => row.uuid)}
						getObjMfiOnDrop={false}
						addChangedRows={addChangedRows}
						origin={"harvest-right"}
						treeHeight={500}
						treeDivStyles={{
							boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
							padding: "15px",
							height: "100%",
						}}
					/>
				</div>
			</DraggableDialog>
		</>
	);
};
