import { useState, useEffect, useRef } from "react";
import { formatDate } from "../../../utils/DateUtils";

/**
 *
 * @param
 * @constructor
 */
const BootstrapInput = ({
	label,
	placeholder,
	value,
	handleChange,
	handleBlur,
	type = "text",
	focus = false,
	invalid = false,
	defaultValue,
	inputStyles,
	isDisabled = false,
	classes,
	...other
}) => {
	//state variables
	const [val, setVal] = useState("");
	const [inputType, setInputType] = useState(type === "date" ? "text" : type);

	const inputRef = useRef({});
	const valRef = useRef("");

	useEffect(() => {
		setVal(value);
	}, [value]);

	useEffect(() => {
		if (focus) {
			inputRef.current.focus();
			inputRef.current.select();
		}
	}, [focus]);

	//Other Methods

	return (
		<>
			{label}
			<input
				className={`form-control ${invalid ? "is-invalid" : ""} ${classes}`}
				ref={inputRef}
				type={type === "date" ? inputType : type}
				disabled={isDisabled}
				placeholder={placeholder}
				value={type === "date" && inputType === "text" && val ? formatDate(val) : val}
				onFocus={() => {
					if (type === "date") setInputType("date");
					if (!val && defaultValue) {
						valRef.current = defaultValue;
						handleChange(defaultValue);
					}

					//TODO: I cant remember why this was here, it was intended to hit a field that didnt get the value when it first rendered so it couldnt select anything
					// setTimeout(() => {
					// 	inputRef.current.select();
					// }, 0);
				}}
				style={{
					padding: "12px",
					...inputStyles,
				}}
				onChange={(e) => {
					let newVal = e.currentTarget.value;
					valRef.current = newVal;
					// if (!newVal && valRef.current) newVal = valRef.current;
					setVal(newVal);

					if (handleChange) handleChange(newVal);
				}}
				onBlur={(e) => {
					if (!handleBlur) return;
					//This case should only happen when default value is, when clicking through the fields fast, the update doesn't have time to make it back to val before the blur is called
					if (!val && valRef.current) handleBlur(valRef.current);
					else handleBlur(val);

					if (type === "date") setInputType("text");
				}}
				{...other}
			/>
		</>
	);
};

export default BootstrapInput;

export const Checkbox = ({ label, value, focus, handleChange, isDisabled }) => {
	const [val, setVal] = useState(false);
	const inputRef = useRef({});

	useEffect(() => {
		if (value) {
			if (typeof value === "string") setVal(value === "true");
			else if (typeof value === "boolean") setVal(value);
		}
	}, []);

	useEffect(() => {
		if (focus) inputRef.current.focus();
	}, [focus]);

	const checkboxChange = (e) => {
		setVal(!val);
		handleChange(!val);
	};

	return (
		<>
			{label ? label : ""}
			<input
				ref={inputRef}
				type={"checkbox"}
				className={` ${isDisabled ? "is-invalid" : ""}`}
				checked={val}
				onChange={checkboxChange}
				disabled={isDisabled}
			/>
		</>
	);
};
