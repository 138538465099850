import React, { useState } from "react";
import { getSmallRef } from "../../utils/StringUtils";
import Version from "../VersionControl/Version";
import { CopyIcon, DownArrowIcon, ExclamationTriangle } from "../BootstrapComponents/Icons/Icons";

export const CardSelector = ({ items }) => {
	const [selected, setSelected] = useState(null);

	const sourceClicked = (row) => {
		if (row === selected) setSelected(null);
		else setSelected(row);

		if (row.onClick) row.onClick(row);
	};

	return (
		<div style={{ display: "flex", justifyContent: "start" }}>
			{items?.length > 0
				? items.map((item, index) => (
						<div
							key={item.uuid + item.versionUuid}
							className={`possible-source card ${selected === item ? "selected" : ""}`}
							style={{
								width: "18rem",
								cursor: "pointer",
								marginRight: "15px",
							}}
							onClick={() => sourceClicked(item)}
							title={`${item.title} was used to create ${item.current?.title}`}
						>
							<div className="card-body">
								<div
									className="card-title h5 d-flex text-center"
									style={{ justifyContent: "space-between", paddingBottom: "15px" }}
								>
									<span
										className={"text-danger"}
										title={item.mfiReference || item.reference}
										style={{ width: "60px" }}
									>
										{getSmallRef(item.mfiReference || item.reference)}
									</span>
									{item.currentObject ? "Current Object: " + item.title : item.title}
									<Version
										version={{ objectVersionNumber: item.computerVersion }}
										classes={"text-end"}
										styles={{
											width: "60px",
											display: "block",
										}}
									/>
								</div>
								{item.current ? (
									<>
										<div
											style={{ display: "flex", justifyContent: "center" }}
											className={"text-secondary"}
										>
											<CopyIcon />
											<DownArrowIcon />
										</div>
										<div
											className="card-text d-flex text-center"
											style={{
												opacity: ".8",
												paddingTop: "15px",
												justifyContent: "space-between",
											}}
										>
											<span
												className={"text-danger"}
												title={item.current?.mfiReference || item.current?.reference}
												style={{ width: "60px" }}
											>
												{getSmallRef(item.current?.mfiReference || item.current?.reference)}
											</span>
											{item.current?.title}
											<Version
												version={{
													objectVersionNumber: item.current?.computerVersion,
												}}
												classes={"text-end"}
												styles={{
													width: "60px",
													display: "block",
												}}
											/>
										</div>
										{item.newerVersion ? (
											<>
												<div
													className="card-text text-danger d-flex"
													style={{
														paddingTop: "20px",
														alignItems: "center",
													}}
												>
													<span style={{ paddingRight: "15px" }}>
														<ExclamationTriangle width={32} />
													</span>
													<span>
														{" "}
														Please update to latest version of {item.title}{" "}
														<Version
															version={{
																objectVersionNumber: item.newerVersion.computerVersion,
															}}
														/>{" "}
														before suggesting a change
													</span>
												</div>
												<div style={{ textAlign: "right", paddingTop: "10px" }}>
													<btn
														className="btn btn-outline-dark"
														onClick={(e) => item.secondaryClick(e, item.newerVersion)}
													>
														Review latest version
													</btn>
												</div>
											</>
										) : (
											""
										)}
									</>
								) : (
									""
								)}
							</div>
						</div>
				  ))
				: ""}
		</div>
	);
};
