import React, { useState, useEffect, useRef, createContext } from "react";
import { useArray } from "../../../../utils/useStickyArray";
import { useDispatch, useTrackedState } from "../../../../utils/store";
import { getCall, getUrl } from "../../../../utils/ApiUtils";
import {
	ASSOCIATED_OBJECT_GENERAL_TYPE_UUID,
	buildSetupSheetTree,
	buildSetupSheetWithOwnerMfi,
	checkForSubObject,
	copyStandardObject,
	getChangesForObject,
	getDbConst,
	getGlobalObjectHierarchy,
	getObjectIdAndVersionUuid,
	getSetupForms,
	getSetupSheets,
	getStateChanges,
	HARVESTED_OBJECT_GENERAL_TYPE,
	linkAttributeName,
	linkVersionAttributeName,
	loadRelatedObject,
	loadSubObject,
	setupNodeTitle,
} from "../../../../utils/StandardObject";
import { convertListToLeavesWithOwner, updateTree } from "../../../../utils/TreeUtils";
import { INPUT_FIELD_TYPES } from "../../../../utils/SetupTypes";
import { getNextRef, insertRowAndReRef, sortByReference } from "../../../../utils/Referencing";
import Panel from "../../Panel/Panel";
import ReactGrid from "../../../ReactGrid/ReactGrid";
import HorizontalTabs from "../../HorizontalTabs/HorizontalTabs";
import RecursiveTreeView from "../../../Tree/Tree";
import SetupSheetWindow, { SetupSheetWindowWithData } from "../../SetupSheetWindow/SetupSheetWindow";
import SetupForms from "../../../PropertiesForm/SetupForms";
import { _updateRow, updateChangeData } from "../NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { getUntrackedObject } from "react-tracked";
import { useIndexedDB } from "@slnsw/react-indexed-db";
import { allowedToEditHarvestedObjects, getTopMostObject } from "../CreatorPanel/CreatorPanel";
import { v4 as uuidv4 } from "uuid";
import EmptyPanelMessage from "../../Panel/EmptyPanelMessage";
import { useSearchParams } from "react-router-dom";
import harvestedObjectView from "../../../HarvestedObjectView/HarvestedObjectView";

export const SetupSheetContext = createContext();
export const SelectedNodeContext = createContext();

const SetupSheetPanel = ({}) => {
	const [setupForms, setSetupForms] = useState([]);

	const selectedNode = useRef({});
	const hierarchy = useRef([]);
	const [queryParams, setQueryParams] = useSearchParams();

	const {
		add: addAttachmentPoint,
		getAll: getAttachmentPoints,
		update: updateAttachmentPoint,
		deleteRecord: deleteAttachmentPoint,
	} = useIndexedDB("attachmentPoints");

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		retrieveSetupForms();
	}, []);

	useEffect(() => {
		let { row, multiClick, replaceUuid } = sharedState.selectedWorkspaceRow;
		if (row || multiClick || replaceUuid)
			updateSelectedPropertyWindows(sharedState, dispatch, row, multiClick, replaceUuid);
	}, [
		sharedState.selectedWorkspaceRow.row,
		sharedState.selectedWorkspaceRow.multiClick,
		sharedState.selectedWorkspaceRow.replaceUuid,
	]);

	useEffect(() => {
		hierarchy.current = sharedState.contextObjectHierarchy;
	}, [sharedState.contextObjectHierarchy?.length]);

	const retrieveSetupForms = async () => {
		if (sharedState.setupForms && sharedState.setupForms.length > 0) {
			return sharedState.setupForms;
		}

		if (!sharedState.setupForms || sharedState.setupForms.length < 1) {
			let forms = await getSetupForms();
			setSetupForms(forms);
			dispatch({ type: "SET_SETUP_FORMS", data: forms });
			return forms;
		}
	};

	const toggleProperties = () => {
		dispatch({ type: "TOGGLE_SHOW_SETUP_SHEETS" });
	};

	const runFullScreen = () => {
		dispatch({ type: "TOGGLE_FULL_SCREEN_VISUAL_REP", data: true });
	};

	/**
	 * Updates fields that need shown on the setup sheet
	 *
	 */
	const nodeClicked = async (node, origin) => {
		if (selectedNode.current.uuid !== node.uuid) {
			selectedNode.current = node;
		}

		if (
			node.objectTypeUuid === sharedState.dbConstants.dataSourceObject.referenceUuid ||
			(origin === "tree" && node.generalTypeUuid === HARVESTED_OBJECT_GENERAL_TYPE)
		)
			return;

		let associatedGeneralType = await getDbConst("ASCOBJ", "associatedObjectType", sharedState, dispatch);

		//Check if this setup node is a sub-object, if it is we want to grab it and attach it to the mfi
		let hierarchyRecord = checkForSubObject(node, hierarchy.current);
		if (!hierarchyRecord) hierarchyRecord = checkForSubObject(node, sharedState.contextFullObjectHierarchy);

		if (hierarchyRecord) {
			let child = sharedState.contextMfi.find((row) => row.parentUuid === node.uuid);
			//If there's not a child then load the sub-object
			if (!child) {
				//Base everything off of Model, App, Object Workspace IDE view. This will need to change once we are doing things from multiple views
				let topUuidAndVersion;

				if (sharedState.contextAncestorObjects && sharedState.contextAncestorObjects.length > 0)
					topUuidAndVersion = getObjectIdAndVersionUuid(sharedState.contextAncestorObjects[0]);
				else topUuidAndVersion = getObjectIdAndVersionUuid(sharedState.contextTop);

				let { objects: changes, objectHierarchy } = getStateChanges(sharedState, topUuidAndVersion);
				let hierarchy = getGlobalObjectHierarchy(sharedState);

				if (changes) changes = getChangesForObject(changes, node, hierarchy, true);
				else changes = null;

				await loadSubObject(
					node,
					hierarchyRecord,
					sharedState,
					dispatch,
					node.relationshipTypeUuid === sharedState.dbConstants.objDefRel.referenceUuid,
					{
						addAttachmentPoint,
						getAttachmentPoints,
						updateAttachmentPoint,
						deleteAttachmentPoint,
					},
					changes,
					setQueryParams
				);
				return;
			}
		} else if (node.generalTypeUuid === associatedGeneralType) {
			hierarchyRecord = sharedState.contextObjectHierarchy.find(
				(row) => row.descendantStandardObjectUuid === node[linkAttributeName]
				//TODO: For the checklist links the linkToObjectVersion doesn't always match so we stopped using it, can we do the same here?
				// && row.descendantStandardObjectVersionUuid === node[linkVersionAttributeName]
			);

			await loadRelatedObject(
				hierarchyRecord,
				node,
				sharedState,
				dispatch,
				false,
				{
					addAttachmentPoint,
					getAttachmentPoints,
					updateAttachmentPoint,
					deleteAttachmentPoint,
				},
				setQueryParams
			);
		}
	};

	const setupTree = <SetupSheetTree nodeClicked={nodeClicked} />;
	const setupWindows = <SetupSheetWindowsFromGlobalState nodeClicked={nodeClicked} />;

	return (
		<Panel
			panelTitle={"Setup Sheets View"}
			menuItems={
				sharedState.contextTop.generalTypeUuid !== HARVESTED_OBJECT_GENERAL_TYPE ||
				allowedToEditHarvestedObjects(sharedState, true)
					? [
							{
								id: "setup-sheets-toggle-properties",
								label: "Toggle Properties",
								onClick: toggleProperties,
							},
							// { id: "setup-sheets-full-screen", label: "Full Screen", onClick: runFullScreen },
							// { id: "setup-sheets-add-panel", label: "Add Panel", onClick: "" },
					  ]
					: []
			}
		>
			<ReactGrid
				layout={[
					{
						x: 0,
						y: 0,
						h: 1,
						w: 840,
						i: "fd3446ef-45d9-4609-85ec-96f2ae00bc97",
					},
				]}
				dynamicHeight={true}
			>
				<div key={"fd3446ef-45d9-4609-85ec-96f2ae00bc97"}>
					{sharedState.showSetupSheets ? (
						<SetupSheetTabs setupTree={setupTree} setupSheetWindows={setupWindows} />
					) : (
						""
					)}

					{sharedState.showSetupSheets ? (
						""
					) : (
						<SetupForms
							data={sharedState.contextMfi}
							// updateRow={updateRow}
							className={"setup-form-parent"}
							developer={true}
							// addChangedRows={(changes) => {
							//     setReRenderMfiTree(prev => !prev);
							//     updateChangeData(dispatch, {objectRows: changes});
							//     // addChangedRows(sharedState.contextTop, changes);
							// }}
						/>
					)}
				</div>
			</ReactGrid>
		</Panel>
	);
};

export default SetupSheetPanel;

const SetupSheetTree = ({ nodeClicked }) => {
	const [setupData, setSetupData] = useState({});
	const [activeSheet, setActiveSheet] = useState("");
	const [selectSetupNode, setSelectSetupNode] = useState({});

	const selectedNode = useRef({});

	const loadingObject = useRef(false);
	const [queryParams, setQueryParams] = useSearchParams();

	const {
		add: addAttachmentPoint,
		getAll: getAttachmentPoints,
		update: updateAttachmentPoint,
		deleteRecord: deleteAttachmentPoint,
	} = useIndexedDB("attachmentPoints");

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		updateSetupDataFromGlobalState();
	}, []);

	useEffect(() => {
		let { setupSheetActiveTitle } = sharedState;
		if (setupSheetActiveTitle) {
			if (setupSheetActiveTitle !== activeSheet) {
				// getSetupSheetData(setupSheetActiveTitle)
				setActiveSheet(setupSheetActiveTitle);
				updateSetupDataFromGlobalState();
			}
		}
		//Otherwise reset the setupData and the activeSheet
		else resetSetupTree();
	}, [sharedState.setupSheetActiveTitle]);

	useEffect(() => {
		updateSetupDataFromGlobalState();
	}, [sharedState.setupSheetData, sharedState.setupSheetData[activeSheet]]);

	useEffect(() => {
		if (
			sharedState.selectedWorkspaceRow?.uuid &&
			sharedState.selectedWorkspaceRow.uuid !== selectedNode.current?.uuid &&
			(sharedState.selectedWorkspaceRow?.setupSheets?.includes(activeSheet) ||
				sharedState.selectedWorkspaceRow?.indeterminateSetupSheets?.includes(activeSheet))
		)
			setupNodeClicked(sharedState.selectedWorkspaceRow, false, false, false, true);
		else selectedNode.current = {};
	}, [sharedState.selectedWorkspaceRow, sharedState.selectedWorkspaceRow?.uuid]);

	const resetSetupTree = () => {
		setSetupData([]);
		setActiveSheet("");
		selectedNode.current = {};
		// dispatch({ type: "UPDATE_SETUP_SHEET_INFO", data: { attribute: "ActiveTitle", data: "" } });
	};

	const updateSetupDataFromGlobalState = () => {
		let { setupSheetData, setupSheetActiveTitle } = sharedState;
		if (setupSheetData) {
			if (setupSheetData[setupSheetActiveTitle] !== setupData)
				setSetupData(setupSheetData[setupSheetActiveTitle]);
			else {
				let data = setupSheetData[setupSheetActiveTitle];
				if (data && data.length !== setupData?.length) setSetupData(data);
			}
		}
		//Otherwise reset the setupData and the activeSheet
		else resetSetupTree();
	};

	/**
	 * Updates fields that need shown on the setup sheet
	 *
	 */
	const setupNodeClicked = async (node, metaKey, shiftKey, doubleClick, updateTree) => {
		let { selectedWorkspaceRow } = sharedState;
		if (node.uuid === selectedWorkspaceRow.uuid) {
			if (updateTree && selectedNode.current.uuid !== node.uuid) {
				selectedNode.current = node;
				setSelectSetupNode(node);
			}
			return;
		} else {
			selectedNode.current = node;
			dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: node });
		}

		nodeClicked(node, "tree");
	};

	return (
		<RecursiveTreeView
			data={setupData}
			treeTitle={sharedState.contextTop?.title || "Object MFI"}
			topNode={sharedState.contextTop}
			// getObjMfiOnDrop={true}
			topNodeId={sharedState.contextTop?.uuid}
			// droppable={true}
			// draggable={true}
			// editable={true}
			className={`tree`}
			// refField={'setup' + activeSetupSheet.title + 'Reference'}
			origin={"setup-panel"}
			rowSelected={setupNodeClicked}
			rowToSelect={selectSetupNode}
			// ref={templateTreeContainer}
		/>
	);
};

/**
 * The Setup Sheet Windows Container that pulls the data from the Global Shared State
 * @constructor
 */
const SetupSheetWindowsFromGlobalState = ({ nodeClicked }) => {
	const [top, setTop] = useState({});
	const [mfi, setMfi] = useState([]);
	const [selectedNodes, setSelectedNodes] = useState([]);

	const mfiRef = useRef([]);
	const setupSheetTrees = useRef({});

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		let { contextTop, contextMfi } = sharedState;
		if (contextTop.uuid !== top.uuid) setSelectedNodes([]);

		//Set this after getting the setup sheet data
		// if (contextTop) setTop({ ...contextTop });
		// if (contextMfi) setMfi([...contextMfi]);

		mfiRef.current = contextMfi;
		buildSetupSheetData(contextTop, contextMfi);
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid, sharedState.contextTop?.versionUuid]);

	useEffect(() => {
		let { selectedWorkspaceRow } = sharedState;
		if (selectedWorkspaceRow?.parentUuid !== selectedNodes[0]?.uuid)
			setSelectedNodes([selectedWorkspaceRow.parentUuid]);
		else setSelectedNodes([]);
	}, [sharedState.selectedWorkspaceRow, sharedState.selectedWorkspaceRow?.uuid]);

	const mfiUpdates = ({ objectRows = [], deletedRows = [] }) => {
		let idsToRemove = [...objectRows.map((row) => row.uuid), ...deletedRows.map((row) => row.uuid)];
		//Update the MFI based on the updated and deleted rows
		objectRows.forEach((row) => sharedState.contextMfi.push(row));

		dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				mfi: [...mfiRef.current.filter((row) => !idsToRemove.includes(row.uuid)), ...objectRows].sort(
					sortByReference
				),
			},
		});
	};

	const nodeSelected = (row) => {
		dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: row });
		nodeClicked(row);
	};

	const buildSetupSheetData = async (contextTop, contextMfi) => {
		//Get the setup sheet data
		let result = await getSetupSheetData(
			contextTop,
			contextMfi,
			sharedState.setupSheetActiveTitle,
			sharedState,
			dispatch
		);

		let data;
		//If we don't get anything back reset everything
		if (!result) {
			data = {};
		} else data = result.data;

		//Update the setup sheet data
		setupSheetTrees.current = data;

		setTop({ ...contextTop });
		setMfi([...contextMfi]);
	};

	return (
		<SetupSheetWindowsWithPassedData
			top={top}
			mfi={mfiRef.current}
			updateRow={(row) => console.log("update row", row)}
			defaultTopLevelSelected={false}
			selectedNodes={selectedNodes}
			mfiUpdates={mfiUpdates}
			nodeSelected={nodeSelected}
			setupSheetTrees={setupSheetTrees.current}
			mfiVersion={sharedState.contextMfiVersion}
		/>
	);
};

export const SetupSheetWindowsWithPassedData = ({
	top,
	mfi,
	selectedNodes: _selectedNodes,
	mfiUpdates,
	nodeSelected,
	mfiVersion,
	harvestedWindow = false,
	setupSheetTrees,
}) => {
	const {
		array: selectedNodes,
		set: setSelectedNodes,
		update: replaceSelectedNode,
		remove: removeSelectedNode,
	} = useArray([]);
	const [setupMfi, setSetupMfi] = useState([]);

	//The currently active setup sheet title
	const activeTitle = useRef("");
	const setupSheetData = useRef({});
	const builtSetupSheet = useRef({});
	const contextTop = useRef({});
	const contextMfi = useRef([]);

	const [queryParams, setQueryParams] = useSearchParams();

	const {
		add: addAttachmentPoint,
		getAll: getAttachmentPoints,
		update: updateAttachmentPoint,
		deleteRecord: deleteAttachmentPoint,
	} = useIndexedDB("attachmentPoints");

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (top?.uuid && top?.versionUuid && mfi.length > 0) {
			contextTop.current = top;
			contextMfi.current = mfi;
			buildSetupSheetData();
		} else resetData();
	}, [top?.uuid, top?.versionUuid, mfi.length, mfiVersion]);

	//Whenever the setup sheet active index changes, change the data shown for this window
	useEffect(() => {
		let { setupSheetActiveTitle } = sharedState;
		activeTitle.current = setupSheetActiveTitle;
		if (setupSheetTrees) updateSetupSheetState(setupSheetTrees, activeTitle.current);
		else updateSetupSheetState(setupSheetData, activeTitle.current);
	}, [sharedState.setupSheetActiveTitle]);

	useEffect(() => {
		if (_selectedNodes) setSelectedNodes(_selectedNodes);
	}, [_selectedNodes]);

	const resetData = () => {
		setSetupMfi([]);
		activeTitle.current = "";
		setupSheetData.current = {};
	};

	const buildSetupSheetData = async () => {
		//Update the active setup sheet title
		activeTitle.current = sharedState.setupSheetActiveTitle;

		if (setupSheetTrees) {
			updateSetupSheetState(setupSheetTrees, activeTitle.current);
		} else if (
			builtSetupSheet.current.uuid === top.uuid &&
			builtSetupSheet.current.versionUuid &&
			builtSetupSheet.current.mfiLength === mfi.length
		)
			return;

		builtSetupSheet.current = top;
		builtSetupSheet.current.mfiLength = mfi.length;

		//Get the setup sheet data
		let result = await getSetupSheetData(
			top,
			mfi,
			activeTitle.current,
			sharedState,
			!harvestedWindow ? dispatch : undefined
		);

		//If we don't get anything back reset everything
		if (!result) {
			resetData();
			return;
		}

		//Update the setup sheet data
		let { data } = result;
		updateSetupSheetState(data, activeTitle.current);
		//What should we do if there is no data for the active setup sheet?
	};

	const updateSetupSheetState = (setupSheetMap, activeTitle) => {
		if (setupSheetMap && activeTitle && setupSheetMap.hasOwnProperty(activeTitle)) {
			let selected = setupSheetMap[activeTitle];
			if (harvestedWindow) setSelectedNodes([selected[1].uuid]);
			setSetupMfi([...selected]);
		} else resetData();
	};

	/**
	 * Replaces the window clicked with the object from the breadcrumb clicked
	 *
	 */
	const breadcrumbClicked = (uuid, windowUuid) => {
		let index = selectedNodes.indexOf(windowUuid);
		if (uuid) {
			replaceSelectedNode(index, uuid);
			let row = mfi.find((row) => row.uuid === uuid);
			dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: { parentUuid: uuid } });
		} else removeSelectedNode(index);
	};

	const dataUpdates = ({ objectRows, hierarchyRecords, subObjectToUpdate, deletedRows }) => {
		let update = {
			subObjectToUpdate,
			objectRows,
			objectHierarchy: hierarchyRecords,
			deletedRows,
			primusRows: true,
		};

		//If the change is coming from a sub-object add that to the update if its not already there.
		if (!subObjectToUpdate && contextTop.current.uuid !== sharedState.contextTop.uuid)
			update.subObjectToUpdate = contextTop.current;

		updateChangeData(dispatch, update);
	};

	return (
		<>
			{selectedNodes.length > 0 ? (
				<>
					{selectedNodes.map((uuid) => (
						<div
							key={uuid + sharedState.setupSheetActiveTitle}
							style={
								!harvestedWindow
									? {
											borderRadius: "4px",
											padding: "8px",
											boxShadow: "0px 0px 15px #b5b5b5",
											height: "100%",
									  }
									: {}
							}
						>
							<SetupSheetWindowWithData
								top={top}
								data={setupMfi}
								windowUuid={uuid}
								setupSheet={activeTitle.current}
								context={top}
								breadcrumbClicked={breadcrumbClicked}
								subObjectWindow={true}
								openSubObject={nodeSelected}
								dataUpdates={dataUpdates}
								mfiUpdates={mfiUpdates}
								hierarchy={mfi[0].objectHierarchy}
								// addCopiedRows={addCopiedRows}
								// setupSheetMap={ownerToDescendantMap.current}
							/>
						</div>
					))}
				</>
			) : (
				<div
					key={"8ad7def9-4190-4730-ad31-8cd461795f78"}
					style={{
						fontSize: "40px",
						textAlign: "center",
						color: "rgb(181, 181, 181)",
						fontWeight: "200",
						margin: "100px auto",
						height: "200px",
						position: "relative",
					}}
				>
					{harvestedWindow ? "No items found on the" : "Select an item in the"}
					<br />
					<span style={{ textDecoration: "underline" }}>
						{/*{objectWorkspacePanels.SETUP_FORMS.title}*/}
						{sharedState.setupSheetActiveTitle}
					</span>{" "}
					Setup Sheet
				</div>
			)}
		</>
	);
};

const SetupSheetTabs = ({ setupTree, setupSheetWindows }) => {
	const [editMode, setEditMode] = useState(false);
	const [sheetNames, setSheetNames] = useState([]);
	const [activeTab, setActiveTab] = useState(0);

	const activeTitle = useRef("");
	const builtSetupTreeFor = useRef({});
	const setupSheetConst = useRef("");

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		// if(sharedState.contextMfiVersion === 0 ) getSetupSheet(sharedState.setupSheetActiveTitle);
		if (sharedState.contextMfi?.length > 0) getSetupSheet(sharedState.setupSheetActiveTitle);
		else resetTabs();
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid, sharedState.contextTop?.versionUuid]);

	useEffect(() => {
		let { setupSheetData } = sharedState;
		if (Object.keys(setupSheetData).length < 1) resetTabs();
		else {
			let keys = Object.keys(setupSheetData);

			if (sheetNames.toString() !== keys.toString() || !activeTitle.current) {
				setSheetNames(keys);
				activeTitle.current = keys[0];
				setActiveTab(0);
			}
		}
	}, [sharedState.setupSheetData]);

	const resetTabs = () => {
		setEditMode(false);
		setSheetNames([]);
		setActiveTab(0);
		builtSetupTreeFor.current = {};
	};

	const updateActiveTitle = (title) => {
		dispatch({ type: "UPDATE_SETUP_SHEET_INFO", data: { attribute: "ActiveTitle", data: title } });
	};

	const updateSelectedSetupNodes = (nodes) => {
		dispatch({ type: "UPDATE_SETUP_SHEET_INFO", data: { attribute: "SelectedNodes", data: nodes } });
	};

	/**
	 * Builds the setup sheet for the mfi passed in
	 */
	const getSetupSheet = async (setupSheetActiveTitle) => {
		let { contextTop: top, contextMfi: mfi } = sharedState;

		let result = await getSetupSheetData(top, mfi, setupSheetActiveTitle, sharedState, dispatch);
		//If we don't get anything back reset the tabs
		if (!result) resetTabs();

		builtSetupTreeFor.current = {
			uuid: top.uuid,
			versionUuid: top.versionUuid,
		};

		//Update the active title if its different
		if (result.activeTitle && result.activeTitle !== setupSheetActiveTitle) updateActiveTitle(result.activeTitle);
	};

	const addNewSetupTab = (activeTab) => {
		let mfi = [...getUntrackedObject(sharedState.contextMfi)];
		let key = sheetNames[activeTab];

		let setupNode = mfi.find(
			(row) => row.title === setupNodeTitle && row.parentUuid === sharedState.contextTop.uuid
		);
		//TODO: What if that setup sheet isn't in the top row? It won't work to add a new one.
		let ss = mfi.find(
			(row) =>
				row.parentUuid === setupNode.uuid &&
				row.title === key &&
				row.objectTypeUuid === sharedState.dbConstants.setupSheetType.referenceUuid
		);
		if (!ss) {
			let setupChildren = mfi.filter((row) => row.parentUuid === setupNode.uuid);
			if (setupChildren.length > 1) ss = setupChildren[setupChildren.length - 2];
			else ss = setupChildren[0];
		}
		//Put the newSs right after the current ss
		let newRef = getNextRef(ss.reference);
		let newSs = copyStandardObject(ss, newRef.reference, ss.parentUuid);

		//Update title and type (titles must be unique in order to be separate, so we add some terms to try to force uniqueness
		newSs.title = `Tab # ${sheetNames.length + 1} ${key} (copy)`;
		newSs.objectTypeUuid = sharedState.dbConstants.setupSheetType.referenceUuid;

		//Insert the new ss updating the references of the rows getting moved
		let changes = insertRowAndReRef(ss, mfi, newSs);

		//Add the new setup sheet name
		sheetNames.splice(activeTab + 1, 0, newSs.title);

		//Update the active tab and sheet
		activeTitle.current = newSs.title;
		setActiveTab(activeTab + 1);

		//Reset selected nodes because we are in the new setup sheet
		updateSelectedSetupNodes([]);
		setEditMode(true);

		//Update each row that was on the copied setup sheet and add them to this new setup sheet
		let setupSheetRows = mfi.filter(
			(row) => row.setupSheets?.includes(key) || row.indeterminateSetupSheets?.includes(key)
		);
		setupSheetRows.forEach((row) => {
			let updated = [];
			if (row.setupSheets?.includes(key)) {
				updated = [...row.setupSheets, newSs.title];
				row.setupSheets = JSON.parse(JSON.stringify(updated));
			} else if (row.indeterminateSetupSheets?.includes(key)) {
				updated = [...row.indeterminateSetupSheets, newSs.title];
				row.indeterminateSetupSheets = JSON.parse(JSON.stringify(updated));
			}
		});

		//Update the global states mfi and the change data
		dispatch({ type: "SET_CONTEXT_OR_MFI", data: { mfi } });
		updateChangeData(
			dispatch,
			{ objectToUpdate: getTopMostObject(sharedState), objectRows: [...changes, ...setupSheetRows, newSs] },
			false,
			sharedState.currentUser
		);
	};

	const updateSetupSheetName = (newVal) => {
		let { contextMfi: mfi } = sharedState;
		let setupSheets = mfi.filter(
			(row) =>
				row.title === activeTitle.current &&
				row.objectTypeUuid === sharedState.dbConstants.setupSheetType.referenceUuid
		);
		setupSheets.forEach((row) => _updateRow(dispatch, row.uuid, "title", newVal));
		let index = sheetNames.indexOf(activeTitle.current);

		//Update all the rows that were on this particular setup sheet
		let rowsOnSetupSheet = mfi.filter(
			(row) =>
				row.setupSheets?.includes(activeTitle.current) ||
				row.indeterminateSetupSheets?.includes(activeTitle.current)
		);
		rowsOnSetupSheet.forEach((row) => {
			if (row.setupSheets?.includes(activeTitle.current)) {
				let ind = row.setupSheets.indexOf(activeTitle.current);
				row.setupSheets.splice(ind, 1, newVal);
			} else if (row.indeterminateSetupSheets?.includes(activeTitle.current)) {
				let ind = row.indeterminateSetupSheets.indexOf(activeTitle.current);
				row.indeterminateSetupSheets.splice(ind, 1, newVal);
			}
		});
		sheetNames[index] = newVal;
		activeTitle.current = newVal;

		updateActiveTitle(newVal);

		updateChangeData(dispatch, { objectToUpdate: getTopMostObject(sharedState), objectRows: rowsOnSetupSheet });
	};

	const setupAttributeCheckboxToggled = (rowData, checkbox, checked) => {
		let { contextMfi: mfi } = sharedState;
		let descendants = mfi.filter((row) => row.reference.startsWith(rowData.reference) && row.uuid !== rowData.uuid);
		let ancestors = mfi.filter((row) => rowData.ancestors?.includes(row.uuid));
		let changes = [];
		if (checked) {
			descendants.forEach((desc) => {
				let setupSheets = [];
				if (desc.setupSheets && desc.setupSheets.length > 0) {
					setupSheets = [...new Set([...desc.setupSheets, activeTitle.current])];
				} else {
					setupSheets = [activeTitle.current];
				}
				//Parse it so we don't have a proxy
				setupSheets = JSON.parse(JSON.stringify(setupSheets));

				desc.setupSheets = setupSheets;
				changes.push(desc);
				// _updateRow(dispatch, desc.uuid, 'setupSheets', setupSheets);
			});
			ancestors.forEach((row) => {
				let updated = [];
				if (row.indeterminateSetupSheets && row.indeterminateSetupSheets.length > 0)
					updated = [...new Set([...row.indeterminateSetupSheets, activeTitle.current])];
				else updated = [activeTitle.current];

				//For some reason its a proxy?
				updated = JSON.parse(JSON.stringify(updated));
				row.indeterminateSetupSheets = updated;
				changes.push(row);
			});

			let updatedSetupSheets = [];
			if (rowData.setupSheets && rowData.setupSheets.length > 0)
				updatedSetupSheets = [...new Set([...rowData.setupSheets, activeTitle.current])];
			else updatedSetupSheets.push(activeTitle.current);

			//Update the row
			rowData.setupSheets = JSON.parse(JSON.stringify(updatedSetupSheets));
			changes.push(rowData);
		} else {
			descendants.forEach((desc) => {
				let setupSheets = [];
				if (desc.setupSheets && desc.setupSheets.length > 0) {
					setupSheets = desc.setupSheets.filter((sheet) => sheet !== activeTitle.current);
				}
				desc.setupSheets = setupSheets;
				changes.push(desc);
				// _updateRow(dispatch, desc.uuid, 'setupSheets', setupSheets);
			});
			ancestors.forEach((ancestor) => {
				let checkedDescendants = mfi.filter(
					(row) =>
						row.uuid !== rowData.uuid &&
						row.uuid !== ancestor.uuid &&
						row.reference.startsWith(ancestor.reference) &&
						row.setupSheets?.includes(activeTitle.current)
				);
				if (checkedDescendants.length < 1) {
					if (ancestor.indeterminateSetupSheets) {
						ancestor.indeterminateSetupSheets = ancestor.indeterminateSetupSheets.filter(
							(ss) => ss !== activeTitle.current
						);
						changes.push(ancestor);
					}
					// _updateRow(dispatch, ancestor.uuid, 'indeterminateSetupSheets', ancestor.indeterminateSetupSheets);
				}
			});

			//Update the row
			if (rowData.setupSheets)
				rowData.setupSheets = rowData.setupSheets.filter((ss) => ss !== activeTitle.current);
			if (rowData.indeterminateSetupSheets)
				rowData.indeterminateSetupSheets = rowData.indeterminateSetupSheets.filter(
					(ss) => ss !== activeTitle.current
				);

			changes.push(rowData);
		}
		//I think we are ok just adding these to changed rows, rather than calling updateRow multiple times, because we already updating the row and nothing else should need to happen
		updateChangeData(
			dispatch,
			{ objectToUpdate: getTopMostObject(sharedState), objectRows: changes },
			false,
			sharedState.currentUser
		);
		// buildSetupSheet(sharedState.contextTop, sharedState.contextMfi);
	};

	const updateGlobalSetupSheetData = (setupSheetTitle) => {
		let { contextTop: top, contextMfi: mfi, setupSheetData } = sharedState;
		let updatedSheet = mfi.filter(
			(row) =>
				(row.setupSheets && row.setupSheets.indexOf(setupSheetTitle) > -1) ||
				(row.indeterminateSetupSheets && row.indeterminateSetupSheets.indexOf(setupSheetTitle) > -1)
		);

		setupSheetData[setupSheetTitle] = updatedSheet;
		dispatch({ type: "UPDATE_SETUP_SHEET_INFO", data: { attribute: "Data", data: setupSheetData } });
	};

	return (
		<>
			{sheetNames.length > 0 ? (
				<HorizontalTabs
					classes={!sharedState.showSetupSheets ? "d-none" : ""}
					headings={sheetNames}
					tabClicked={(tab) => {
						setActiveTab(tab);
						if (sheetNames[tab]) {
							activeTitle.current = sheetNames[tab];
							updateActiveTitle(activeTitle.current);
						}
					}}
					activeTab={activeTab}
					allowEditMode={sharedState.contextTop.generalTypeUuid !== HARVESTED_OBJECT_GENERAL_TYPE}
					allowAddMode={sharedState.contextTop.generalTypeUuid !== HARVESTED_OBJECT_GENERAL_TYPE}
					toggleEditMode={() => {
						if (editMode) updateGlobalSetupSheetData(activeTitle.current);
						setEditMode((prev) => !prev);
					}}
					addNewTab={addNewSetupTab}
				>
					{
						//Allows the user to select things that will show up on their setup sheet
						editMode ? (
							<div style={{ display: "flex", width: "100%", height: "100%" }}>
								<div style={{ width: "100%" }}>
									{/*Field for editing the Name of the Setup Sheet*/}
									{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
										label: "Tab Name",
										value: activeTitle.current,
										handleChange: () => {},
										handleBlur: updateSetupSheetName,
									})}
									<div style={{ marginTop: "20px", width: "100%", height: "100%" }}>
										<RecursiveTreeView
											data={sharedState.contextMfi}
											treeTitle={
												sharedState.contextTop ? sharedState.contextTop.title : "Object MFI"
											}
											topNode={sharedState.contextTop}
											topNodeId={sharedState.contextTop?.uuid}
											className={`tree`}
											checkboxes={["write"]}
											checkboxToggled={setupAttributeCheckboxToggled}
											origin={sheetNames[activeTab]}
										/>
									</div>
								</div>
							</div>
						) : (
							//This shows the setupSheet tree with the setup Windows
							<div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
								<div
									style={{
										borderRight: "1px solid #efefef",
										flexGrow: "1",
										minWidth: "400px",
									}}
								>
									{setupTree}
								</div>
								<div
									style={{
										paddingLeft: "12px",
										flexGrow: "1",
									}}
								>
									{setupSheetWindows}
								</div>
							</div>
						)
					}
				</HorizontalTabs>
			) : (
				<EmptyPanelMessage message={"No setup sheets found"} />
			)}
		</>
	);
};

/**
 * Set the selected row, triggered from the row selected event in the Tree Component
 * @param row
 */
export const updateSelectedPropertyWindows = (sharedState, dispatch, row, multiClick = false, replaceUuid) => {
	//Set the windowUuid = to the parent of the row clicked
	let windowUuid = row.parentUuid;

	let updatedOpenWindows = sharedState.openPropertyWindows;

	if (windowUuid === sharedState.contextTop.uuid && !updatedOpenWindows.includes("0")) windowUuid = "0";

	if (multiClick) {
		if (replaceUuid) {
			const index = updatedOpenWindows.indexOf(replaceUuid);
			if (windowUuid && !updatedOpenWindows.includes(windowUuid)) updatedOpenWindows.splice(index, 1, windowUuid);
			// addToOpenWindows(index, windowUuid);
			else updatedOpenWindows.splice(index, 1);
			// removeFromOpenWindows(index);
		} else if (!updatedOpenWindows.includes(windowUuid)) updatedOpenWindows = [...updatedOpenWindows, windowUuid];
		// openWindows.push(windowUuid);
	} else updatedOpenWindows = [windowUuid];
	// setOpenWindows([windowUuid]);

	//Update the array using splice

	dispatch({ type: "UPDATE_OPEN_PROPERTY_WINDOWS", data: updatedOpenWindows });

	dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: row });
	// setSelectedRow(row);
};

export const getSetupSheetType = async (sharedState, dispatch) => {
	return await getDbConst("SETUPSHEET", "setupSheetType", sharedState, dispatch);
};

/**
 * Builds the setup sheet for the mfi passed in
 */
const getSetupSheetData = async (top, mfi, activeTitle, sharedState, dispatch) => {
	//If we don't receive the active title then reset the data
	if (!activeTitle && sharedState.setupSheetData.length < 1 && sharedState.contextMfi < 1) return;

	let setupSheetType = await getSetupSheetType(sharedState, dispatch);

	let sheets;
	sheets = getSetupSheets(mfi, top, setupSheetType, {});

	if (sheets.length < 1 && dispatch) {
		await dispatch({ type: "UPDATE_SETUP_SHEET_INFO", data: { attribute: "Data", data: {} } });
		return;
	}

	if (!sheets.includes(activeTitle)) activeTitle = sheets[0];

	//Update the setup sheet data
	let theData = {};
	sheets.forEach(
		(key) =>
			(theData[key] = mfi.filter(
				(row) =>
					(row.setupSheets && row.setupSheets.indexOf(key) > -1) ||
					(row.indeterminateSetupSheets && row.indeterminateSetupSheets.indexOf(key) > -1)
			))
	);

	if (dispatch) await dispatch({ type: "UPDATE_SETUP_SHEET_INFO", data: { attribute: "Data", data: theData } });

	return { data: theData, activeTitle };
};
