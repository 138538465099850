import jsondiffpatch from "jsondiffpatch";

const DiffTree = (currentMfi, oldMfi) => {
	const diff = jsondiffpatch
		.create({
			objectHash: function (obj, index) {
				// try to find an id property, otherwise just use the index in the array
				return obj.uuid || "$$index:" + index;
			},
			propertyFilter: function (name, context) {
				return (
					name === "uuid" ||
					name === "title" ||
					name === "value" ||
					name === "reference" ||
					name === "inputType" ||
					name === "developerNotes" ||
					name === "objectTypeUuid" ||
					name === "description" ||
					name === "minCount" ||
					name === "maxCount" ||
					name === "defaultValue" ||
					name === "validator" ||
					name === "objectTypeUuid" ||
					name === "attachableTypes" ||
					name === "defaultValue"
				);
			},
		})
		.diff(oldMfi, currentMfi);
	return diff;
};

export default DiffTree;
