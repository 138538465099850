import BootstrapInput, { Checkbox } from "../components/BootstrapComponents/Input/BootstrapInput";
import React from "react";
import BootstrapTextarea from "../components/BootstrapComponents/Textarea/BootstrapTextarea";
import BootstrapSelect from "../components/BootstrapComponents/Select/BootstrapSelect";
import PlusIcon from "../components/BootstrapComponents/Icons/PlusIcon";
import { getCall, getUrl } from "./ApiUtils";
import LabelWithEditButton from "../components/BootstrapComponents/LabelWithEditButton/LabelWithEditButton";
import MultiEditableLabels from "../components/EditableLabel/MultiEditableLabels/MultiEditableLabels";
import CheckCircleToXIcon from "../components/BootstrapComponents/Icons/CheckCircleToXIcon";
import FileEarmarkIcon from "../components/BootstrapComponents/Icons/FileEarmarkIcon";
import { CloseIcon, FolderIcon, InfoIcon } from "../components/BootstrapComponents/Icons/Icons";
import EditableLabel from "../components/EditableLabel/EditableLabel";
import RichTextEditor from "../components/RichTextEditor/RichTextEditor";
import ReactEditor from "../components/ReactEditor/ReactEditor";
import AssociationSetupType from "../components/AssociationSetupType/AssociationSetupType";
import { Tooltip } from "@mui/material";
import { formatDate } from "./DateUtils";
import {
	FileUploaderSetupField,
	LabelWithHelpIcon,
} from "../components/ReactGridComponents/SetupSheetWindow/SetupField";
import { LinkButton } from "../components/BootstrapComponents/LinkButton";
import ReportDataSource from "../components/InputFieldTypes/ReportDataSource";
import HarvestedObjectView from "../components/HarvestedObjectView/HarvestedObjectView";

export const SETUP_TYPES = {
	NONE: {
		value: "none",
		label: "None",
	},
	VALUE: {
		value: "value",
		label: "Hard-Coded Value",
	},
	OBJECT_TITLE: {
		value: "objectTitle",
		label: "Object Title",
	},
	TEXT_AREA: {
		value: "textarea",
		label: "Text Area",
	},
	LINK: {
		value: "link",
		label: "Link",
	},
	DROP_DOWN: {
		value: "dropdown",
		label: "Dropdown",
	},
	LIST: {
		value: "list",
		label: "List",
	},
};

export const INPUT_FIELD_TYPE_ATTRIBUTE = "inputType";
export const INPUT_SOURCE_ATTRIBUTE = "inputSource";

/**
 *  To have this be successful, I need a couple things:
 *  1. A map of the different types of input fields i can have
 *  2. Each input field type should have a render or return function to render that type of input field
 *  3. Each input field type should have a list of the validations that are available for that input field type.
 *      Should this be one massive group of validations? Or should it be grouped by input field type?
 *      Should there be a more generic form of validation that the validations on eac h type extends?
 *      How would I define my validation object? What properties would it have? (We'll start with using  regex
 *      Validation:
 *           value to validate
 *            expression to validate against
 */
export const INPUT_FIELD_TYPES = {
	NONE: {
		value: "none",
		label: "None",
	},
	NUMERIC: {
		value: "numeric",
		label: "Numeric",
		allowLengthValidation: true,
		validations: [
			{ uuid: 1, title: "base", value: "^-?\\d+(\\.\\d*)?$" },
			{ uuid: 2, title: "isPositive", value: "^[+]?([.]\\d+|\\d+([.]\\d+)?)$" },
		],
		render: ({
			label,
			title,
			value,
			handleChange,
			handleBlur,
			invalid,
			description,
			focus = false,
			other,
			disabled = false,
		}) => (
			<BootstrapInput
				label={label}
				help={description}
				placeholder={title}
				value={value}
				handleChange={handleChange}
				handleBlur={handleBlur}
				type="number"
				invalid={invalid}
				focus={focus}
				isDisabled={disabled}
				{...other}
			/>
		),
	},

	ALPHA_NUMERIC: {
		value: "alpha-numeric",
		label: "Alpha Numeric",
		allowLengthValidation: true,
		validations: [],
		render: ({
			label,
			value,
			handleChange,
			handleBlur,
			invalid,
			description,
			focus = false,
			defaultValue = "",
			title,
			classes,
			disabled = false,
			...other
		}) => (
			<BootstrapInput
				label={label}
				placeholder={title}
				value={value}
				help={description}
				handleChange={handleChange}
				handleBlur={handleBlur}
				type="text"
				invalid={invalid}
				autoFocus={false}
				focus={focus}
				defaultValue={defaultValue}
				isDisabled={disabled}
				classes={classes}
				{...other}
			/>
		),
	},

	INCLUDE_SPECIAL_CHARACTERS: {
		value: "include-special-characters",
		label: "Include Special Characters",
		allowLengthValidation: true,
		validations: [{ uuid: 1, title: "base", value: "^[ A-Za-z0-9_@.!%^$*#&+-]*$" }],
		render: ({ label, value, handleChange, handleBlur, invalid, description, focus = false, disabled = false }) => (
			<BootstrapInput
				label={label}
				value={value}
				help={description}
				handleChange={handleChange}
				handleBlur={handleBlur}
				type="text"
				invalid={invalid}
				isDisabled={disabled}
				focus={focus}
			/>
		),
	},
	TEXT_AREA: {
		value: "text-area",
		label: "Text Area",
		allowLengthValidation: true,
		validations: [],
		render: ({
			label,
			title,
			value,
			handleChange,
			handleBlur,
			invalid,
			description,
			allowTabInside = false,
			focus = false,
			other,
			classes,
			disabled = false,
		}) => (
			<BootstrapTextarea
				label={label}
				value={value}
				placeholder={title}
				handleChange={handleChange}
				handleBlur={handleBlur}
				help={description}
				allowTab={allowTabInside}
				invalid={invalid}
				focus={focus}
				disabled={disabled}
				classes={classes}
				{...other}
			/>
		),
		renderMoreSetupFields: (setupValue, handleChange, handleBlur) => {
			return (
				<BootstrapTextarea
					label={"Setup Value"}
					placeholder={"Setup Value"}
					value={setupValue}
					handleChange={handleChange}
					handleBlur={handleBlur}
					divStyles={{ margin: "0px", paddingTop: "5px" }}
					allowTab={true}
				/>
			);
		},
	},
	CHECK_BOX: {
		value: "check-box",
		label: "Checkbox",
		validations: [],
		render: ({ label, value, handleChange, focus, disabled = false }) => (
			<Checkbox label={label} value={value} handleChange={handleChange} isDisabled={disabled} focus={focus} />
		),
	},
	RICH_TEXT_AREA: {
		value: "rich-text-area",
		label: "Rich Text Area",
		allowLengthValidation: true,
		validations: [],
		render: ({ label, value, handleChange, handleBlur, invalid, description, focus = false, disabled = false }) => (
			<RichTextEditor
				label={label}
				value={value}
				handleChange={handleChange}
				handleBlur={handleBlur}
				help={description}
				focus={focus}
				disabled={disabled}
			/>
		),
	},

	DROP_DOWN: {
		value: "drop-down",
		label: "Drop Down",
		allowLengthValidation: false,
		validations: [],
		render: ({
			label,
			value,
			optionGroups,
			handleChange,
			description,
			handleBlur,
			asyncMethodToLoadOptions,
			focus = false,
			other,
			disabled = false,
		}) => (
			<BootstrapSelect
				label={label}
				value={value}
				optionGroups={optionGroups}
				handleChange={handleChange}
				help={description}
				handleBlur={handleBlur}
				focus={focus}
				asyncMethodToLoadOptions={asyncMethodToLoadOptions}
				disabled={disabled}
				{...other}
			/>
		),
		renderMoreSetupFields: (
			uuid,
			setupOptions,
			updateDropdownValues,
			objectTypeValue,
			dropdownObjectTypeEditClick
		) => {
			return (
				<>
					<div className="col">
						<div className={"row"}>
							<div className={"col"} style={{ paddingTop: "12px" }}>
								<MultiEditableLabels
									uuid={uuid}
									label={"Dropdown Options"}
									values={setupOptions}
									update={updateDropdownValues}
								/>
							</div>
						</div>
					</div>
					<br />
					<div className="col">
						<LabelWithEditButton
							label={"Dropdown Object Type"}
							value={objectTypeValue}
							onClick={dropdownObjectTypeEditClick}
							rowStyle={{ paddingTop: "4px" }}
							multiSelect={true}
						/>
					</div>
				</>
			);
		},
	},
	REPORT_DATA_SOURCE: {
		value: "report-data-source",
		label: "Report Data Source",
		allowLengthValidation: false,
		validations: [],
		render: ({ field, label, updateRow, focus }) => (
			<ReportDataSource field={field} label={label} updateRow={updateRow} focus={focus} />
		),
		renderMoreSetupFields: (
			objectTypeUuid,
			objectTypeVersionUuid,
			filterUuid,
			cardinality,
			objectTypes,
			updateRow
		) => {
			return <></>;
		},
	},
	ASSOCIATION: {
		value: "association",
		label: "Object Association",
		allowLengthValidation: false,
		validations: [],
		render: (label, value, optionGroups, handleChange, focus = false) => (
			<div style={{ paddingLeft: "0px" }}>
				label
				{/*<BootstrapSelect*/}
				{/*    label={label}*/}
				{/*    value={value}*/}
				{/*    optionGroups={optionGroups}*/}
				{/*    handleChange={handleChange}*/}
				{/*    focus={focus}*/}
				{/*/>*/}
			</div>
		),
		renderMoreSetupFields: (
			objectTypeUuid,
			objectTypeVersionUuid,
			filterUuid,
			cardinality,
			objectTypes,
			updateRow,
			linkToSource
		) => {
			return (
				<AssociationSetupType
					objectTypeUuid={objectTypeUuid}
					objectTypeVersionUuid={objectTypeVersionUuid}
					filterUuid={filterUuid}
					cardinality={cardinality}
					objectTypes={objectTypes}
					updateRow={updateRow}
					linkToSource={linkToSource}
				/>
			);
		},
	},

	LIST: {
		value: "list",
		label: "List",
		allowLengthValidation: false,
		listLocationField: "setupListObjectLocationUuid",
		listDataWarehouseUuidField: "setupListObjectMfiUuid",
		validations: [],
		render: ({ label, plusClick, children, noPlus = false }) => (
			<>
				{label}
				{/*<Tooltip title={description}>*/}
				{/*	<span className="pb-4 ms-1" title={description}>*/}
				{/*		<InfoIcon color="black" />*/}
				{/*	</span>*/}
				{/*</Tooltip>*/}
				{!noPlus && <PlusIcon onClick={plusClick} />}
				{children}
			</>
		),
		renderMoreSetupFields: (
			listObjectClicked,
			setupListObject,
			onListObjectXClick,
			listObjectLocationClicked,
			setupListObjectLocation,
			listObjectLocationXClick
		) => {
			return (
				<div className="col flex">
					<div className={"col"}>
						<a
							className={""}
							onClick={listObjectClicked}
							style={{ cursor: "pointer", textDecoration: "none" }}
						>
							<div
								style={{
									fontSize: "35px",
									textAlign: "center",
									lineHeight: "35px",
									color: "rgb(181, 181, 181)",
									fontWeight: "200",
									margin: "20px auto",
									height: "auto",
									width: "90%",
									position: "relative",
									display: "flex",
									flexDirection: "column",
									backgroundColor: "whitesmoke",
									border: "solid 4px",
									borderRadius: "10px",
									padding: "20px",
								}}
							>
								{setupListObject.uuid ? (
									<>
										<span
											style={{ fontSize: "20px", lineHeight: "20px", fontWeight: 500 }}
										>{`${setupListObject.reference} - ${setupListObject.title}`}</span>
										<div style={{ position: "absolute", top: 0, right: "5px" }}>
											<CheckCircleToXIcon iconStyles={{}} onXClick={onListObjectXClick} />
										</div>
									</>
								) : (
									"Copy From"
								)}
								<span style={{ margin: "20px" }}>
									<FileEarmarkIcon />
								</span>
								<span style={{ fontSize: "20px", lineHeight: "18px" }}>
									Select object to create a list of
								</span>
							</div>
						</a>
					</div>
				</div>
			);
		},
	},

	EXCEL_SPREADSHEET: {
		value: "excel-spreadsheet",
		label: "Excel Spreadsheet (Under Construction)",
		allowLengthValidation: false,
		validations: [],
		render: () => <div>Excel Spreadsheet</div>,
	},
	DATE: {
		value: "date",
		label: "Date",
		allowLengthValidation: false,
		validations: [],
		render: ({
			label,
			value,
			handleChange,
			handleBlur,
			invalid,
			description,
			focus,
			defaultValue,
			title,
			other,
			classes,
			disabled = false,
		}) => {
			if (value) value = formatDate(value, true);
			return (
				<BootstrapInput
					label={label}
					placeholder={title}
					value={value}
					help={description}
					handleChange={(newVal) => {
						if (newVal) newVal = formatDate(newVal);
						handleChange(newVal);
					}}
					handleBlur={(newVal) => {
						if (newVal) newVal = formatDate(newVal);
						handleBlur(newVal);
					}}
					type="date"
					invalid={invalid}
					focus={focus}
					defaultValue={defaultValue}
					isDisabled={disabled}
					classes={classes}
					{...other}
				/>
			);
		},
		renderMoreSetupFields: () => {
			return <></>;
		},
	},
	FILE_UPLOAD: {
		value: "file-upload",
		label: "File Upload",
		allowLengthValidation: false,
		validations: [],
		render: ({
			label,
			updateRow,
			row,
			description,
			showUploader,
			uploadUsingUrl,
			submitFile,
			focus = false,
			disabled = false,
		}) => (
			<FileUploaderSetupField
				label={label}
				description={description}
				updateRow={updateRow}
				row={row}
				showUploader={showUploader}
				disabled={disabled}
				focus={focus}
				uploadUsingUrl={uploadUsingUrl}
				submitFile={submitFile}
			/>
		),
		renderMoreSetupFields: () => {
			return <></>;
		},
		checkIfFileAttribute: (attribute, sharedState) => {
			if (
				attribute &&
				(attribute.inputType === "file-upload-wopi" ||
					attribute.inputType === INPUT_FIELD_TYPES.FILE_UPLOAD.value ||
					attribute.objectTypeUuid === sharedState.dbConstants.fileObject.referenceUuid)
			)
				return true;
			else return false;
		},
	},
	LINK: {
		value: "link",
		label: "Link",
		allowLengthValidation: false,
		validations: [],
		render: ({ label, link, linkClick, removeLink, description }) => (
			<div className={"col"}>
				{label && (
					<label className="form-label" style={{ paddingLeft: "3px" }}>
						{label}
					</label>
				)}
				{description && (
					<Tooltip title={description}>
						<span className="pb-4 ms-1" title={description}>
							<InfoIcon color="black" />
						</span>
					</Tooltip>
				)}
				<a className={""} onClick={linkClick} style={{ cursor: "pointer", textDecoration: "none" }}>
					<div
						style={{
							fontSize: "35px",
							textAlign: "center",
							lineHeight: "35px",
							color: "rgb(181, 181, 181)",
							fontWeight: "200",
							margin: "auto",
							height: "auto",
							width: "90%",
							position: "relative",
							display: "flex",
							flexDirection: "column",
							backgroundColor: "whitesmoke",
							border: "solid 4px",
							borderRadius: "10px",
							padding: "20px",
						}}
					>
						{link.uuid ? (
							<>
								<span
									style={{ fontSize: "20px", lineHeight: "20px", fontWeight: 500 }}
								>{`Linked to: ${link.reference} - ${link.title}`}</span>
								<div style={{ position: "absolute", top: 0, right: "5px" }}>
									<CheckCircleToXIcon iconStyles={{}} onXClick={removeLink} />
								</div>
							</>
						) : (
							"Link To"
						)}
						<span style={{ margin: "20px" }}>
							<FileEarmarkIcon />
						</span>
						<span style={{ fontSize: "20px", lineHeight: "18px" }}>
							Click to select an object to link to
						</span>
					</div>
				</a>
			</div>
		),
		renderMoreSetupFields: () => {
			return <></>;
		},
	},
	SOURCE_CODE: {
		value: "source-code",
		label: "Source Code",
		allowLengthValidation: false,
		validations: [],
		render: ({ label, value, validate, description, handleBlur, components, methods }) => (
			<ReactEditor
				label={label}
				value={value}
				handleBlur={handleBlur}
				help={description}
				components={components}
				methods={methods}
			/>
		),
		renderMoreSetupFields: () => {
			return <></>;
		},
	},
};

/**
 * Method to validate a value against an array of regular expressions as strings
 */
export const validateInput = (value, { field, validations }) => {
	let theValidations = [];
	let isValid = true;
	if (field) {
		let validations = getValidations(field.inputType);
		theValidations = validations.filter(
			(validation) => field.validations.includes(validation.uuid) || validation.title === "base"
		);
	} else theValidations = validations;

	theValidations.forEach((validation) => {
		let regex = new RegExp(validation.value);
		if (!regex.test(value)) isValid = false;
	});
	return isValid;
};

// Grab the Sources from the object. I think we need a couple things before this can really function, a lot of it depends on the starting environment / model, I think
export const INPUT_FIELD_SOURCES = {
	HARD_CODED_VALUE: {
		value: "value",
		label: "Hard Coded Value",
		renderMoreSetupFields: (inputType, params) => {
			return INPUT_FIELD_TYPES[inputType].renderMoreSetupFields(...params);
		},
	},
	URL: {
		value: "url",
		label: "URL (Under Construction)",
	},

	TABLE: {
		value: "table",
		label: "Table (Under Construction)",
	},

	OBJECT_FIELD: {
		value: "object-field",
		label: "Object Field",
		renderMoreSetupFields: (value, onClick) => {
			return (
				<div className="col">
					<LabelWithEditButton
						label={"Link To"}
						value={value}
						onClick={onClick}
						rowStyle={{ paddingTop: "4px" }}
					/>
				</div>
			);
		},
	},
	OBJECT_OWNER_TITLE: {
		value: "object-owner-title",
		label: "Object Owner Title",
	},
	OBJECT_RELATIONSHIP: {
		value: "object-relationship",
		label: "Object Relationship",
	},
};

// Object Defaults
export const DEFAULT_TYPES = {
	COMMON_VALUES: {
		value: "common-values",
		label: "Common Values",
		url: "getCommonValues",
	},
	RECENT_VALUES: {
		value: "recent-values",
		label: "Recent Values",
		url: "getRecentValues",
	},
};

export const getValidations = (value) => {
	let type = Object.values(INPUT_FIELD_TYPES).find((type) => type.value === value);
	if (type && type.validations?.length) return type.validations;
	else return [];
};

export const getRenderElement = (value, params) => {
	let type = Object.values(INPUT_FIELD_TYPES).find((type) => type.value === value);
	if (type) return type.render(params);
	else return <span>Invalid Input Type</span>;
};

export const typeExists = (value) => {
	let type = Object.values(INPUT_FIELD_TYPES).find((type) => type.value === value);
	if (type) return true;
	else return false;
};

//Accepts the default type, standard object uuid of the field's owner and the title of the field
export const getDefaultOptions = (defaultType, ownerStandardObjectUuid, fieldTitle) => {
	let url = getUrl(DEFAULT_TYPES[defaultType].url, [ownerStandardObjectUuid, fieldTitle]);
	getCall(url).then((options) => options);
};

export const SETUP_SHEET_OUTPUT_FIELD_TYPES = {
	IS_OBJECT: {
		value: "is-object",
		label: "Navigates into an object",
		render: ({ child, folderIconClicked, handleBlur, removeChild, lastChild = false }) => (
			<div
				key={child.uuid}
				style={{
					height: "50px",
					background: "rgb(229, 242, 255)",
					display: "flex",
				}}
			>
				<button className="btn btn-link p-2" onClick={() => folderIconClicked(child.uuid)}>
					<FolderIcon />
				</button>
				<EditableLabel
					initialValue={child.title}
					uuid={child.uuid}
					placeholder={
						<LabelWithHelpIcon
							reference={child.reference}
							title={child.title}
							description={child.description}
						/>
					}
					onBlur={handleBlur}
					style={{
						color: "#4183c4",
					}}
					disableEdit={!handleBlur}
				/>
				{removeChild ? (
					<button className="btn btn-link p-2 ms-auto " onClick={() => removeChild(child)}>
						<CloseIcon color="red" width={24} height={24} />
					</button>
				) : (
					""
				)}
			</div>
		),
	},
	HARVESTED_OBJECT_VIEW: {
		value: "harvested-object",
		label: "Harvested Object View",
		render: ({ object, nodeClicked, hierarchyRecord, removeObject, updateRow }) => (
			<HarvestedObjectView
				obj={object}
				hierarchyRecord={hierarchyRecord}
				nodeClicked={nodeClicked}
				updateRow={updateRow}
				removeObject={removeObject}
			/>
		),
	},
	LINK: {
		value: "is-link",
		label: "Links to another object",
		render: ({ linkTo, navigateToLinkClicked, createLinkClicked, deleteLinkClicked, maxWidth, height }) => (
			<LinkButton
				linkTo={linkTo}
				navigateToLinkClicked={navigateToLinkClicked}
				createLinkClicked={createLinkClicked}
				deleteLinkClicked={deleteLinkClicked}
				maxWidth={maxWidth}
				height={height}
			/>
		),
	},
};
