import React from "react";
import "./Loader.scss";

/**
 * A loader for a loading screen, use css to hide and display
 * @param {show = false}
 * @constructor
 */
const Loader = ({ show = true }) => {
	let display = "none";
	if (show) display = "inline-block";

	return (
		<div id="loading" style={{ display }}>
			<div id="loading-center" className="">
				<div className="loader">
					<div className="assembly">
						<div className="comp-3d comp-3d--i">
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
							<div className="cube">
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
								<div className="cube__face"></div>
							</div>
						</div>
						<div className="comp-3d comp-3d--o">
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
							<div className="pos">
								<div className="cube">
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
									<div className="cube__face"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loader;

export const SimpleLoader = ({ loaderStyles = {} }) => {
	return (
		<div className="lds-ring" style={loaderStyles}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export const ColorfulLoader = ({}) => {
	return (
		<div className="colorful-loader">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
};

export const OtherLoader = () => {
	return (
		<div className="loading" align="center">
			<div className="main">
				<div className="small1">
					<div className="small-loader ball smallball1"></div>
					<div className="small-loader ball smallball2"></div>
					<div className="small-loader ball smallball3"></div>
					<div className="small-loader ball smallball4"></div>
				</div>

				<div className="small2">
					<div className="small-loader ball smallball5"></div>
					<div className="small-loader ball smallball6"></div>
					<div className="small-loader ball smallball7"></div>
					<div className="small-loader ball smallball8"></div>
				</div>

				<div className="bigcon">
					<div className="big ball"></div>
				</div>
			</div>
		</div>
	);
};
