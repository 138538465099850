import React, { useState, useEffect, useRef, useCallback } from "react";
import "@react-awesome-query-builder/ui/css/styles.css";
import "./Filter.scss";
import { LabelWithHelpIcon } from "../ReactGridComponents/SetupSheetWindow/SetupField";

/**
 * Stock # - 116-511210.228.RCFNCMPT.415101: 0 - React Functional Component
 * Renders a list with checkboxes down the side
 * @param {attributes, checkboxChanged, filter}
 * @constructor
 */
const CheckboxAndFilterList = ({ attributes, checked, updateAttributeFilter, filter = {} }) => {
	//state variables
	//Stock # - **NO STOCK # FOUND**: 04.04.01 - list
	const [list, setList] = useState([]);

	const attributeToFilter = useRef({});

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {}, []);

	useEffect(() => {
		//Iterate over attributes and filter, combine them into the one row
		setList(attributes);
	}, [attributes]);

	//Other Methods
	/**
	 *
	 * Called when a checkbox is clicked
	 * @param index, newVal
	 */
	const onCheckboxChange = (title, checked) => {
		updateAttributeFilter("checkbox", checked, title);
	};

	return (
		// <div>
		<ul className="list-group-flush">
			{list.map((item) => (
				<div
					key={item.uuid}
					className={"flex"}
					style={{
						justifyContent: "space-between",
						borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
					}}
				>
					<li className="list-group-item" style={{ display: "flex" }}>
						<input
							id={item.uuid}
							className="form-check-input me-1"
							style={{ paddingRight: "5px" }}
							type="checkbox"
							checked={checked[item.title] || item.checked}
							onChange={(e) => onCheckboxChange(item.title, e.currentTarget.checked)}
						/>
						<LabelWithHelpIcon
							labelFor={item.uuid}
							reference={item.reference}
							title={item.title}
							description={item.description}
							labelClass={"form-check-label stretched-link"}
						/>
					</li>
				</div>
			))}
		</ul>
		// </div>
	);
};

export default CheckboxAndFilterList;

const testQueryValue =
	// {id: QbUtils.uuid(), type: 'group'}
	{
		id: "9888b99b-4567-489a-bcde-f1863cde4490",
		type: "group",
		children1: [
			{
				type: "rule",
				id: "ab8abb9a-0123-4456-b89a-b1863ce04606",
				properties: {
					field: "Date Available to Work",
					operator: "equal",
					value: [new Date().toString()],
					valueSrc: ["value"],
					valueType: ["date"],
				},
			},
		],
	};
