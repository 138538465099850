import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import stylesModule from "./Dialog.module.scss";

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export default function DraggableDialog({
	id,
	showDialog,
	handleSave,
	handleClose,
	header,
	children,
	size,
	style,
	saveButtonText,
	cancelButtonText,
	cancelButton = true,
	closeOnLoseFocus = true,
	// { onClick: func(), type: "type", text: "text", closeDialog: false }
	additionalActions,
	PaperProps,
	formSave,
	contentStyles,
	disableSave = false,
	disableCancel = false,
	noButtons = false,
	saveButton = true,
	...other
}) {
	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		setOpen(showDialog);
	}, [showDialog]);

	const handleDialogClose = (e, eName, save) => {
		if (save && handleSave) handleSave();
		else handleClose(eName);
		setOpen(false);
	};

	return (
		<Dialog
			id={id}
			open={open}
			onClose={handleDialogClose}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
			className={`dialog ${stylesModule.grayGradientBackground} ${size || "large" + "-dialog"}`}
			style={style}
			disableEscapeKeyDown={!closeOnLoseFocus}
			PaperProps={PaperProps}
			{...other}
		>
			<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title" className={stylesModule.dialogTitles}>
				<span style={{ margin: "auto" }}>{header}</span>
				{cancelButton ? (
					<button
						onClick={(event) => {
							handleDialogClose(event, "closeClick");
						}}
						type="button"
						className="btn-close"
						style={{ float: "right", marginTop: "-5px" }}
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				) : (
					""
				)}
			</DialogTitle>
			<DialogContent className={stylesModule.grayGradientBackground} style={{ ...contentStyles }}>
				{children}
			</DialogContent>
			{!noButtons ? (
				<DialogActions className={stylesModule.dialogGradientBlueBackground}>
					{additionalActions
						? additionalActions.map((action) => {
								return (
									<button
										onClick={(event) => {
											action.onClick();
											if (action.closeDialog) handleDialogClose(event, action.type);
										}}
										style={action.style || {}}
										className={"btn btn-primary"}
									>
										{action.text}
									</button>
								);
						  })
						: ""}
					{cancelButton ? (
						<button
							autoFocus
							onClick={(event) => {
								handleDialogClose(event, "closeClick");
							}}
							className={"btn btn-outline-danger"}
							disabled={disableCancel}
						>
							{cancelButtonText ? cancelButtonText : "Cancel"}
						</button>
					) : (
						""
					)}
					{saveButton ? (
						formSave ? (
							<button
								type="submit"
								form={formSave}
								// onClick={event => {handleDialogClose(event, 'saveClick', true);}}
								// color="primary"
								className={"btn btn-primary"}
								disabled={disableSave}
							>
								{saveButtonText ? saveButtonText : "Save"}
							</button>
						) : (
							<button
								onClick={(event) => {
									handleDialogClose(event, "saveClick", true);
								}}
								// color="primary"
								className={"btn btn-primary"}
								disabled={disableSave}
							>
								{saveButtonText ? saveButtonText : "Save"}
							</button>
						)
					) : (
						""
					)}
				</DialogActions>
			) : (
				""
			)}
		</Dialog>
	);
}
