import { useState, useEffect, useRef } from "react";
import { getObjectIdAndVersionUuid } from "../../../utils/StandardObject";

/**
 * A select menu styled by bootstrap
 * @param {options, handleChange}
 * @constructor
 */
const BootstrapSelect = ({
	disabled = false,
	label,
	value,
	optionGroups,
	handleChange,
	handleBlur,
	focus = false,
	asyncMethodToLoadOptions,
	style,
	labelStyle = {},
	divStyle = {},
}) => {
	//state variables
	const [val, setVal] = useState("");
	const [options, setOptions] = useState([]);

	const selectRef = useRef({});
	const loadedObjects = useRef([]);

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (val !== value) setVal(value);
	}, [value, value?.length]);

	useEffect(() => {
		if (focus) selectRef.current.focus();
	}, [focus]);

	useEffect(() => {
		if (!optionGroups && asyncMethodToLoadOptions) loadAsyncOptions(asyncMethodToLoadOptions);
		else setOptions(optionGroups);
	}, [optionGroups, asyncMethodToLoadOptions]);

	//Other Methods
	/**
	 *
	 * @param: methodToCall: The method that should return the options for this select
	 */
	const loadAsyncOptions = async (methodToCall) => {
		let options = await methodToCall();
		loadedObjects.current = options;
		setOptions([
			{
				title: "Select",
				options: [
					{ label: "Choose...", value: "0" },
					...options.map((option) => ({ label: option.title, value: getObjectIdAndVersionUuid(option) })),
				],
			},
		]);
	};

	const handleSelectChange = (e) => {
		let newVal = e.currentTarget.value;
		setVal(newVal);

		//If we load the options asynchronously here, send the entire selected object back in case the update doesn't have it
		if (!optionGroups && asyncMethodToLoadOptions && newVal && newVal !== "0") {
			let split = newVal.split("/");
			let selected = loadedObjects.current.find((row) => row.uuid === split[0] && row.versionUuid === split[1]);
			handleChange(selected);
		} else handleChange(newVal);
	};

	return (
		<div style={{ ...divStyle, ...style }}>
			{label}
			<select
				ref={selectRef}
				className="form-select"
				onChange={handleSelectChange}
				value={val}
				disabled={disabled}
				style={{
					padding: "8px",
				}}
				onBlur={handleBlur}
			>
				{options.map((group) => (
					<optgroup key={group.title} label={group.title}>
						{group.options.map((option) => (
							<option key={option.label} value={option.value}>
								{option.label}
							</option>
						))}
					</optgroup>
				))}
			</select>
		</div>
		// <div className={`row ${surroundingDivClasses}`} style={style}>
		// 	<div className="col" style={{ ...colStyles }}>

		// 		{/*{help && (*/}
		// 		{/*	<Tooltip title={help}>*/}
		// 		{/*		<span className="pb-4 ms-1">*/}
		// 		{/*			<InfoIcon color="black" />*/}
		// 		{/*		</span>*/}
		// 		{/*	</Tooltip>*/}
		// 		{/*)}*/}
		//
		// 	</div>
		// </div>
	);
};

export default BootstrapSelect;
