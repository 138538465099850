import React, { useEffect, useRef, useState } from "react";
import TreeView from "./TreeViewVirtualized";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "./TreeItemVirtualized";
import {
	ASSOCIATED_OBJECT_GENERAL_TYPE_UUID,
	getObjectIdAndVersionUuid,
	shallowDiffers,
} from "../../utils/StandardObject";
import {
	deleteRow,
	fixReferences,
	getNextRef,
	getPrevRef,
	insertRowAndReRef,
	reRefOnManualReferenceChange,
	sortByReference,
} from "../../utils/Referencing";
import { v4 as uuidv4 } from "uuid";
import { getCall, getObjectDTOByUuidAndVersion, getSingleLevelObjectMfi, getUrl } from "../../utils/ApiUtils";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {
	copyObjectMfi,
	copyObjectMfiAndAttachToId,
	copyStandardObject,
	createNewStandardObject,
	createObjectHierarchyRecord,
	filterOutSubObjects,
	findAllPrimusDescendantsByMap,
	findClosestPrimusAncestor,
	findClosestPrimusAncestorByMap,
	findNearestSetupAttributeWithTitle,
	getDbConst,
	getObjectAncestorIdAndVersionUuid,
	getObjectDescendantIdAndVersionUuid,
	getPrimusObjectMap,
	getSetupForms,
	linkAttributeName,
	OBJECT_RECORD_NAME,
	updateRowsThatReference,
} from "../../utils/StandardObject";
import { buildTypeOfTreeList, getMapOfTreeNodes, listToTree } from "../../utils/TreeUtils";
import Checkbox from "@mui/material/Checkbox";

import { FixedSizeTree as Tree } from "react-vtree/dist/lib";
import { useStickyState } from "../../utils/StickyState";
import { useTracked } from "../../utils/store";
import "./Tree.scss";
import { getSmallRef, humanize, titleize } from "../../utils/StringUtils";
import EditableLabel from "../EditableLabel/EditableLabel";
import Version from "../VersionControl/Version";
import Reference from "../Reference/Reference";
import { useArray } from "../../utils/useStickyArray";
import {
	ErrorIcon,
	PlusIcon,
	MinusIcon,
	PlusSlashMinusIcon,
	WarningIcon,
	ArrowRightIcon,
	AsteriskIcon,
} from "../BootstrapComponents/Icons/Icons";
import { useClickHandler } from "../../utils/ClickHandler";
import Tooltip from "@mui/material/Tooltip";
import stylesModule from "./Tree.module.scss";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import EmptyPanelMessage from "../ReactGridComponents/Panel/EmptyPanelMessage";
import TreeNodeIcon from "./TreeNodeIcon";
import { updateChangeData } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";

/**
 * Recursive Tree, accepts data as a property (along with other things).
 *  Converts the data array to a tree data structure with a children property
 *  Sets the state of the treeData to the tree data structure
 *  Add support for allowing the tree to decide if it's draggable and where it's draggable
 *  (Maybe use booleans passed as properties?)
 * @param data
 * @param treeTitle
 * @param topNode
 * @param topNodeId
 * @param addChangedRows
 * @param addDeleteRows
 * @param rowSelected
 * @param getObjMfiOnDrop
 * @param droppable
 * @param draggable
 * @param draggableIds
 * @param editable
 * @param uploadObject
 * @param origin
 * @param checkboxes
 * @param visibleData
 * @param isLoading
 * @param props
 * @returns {*}
 * @constructor
 */
export default function RecursiveTreeView({
	data: _data,
	treeTitle,
	topNode,
	topNodeId,
	addChangedRows,
	addDeleteRows,
	rowSelected,
	getObjMfiOnDrop = false,
	getMdMfiOnDrop = false,
	droppable = false,
	draggable = false,
	draggableIds = [],
	editable = false,
	uploadObject,
	origin,
	checkboxes = [],
	checkboxToggled,
	visibleData,
	filteredList,
	dropEventHandler,
	isLoading = false,
	showVersions = false,
	updateSetupInfo,
	visibleDataIsTree = false,
	getObjectCopyToPutInDataWarehouse,
	searchTerms = [],
	changeIds = [],
	highlightIds = [],
	updateRow,
	reRenderWorkspace = false,
	setupSheetMap,
	reRenderOnInsertOrDelete,
	allowSmallRef = true,
	createNewNode,
	openAncestorsOfNodeUuid,
	borderIds = [],
	treeHeight,
	treeData: _treeData,
	refField = "reference",
	active,
	showTopNode,
	showErrors = false,
	showWarnings = false,
	updateSetupTrees,
	onSingleClick,
	onDoubleClick,
	onTripleClick,
	rowToSelect = {},
	emptyMessage = "It's empty",
	updateMfiInState = false,
	diff,
	rowButtons,
	rowButtonsForObjects,
	treeDivStyles = {},
	...props
}) {
	//The data that populates the tree
	const [treeData, setTreeData] = useState([]);
	const { array: data, set: setData, push: pushToData, remove: removeFromData, update: addToData } = useArray([]);
	const [mouseCoord, setMouseCoord] = useState({ mouseY: null, mouseX: null });
	const [selectedRow, setSelectedRow] = useState({ uuid: " " });
	const [expanded, setExpanded] = useStickyState([topNodeId || "0"], origin + "-expandedTree");
	const filterExpanded = useRef([]);
	const noDragOver = useRef(false);
	const [reRender, setReRender] = useState(reRenderWorkspace);
	const [highlightId, setHighlightId] = useState();

	const selectedRowRef = useRef({});

	const objectHierarchy = useRef([]);
	const tree = useRef();
	const treeContainer = useRef();
	const mapOfTreeNodes = useRef();

	//Global state used to store the existing objects that can be shared across components
	// const [sharedState, setSharedState] = useSharedState();
	const [sharedState, dispatch] = useTracked();
	// const dispatch = useDispatch();
	// const sharedState = useTrackedState();
	const copyMfi = useRef({});

	//Update the global states shared objects that is used across multiple components
	// const updateSharedObjects = (attribute, data) => {
	//     setSharedState((prev) => ({ ...prev, [attribute]: data }));
	// };

	//Set every time something is dragged over a tree item
	const [dragOverObj, setDragOverObj] = useState({
		id: "",
		section: {
			third: "",
			border: { border: "none" },
		},
	});

	// Merge the diff with the data
	useEffect(() => {
		if (!diff) {
			if (_data.length > 0) {
				getTree(_data);
			}
			return;
		} else if (_data.length < 1) return;

		let indexOffset = 0;
		let dataCopy = [..._data];
		let visitedRows = [];
		const recursivelyGoUpTree = (row) => {
			// If we have already gone up this tree, don't go up again
			if (visitedRows.includes(row.uuid)) return;
			visitedRows.push(row.uuid);

			if (row?.parentUuid) {
				let parent = dataCopy.find((r) => r.uuid === row.parentUuid);
				if (parent) {
					parent.descendantModified = true;
					recursivelyGoUpTree(parent);
				}
			}
		};

		//Remove any changes that may have been left over from previous changes
		let changedUuids = [];
		let deletedUuids = [];
		let addedUuids = [];
		Object.keys(diff).forEach((key) => {
			const row = diff[key];
			// Format of a delta (diff) https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
			if (key === "_t") return;
			// Additions are flagged
			// Additions are always an array with a single row
			if (Array.isArray(row) && row.length === 1) {
				let index = Number(key);
				dataCopy[index + indexOffset].added = true;
				addedUuids.push(dataCopy[index + indexOffset].uuid);
				recursivelyGoUpTree(dataCopy[index + indexOffset]);
				return;
			}
			// Deletions get added as a new row with deleted flag
			// Deletions are always an array where the second index is 0
			if (Array.isArray(row) && row.length === 3 && row[1] === 0 && row[0]) {
				let index = Number(key.substring(1));
				row[0].deleted = true;
				row[0].reference = topNode.reference + "." + row[0].reference;
				deletedUuids.push(row[0].uuid);
				dataCopy.splice(index + indexOffset, 0, row[0]);
				if (dataCopy[index + indexOffset]) {
					recursivelyGoUpTree(dataCopy[index + indexOffset]);
					indexOffset++;
				}
				return;
			}

			// Changes get added as changes attribute with an array of changes
			// Changes are always an object with a key for each change which is an array containing the new and old value
			if (!Array.isArray(row) && Object.keys(row).length > 0 && key !== "_t") {
				let index = Number(key);
				if (dataCopy[index + indexOffset]) {
					dataCopy[index + indexOffset].changes = row;
					changedUuids.push(dataCopy[index + indexOffset].uuid);
					recursivelyGoUpTree(dataCopy[index + indexOffset]);
				} else console.warn("unable to attach change", row);
				return;
			}
			// There are a couple of other operations that we don't handle right now TODO
			console.warn("Unknown delta type: ", row);
		});

		//Remove any changes that may have been left over
		dataCopy.forEach((row) => {
			if (!changedUuids.includes(row.uuid)) delete row.changes;
			if (!deletedUuids.includes(row.uuid)) delete row.deleted;
			if (!addedUuids.includes(row.uuid)) delete row.added;
			if (!visitedRows.includes(row.uuid)) delete row.descendantModified;
		});

		dataCopy = dataCopy.sort(sortByReference);
		setData(dataCopy);
		//Build tree from data copy
		let theTree = listToTree(dataCopy);

		mapOfTreeNodes.current = getMapOfTreeNodes(theTree);
		setTreeData(theTree);
	}, [diff]);

	//For some reason the state updates aren't getting pushed here, add ref and update manually
	useEffect(() => {
		// objectHierarchy.current = sharedState.contextObjectHierarchy;
	}, [sharedState.contextObjectHierarchy, sharedState.contextObjectHierarchy.length]);

	const deleteUuids = useRef([]);

	useEffect(() => {
		setReRender((prev) => !prev);
	}, [reRenderWorkspace]);

	useEffect(() => {
		if (rowToSelect && Object.keys(rowToSelect).length !== 0) {
			singleClick({}, rowToSelect);
			//Will / Adam Realistically we should probably figure out why there aren't ancestors, but this should work for now
			if (rowToSelect.ancestors) toggleNodesOn(rowToSelect.ancestors);
		}
	}, [rowToSelect, rowToSelect.uuid]);

	/**
	 * On render if there are any items in the includes array, show the checkbox for that "include"
	 * On click of the checkbox it will set it and all of it's descendants to (true or false)
	 * On close of the dialog it will either save and update the state in the object workspace or cancel discarding the user's changes
	 */

	useEffect(() => {
		copyMfi.current = sharedState.copyMfi;
	}, [sharedState.copyMfi]);

	useEffect(() => {
		if (_data) setData(_data);
		else if (_treeData) setTreeData(_treeData);
	}, [topNodeId, topNode?.versionUuid, _data?.length, _data?.[1]?.uuid]);
	//There is potentially another fix for this (instead of passing in _data?.[1]?.uuid to the useEffect)
	// In the openExisting we may want to implement a check to see if the oldUuidAndVersion still match up with the returned uuidAndVersion
	// If they do, don't go get the mfi
	// There's a lot more stuff going on in the openExisting method so we would need to be careful when updating it that we don't cause 15 other bugs

	/**
	 * This is called / triggered every time props.data changes
	 * Converts the array to a tree data structure and sets the state variable
	 */
	useEffect(() => {
		let theTree;
		let theData = [];
		if (_treeData) {
			setTreeData(_treeData);
			return;
		}
		if (filteredList) {
			theData = filteredList;
			//Convert the filtered list to a tree
			// theTree = listToTree(filteredList);

			//Expand all the tree nodes TODO: This doesn't work for some reason. What else needs to happen for all the nodes to be expanded on a search
			filterExpanded.current = filteredList.map((row) => row.uuid);
		} else if (visibleDataIsTree && visibleData) theTree = visibleData;
		else if (visibleData) theData = visibleData;
		else {
			if (showTopNode && topNode.uuid) theData = [topNode, ..._data];
			//Do we always not want the top node there? Or just currently?
			else theData = _data;
		}

		if (diff) return;

		if (!theTree) {
			getTree(theData);
		} else {
			mapOfTreeNodes.current = getMapOfTreeNodes(theTree);
			setTreeData(theTree);
		}
	}, [
		_data,
		_data?.length,
		_data?.[0]?.versionUuid,
		_data?.[1]?.uuid,
		// data.length,
		visibleData?.length,
		filteredList,
		filteredList?.length,
		topNodeId,
		topNode?.versionUuid,
		_treeData /*JSON.stringify(topNode)*/,
	]);

	useEffect(() => {
		if (!openAncestorsOfNodeUuid) return;

		let row = data.find((row) => row.uuid === openAncestorsOfNodeUuid);

		if (!row.ancestors) return;

		setExpanded([...expanded, ...row.ancestors]);

		setHighlightId(openAncestorsOfNodeUuid);
		tree.current.scrollToItem(openAncestorsOfNodeUuid, "center");

		setTimeout(() => {
			setHighlightId();
		}, 3000);
	}, [openAncestorsOfNodeUuid]);

	const getTree = (theData) => {
		let theTree = listToTree(theData);
		mapOfTreeNodes.current = getMapOfTreeNodes(theTree);
		setTreeData(theTree);
	};

	/**
	 * Called when a tree item is dragged.
	 * Get the rowId from the elements data set, verify it's not 0
	 * Add the rowId to the event's dataTransfer property.
	 * This makes so that wherever the event goes it has access to the rowId
	 * @param event
	 * @returns {boolean}
	 */
	const handleDragStart = (event) => {
		//Get the rowId of the tree item being dragged
		let rowId = event.target.dataset.rowid;
		let node = mapOfTreeNodes.current.get(rowId);

		if (
			(!draggable && !draggableIds.includes(rowId) && !draggableIds.includes(node.data.standardObjectUuid)) ||
			!rowId ||
			node.deleted
		) {
			event.dataTransfer.effectAllowed = "none";
			dispatch({ type: "SHOW_ERROR_MESSAGE", data: "This drag is not supported" });
			return false;
		}

		let row = data.filter((item) => item.uuid === rowId)[0];
		//If the row is associated
		row.treeTitle = treeTitle;
		row.getObjMfiOnDrop = getObjMfiOnDrop;

		if (origin) {
			row.origin = origin;
			event.dataTransfer.setData(origin, true);
		}

		row.topUuid = data[0].uuid;
		row.topUuidVersion = data[0].versionUuid;

		event.dataTransfer.setData("text", JSON.stringify(row));
		event.dataTransfer.effectAllowed = "move";
	};

	/**
	 * calculate which part of the element (top, middle, or bottom) the mouse is over and return the borderStyle
	 * @param event
	 */
	const calculateBorderStyle = (event) => {
		/**
		 * Get element begin and end y value, since this event is being triggered i don't need to know the x
		 * I just need to know where the mouse y is in relation to the element, is it close to the bottom?
		 * if so i want a bottom border on the element, if it's in the middle border around entire element,
		 * if it's on the top add a top border
		 */

		let coords = event.currentTarget.getBoundingClientRect();
		let yBegin = coords.top;
		let yEnd = coords.bottom;

		//Split the element into thirds, top part = border above, middle = border around, bottom = border below
		let elHeight = yEnd - yBegin;

		//Calculate which fourth of the total height the mouse is
		let mouseY = event.pageY;

		//This puts me in the bottom 1/4 of the element, add a bottom border
		if (mouseY <= yEnd && mouseY > yEnd - elHeight / 4) {
			return {
				third: "bottom",
				border: { borderBottom: "1px solid blue" },
			};
		}
		//This puts me in the top 1/4 of the element, add a top border
		else if (mouseY >= yBegin && mouseY < yBegin + elHeight / 4) {
			return {
				third: "top",
				border: { borderTop: "1px solid blue" },
			};
		}
		//This puts me in the middle third, add a surrounding border
		else {
			return {
				third: "middle",
				border: { border: "1px solid blue" },
			};
		}
	};

	/**
	 * Called when a tree item is being dragged over
	 * Get the rowId for the tree item being dragged over,
	 * Update the state variable which should trigger the css to
	 * highlight the tree item
	 * @param event
	 */
	const handleDragOver = (event, node) => {
		//If it's not droppable or we're dropping on the data warehouse panel from the workspace panel
		if (origin === "datawarehouse-panel" && event.dataTransfer.types.includes("workspace-panel")) {
		}
		// if(( (origin === 'datawarehouse-panel' || origin === 'models-panel') && event.dataTransfer.types.includes('workspace-panel'))) {}
		else if (!droppable) return;

		event.preventDefault();
		event.stopPropagation();

		//This event triggers millions of times, try only letting it run every 300 ms
		if (noDragOver.current) return;
		else noDragOver.current = true;

		setTimeout(() => (noDragOver.current = false), 150);

		//Get the id of the row being dropped
		let rowDropId = event.currentTarget.dataset.rowid;

		if (event.dataTransfer.effectAllowed === "none") return;

		//Get the mouse offset from the element
		let border = calculateBorderStyle(event);

		if (dragOverObj.id === rowDropId && border.third === dragOverObj.section.third) return;

		//Get the id of the row being dragged over
		let obj = { id: rowDropId, section: border };

		//Set the dragged over id state to update the styling

		setDragOverObj(obj);
	};

	const resetDragOverObj = () => {
		setDragOverObj({ id: "", section: "" });
	};

	/**
	 * Handle the tree item being dropped.
	 * Get the rowId of the item being dropped and the item being dropped on
	 * Make sure to only trigger if a tree item is being dropped on another tree item.
	 * Node is the row that was dropped on
	 */
	const handleDrop = async (event, node) => {
		resetDragOverObj();

		if (dropEventHandler) dropEventHandler();

		let objectHierarchy = null;
		let workspaceToWarehouse = false;
		let workspaceToModel = false;
		let validDropOnSourceCodeTree = false;
		if (origin === "datawarehouse-panel" && event.dataTransfer.types.includes("workspace-panel"))
			workspaceToWarehouse = true;
		else if (
			origin === "layout-panel" &&
			(event.dataTransfer.types.includes("datawarehouse-panel") ||
				event.dataTransfer.types.includes("template-panel"))
		)
			validDropOnSourceCodeTree = true;
		// else if(origin === 'models-panel' && event.dataTransfer.types.includes('workspace-panel'))
		//     workspaceToModel = true;
		else if (!droppable) return;

		//TODO Add check for dropping object being a code object

		event.preventDefault();
		event.stopPropagation();

		//Get the third of the element the mouse is in
		let section = calculateBorderStyle(event);

		let newRows = [];

		//Get the row that was dropped
		let dragObj;
		try {
			dragObj = JSON.parse(event.dataTransfer.getData("text"));
		} catch (e) {
			return;
		}

		//If we are dragging from the workspace to the data warehouse we want to ignore the default behavior
		if (workspaceToWarehouse || validDropOnSourceCodeTree || workspaceToModel) {
		} else if (
			dragObj.uuid === topNodeId ||
			(!draggable && !draggableIds.includes(dragObj.uuid) && !draggableIds.includes(dragObj.standardObjectUuid))
		)
			return;

		let objectType = await getObjectTypeConst();

		let newObj = {};

		//Get the row that was dropped on
		let idDroppedOn = node.uuid;
		let rowDroppedOn = data.find((item) => item.uuid === idDroppedOn);
		if (rowDroppedOn.deleted || dragObj.deleted) {
			dispatch({ type: "SHOW_ERROR_MESSAGE", data: "Dragging or Dropping on deleted rows is not supported" });
			return;
		}

		//Get the children of the row dropped on, sort it by reference
		let children = data.filter((item) => item.parentUuid === idDroppedOn);

		//Get the row's new reference
		let newRef = "";

		if (idDroppedOn === topNodeId) {
			section.third = "middle";
		}

		//Declare a variable that we can store a list of changes in to later alert the parent so that they can be saved on click of the save button
		let changes = [];
		let objectRows = [];

		//What happens when a row is dropped onto another row?

		if ((validDropOnSourceCodeTree || rowDroppedOn.isObject) && section.third === "middle")
			section.third = "bottom";

		//If third === bottom the new ref will the next ref of the row being dropped on
		if (section.third === "bottom") newRef = getNextRef(rowDroppedOn.reference);
		//If third === middle the new ref will be the ref after the last child of the row dropped on
		else if (section.third === "middle") {
			//If there is a newRef do nothing
			if (newRef !== "") {
			} else if (children.length < 1) newRef = getPrevRef(rowDroppedOn.reference + ".02");
			//Otherwise sort the array by reference and set the new ref to the next ref after the ref of the last child
			else {
				children.sort(sortByReference);
				newRef = getNextRef(children[children.length - 1].reference);
			}
		}
		//Otherwise if third === top the new ref will be the reference of the row dropped on
		else if (section.third === "top") {
			newRef = { reference: rowDroppedOn.reference, referenceNo: rowDroppedOn.referenceNo };
		}

		//Checks if the row is going to go on the same level, or inside
		//If it's on the same level the new parent is the row that was dropped on's parent
		//If it's inside the new parent is the row that was dropped on
		let newParent = "";

		if (section.third === "top" || section.third === "bottom") newParent = rowDroppedOn.parentUuid;
		else if (section.third === "middle") newParent = idDroppedOn;

		let relatedObjectType = await getDbConst("ASCOBJ", "associatedObjectType", sharedState, dispatch);
		//Check if the row is an associated object, it follows different rules if so.
		let hierarchyRecord = sharedState.contextObjectHierarchy.find(
			(row) =>
				row.descendantStandardObjectUuid === dragObj.uuid &&
				row.descendantStandardObjectVersionUuid === dragObj.versionUuid &&
				row.hierarchyTypeUuid === relatedObjectType
		);
		if (hierarchyRecord || rowDroppedOn.inputType === INPUT_FIELD_TYPES.ASSOCIATION.value) {
			//Get the dragObj original parent
			let originalParent = data.find((row) => row.uuid === dragObj.parentUuid);
			//If the parent is different throw warning that rows inside this parent must be changed / updated with the setup sheet
			//TODO: We might want to check if the original parent has children yet, if not, and this dragObj has the correct type we could allow the drop
			if (originalParent?.cardinality !== "0+" || newParent !== dragObj.parentUuid) {
				//Throw warning
				dispatch({
					type: "SHOW_ERROR_MESSAGE",
					data: "You can only modify this level of the tree using the setup sheet",
				});
				nodeClicked({}, rowDroppedOn, false);
				return;
			}
		}

		//Check if the dragged row is coming from this tree or an outside source,
		if (dragObj.treeTitle === treeTitle) {
			//Dragging within self
			//If coming from this tree, verify the row is not being dragged inside of itself
			let dragRow = _data.filter((item) => item.uuid === dragObj.uuid)[0];
			//Add a check to see if we are dropping it on the top node, if so, we don't need to worry about stopping the rest of the function
			if (idDroppedOn !== topNodeId && rowDroppedOn.reference.startsWith(dragRow.reference)) return;

			//When an object is moved around in the hierarchy, if it's moved into another primus object we need to update
			//the ancestor and maybe the pathEnum
			//Get the closestPrimusAncestor for both the dragRow.reference (original location) and the newRef.reference (new location)
			//Find the closest (or all) primus descendants of the dragged row and update the ancestor for the dragged row
			// also we may need to update the pathEnum for all descendants
			let primusMap = getPrimusObjectMap(_data);
			let originalAncestor = findClosestPrimusAncestorByMap(primusMap, dragRow.reference);
			let newAncestor = findClosestPrimusAncestorByMap(primusMap, newRef.reference);
			if (originalAncestor?.uuid != newAncestor?.uuid) {
				let allPrimusDescendants = findAllPrimusDescendantsByMap(primusMap, dragRow.reference);

				if (allPrimusDescendants.length > 0) {
					let descendantPrimusMap = new Map();
					//Filter Object Hierarchy by allPrimusDescendants
					let descendantObjectHierarchyRecords = sharedState.contextObjectHierarchy.filter(
						(objectHierarchy) =>
							allPrimusDescendants.filter(
								(desc) =>
									desc.uuid === objectHierarchy.descendantStandardObjectUuid &&
									desc.versionUuid === objectHierarchy.descendantStandardObjectVersionUuid
							).length > 0
					);
					let newAncestorObjectHierarchyRecord = sharedState.contextObjectHierarchy.filter(
						(objectHierarchy) =>
							objectHierarchy.descendantStandardObjectUuid === newAncestor.uuid &&
							objectHierarchy.descendantStandardObjectVersionUuid === newAncestor.versionUuid
					);

					//Order the list by pathEnum, we would have a map by descendantUuidAndVersion
					descendantObjectHierarchyRecords.sort((a, b) => a.pathEnum.length - b.pathEnum.length);

					//Anything with a pathEnum length the same as the first one is a closest descendant
					//Anything with a pathEnum length longer than the first is a further descendant
					let firstLength = descendantObjectHierarchyRecords[0]?.length;
					descendantObjectHierarchyRecords.forEach((record) => {
						let descendantKey = getObjectDescendantIdAndVersionUuid(record);
						let ancestorKey = getObjectAncestorIdAndVersionUuid(record);
						//For the closest ones update the ancestorStandardObjectUuid
						if (record.pathEnum.length === firstLength) {
							record.pathEnum = newAncestorObjectHierarchyRecord.pathEnum + "." + descendantKey;
							record.ancestorStandardObjectUuid = newAncestor.uuid;
							record.ancestorStandardObjectVersionUuid = newAncestor.versionUuid;
						}
						//For everything else update the pathEnum
						else {
							record.pathEnum = descendantPrimusMap.get(ancestorKey) + "." + descendantKey;
						}

						//Add the current record to the map for the descendants
						descendantPrimusMap.set(descendantKey, record.pathEnum);
					});

					//Add the descendantObjectHierarchyRecords to the changed rows
					if (descendantObjectHierarchyRecords.length > 0)
						dispatch({
							type: "UPDATE_CHANGED_ROWS",
							data: {
								objectHierarchy: descendantObjectHierarchyRecords,
							},
						});
				}
			}

			//On the backend it pulls up the existing stuff, applies the stuff sent from the front-end to the existing stuff
			//If we just update the current hierarchy record the backend may just add an additional set of records, instead of updating
			// the current records. (Note: When I say update this is referring to the copying of the current object's hierarchy)
			// Verify on the backend the updatedHierarchy is being applied on the existingHierarchy, instead of just merging.

			//If not move the row being dragged and its children to wherever it was placed using the section to decide if it goes above, inside, or underneath

			//Call the referencing method that will re reference everything for you
			changes = reRefOnManualReferenceChange(dragRow, dragRow.reference, newRef.reference, _data);

			let rowsToDelete = filterOutSubObjects(changes);

			_data.sort(sortByReference);

			//Update the tree data
			if (visibleData) setTreeData(listToTree(visibleData.sort(sortByReference)));
			else {
				setData(_data);
				setTreeData(listToTree(_data));
			}
		}
		//Otherwise the dragged object is coming from another tree
		else {
			//If coming from the data warehouse get the object master file index for the row that was dropped, need to add a flag to say it's the data warehouse
			//TODO: I want the same object signature as the dropped on object not the dragged
			//Create a new object that is copy of the object dragged, and set the uuid, reference, and parent to the new stuff
			let dragged;

			//We need to stringify then parse the object so newObj = a new object rather than a reference to rowDroppedOn
			if (!rowDroppedOn) dragged = children[0];
			else dragged = rowDroppedOn;

			newObj = copyStandardObject(
				dragged,
				dragged.reference,
				newParent,
				sharedState.currentUser?.uuid,
				true,
				true
			);
			//Reset some of the type stuff. Coming from different trees, we probably don't want the same type?
			newObj.typeUuid = null;
			newObj.objectHierarchyUuid = null;

			newObj.title = dragObj.title;
			newObj.description = dragObj.description;
			newObj.location = "";
			// newObj.versionUuid = dragObj.versionUuid;
			newObj.versionControl = dragObj.versionControl;
			newObj.isObject = true;

			let newMfi = [];

			//Get the dropped object's object master file index so we can attach that to the tree along with the dropped object
			if (dragObj.getObjMfiOnDrop) {
				/**
				 * If the dragged object isn't tied to a standard object what should we do?
				 * We can do 1 of 2 things, we can either only let the object be draggable if it is already tied to a standard object
				 * OR as we create the new object, we can update the MFI row to tie to the newly created object.
				 * What makes the most sense? From a user standpoint? You should be able to make an object out of anything
				 * so I think it does make sense to do the second option.
				 */

				if (dragObj.origin == "template-panel") {
					newObj.standardObjectUuid = dragObj.uuid;
					newObj.standardObjectVersionUuid = dragObj.versionUuid;
				} else {
					newObj.standardObjectUuid = dragObj.standardObjectUuid;
					newObj.standardObjectVersionUuid = dragObj.standardObjectVersionUuid;
				}

				//Get the standardObjectGit record?
				let dto = await getObjectDTOByUuidAndVersion(
					newObj.standardObjectUuid,
					newObj.standardObjectVersionUuid
				);

				//Set stock # and stuff
				if (dto) {
					//What should transfer from the DTO to to the newObj? stock #, type, etc.
					newObj.stockNumber = dto?.stockNumber;
					newObj.stockNo = dto?.stockNo;
					newObj.objectTypeUuid = dto?.objectTypeUuid;
					newObj.objectTypeVersionUuid = dto?.objectTypeVersionUuid;
					newObj.tags = dto?.tags;
				} else {
					newObj.stockNumber = dragObj.stockNumber;
					newObj.stockNumberUuid = dragObj.stockNumberUuid;
				}

				//If there isn't a standard object uuid associated with the dragged object, this means I am dragging a row from the mfi table without an associated object
				if (!newObj.standardObjectUuid) {
					//Update the master file index record to point to this new standard object
					dragObj.standardObjectUuid = newObj.uuid;

					//Update the new object to point to itself
					newObj.standardObjectUuid = newObj.uuid;

					newObj.generalTypeUuid = objectType.referenceUuid;

					//Add a variable to the object to let changedRows to know that this record is an mfi record rather than a standard object
					dragObj.mfiRow = true;

					//Add dragObj to the changedRows array
					changes.push(dragObj);
				}

				//Also pass in the closest primus object so we can set the top of the objectHierarchy to attach to it
				let hierarchyToAttachTo = findClosestPrimusAncestor(data, rowDroppedOn.reference);
				let ancestorHierarchyRecord = sharedState.contextObjectHierarchy.find(
					(row) =>
						row.descendantStandardObjectUuid === hierarchyToAttachTo.uuid &&
						row.descendantStandardObjectVersionUuid === hierarchyToAttachTo.versionUuid
				);

				// newMfi = await copyObjectMfiAndAttachToId(newObj.standardObjectUuid, newObj.standardObjectVersionUuid, newObj, newRef.reference, sharedState.currentUser?.uuid, hierarchyToAttachTo);
				// let objMfi = await getCall(getUrl('getObjectMfi', [dragObj.standardObjectUuid]));
				// objectHierarchy = newMfi.objectHierarchy;
				objectHierarchy = [createObjectHierarchyRecord(ancestorHierarchyRecord, { ...newObj })];
			}
			//I'm going to assume that if an associated object is dropped here, the copy should be a literal copy but just update the uuid so its different. We want it to have the same linking information.
			//I don't think this'll break the other trees.
			else if (dragObj.generalTypeUuid === ASSOCIATED_OBJECT_GENERAL_TYPE_UUID) {
				newObj = { ...dragObj, uuid: uuidv4() };
			} else {
				newObj.standardObjectUuid = dragObj.uuid;
				newObj.standardObjectVersionUuid = dragObj.versionUuid;
			}

			//I'm assuming that if the treeTitles don't match we are coming from the datawarehouse MFI to object MFI. The generalTypeUuid field in the datawarehouse MFI is referring to the objects type_uuid so it should be ok to set it on the object row being created
			newObj.generalTypeUuid = dragObj.generalTypeUuid;

			//If we are attaching this on the models mfi, we want to also build the model mfi
			if (getMdMfiOnDrop) {
				//Get the object's mfi
				let mfi = [];
				let descendants = [];
				//Not sure why this is here. Don't think we ever used it?
				// if(dragObj.mfiView) {
				//     // mfi = dragObj.mfiVieww;
				//     descendants = dragObj.mfiView;
				// }
				// else {
				//     mfi = await getObjectMfi(dragObj.standardObjectUuid, dragObj.standardObjectVersionUuid, dispatch);
				//     descendants = getMfiView(mfi);
				// }
				//
				// if(descendants.length > 0) {
				//     //TODO: change updateStandardObjectUuid to true when the backend is fixed
				//     newMfi = copyObjectMfi(descendants, mfi[0].uuid, newObj.uuid, newRef.reference, sharedState.currentUser.uuid, {updateStandardObjectUuid: true});
				//
				//     //Re-reference the new mfi
				//     fixReferences(newMfi, newObj.uuid, newRef.reference, {});
				// }
				let { changedMfiRows } = await createModelMfiCopy(
					{ ...newObj, reference: newRef.reference },
					dragObj.standardObjectUuid,
					dragObj.standardObjectVersionUuid,
					sharedState,
					dispatch
				);
				newMfi = changedMfiRows;
			}

			//The reason we have to set the reference down here is because we have to replace the old ref with the new ref in the descendants, so we put this after the if that get's the object mfi and copies it
			newObj.reference = newRef.reference;
			newObj.referenceNo = newRef.referenceNo;

			// newObj.children = listToTree(newMfi);

			if (newObj.setupRow) {
				newObj.setupRow.uuid = uuidv4();
				newObj.setupRow.objectUuid = newObj.uuid;
			}

			//Create this object for referencing purposes
			let rowToInsertAfter = {
				parentUuid: newParent,
				uuid: idDroppedOn,
				referenceNo: getPrevRef(newObj.reference).referenceNo,
			};

			newObj.parentUuid = newParent;

			changes = [...changes, ...insertRowAndReRef(rowToInsertAfter, _data, newObj)];
			// changes = changes.concat(insertRowAndReRef(rowToInsertAfter, data, newObj));

			// data = [...data, ...newMfi];
			newMfi.forEach((row) => _data.push(row));
			if (objectHierarchy) sharedState.contextObjectHierarchy.push(...objectHierarchy);

			newRows = newMfi;

			if (getMdMfiOnDrop) newMfi.forEach((row) => changes.push(row));

			//If this is an object being dragged from the workspace panel to the data warehouse panel,
			//I want to create a object that the dragged object will point to and the data warehouse row
			//will point to this new object rather than the dragged object,

			changes.push(newObj);

			_data.sort(sortByReference);

			//I think this is when dragging a new object into the warehouse
			if (uploadObject) {
				uploadObject(newObj);
			}

			//Update the tree data
			// if (visibleData) setTreeData(listToTree(visibleData.sort(sortByReference)));
			// else {
			// 	setData(_data);
			// 	setTreeData(listToTree(_data));
			// }
			if (updateMfiInState) updateTreeMfi(_data);
			else setData(_data);

			getTree(_data);
		}
		//TODO: Let parent know that changes were made and pass them up.
		if (addChangedRows) {
			let masterFileIndexTree = false;
			if (workspaceToWarehouse || getMdMfiOnDrop) {
				changes.forEach((row) => (row.mfiRow = true));
				masterFileIndexTree = true;
			}

			if (workspaceToWarehouse) {
				changes = [...getObjectCopyToPutInDataWarehouse(dragObj, newObj, objectType), ...changes];
			}
			if (changes.length > 0) {
				//This should only apply to re-references any other change should be added
				if (!masterFileIndexTree) {
					newObj.objectTags += OBJECT_RECORD_NAME;
					changes = filterOutSubObjects(changes);
				}

				//Remove any rows that go over the object borders.
				//If I am in a top object and move things around, any sub-objects should not be added to the changed rows that need to be saved.
				//Meaning only the object I am changing will change
				//If I drop in an object from the data warehouse I will only add the top row of the object to the changed rows
				changes = [...changes, ...objectRows];

				changes.objectHierarchy = objectHierarchy;

				addChangedRows(changes);
				if (reRenderOnInsertOrDelete) reRenderOnInsertOrDelete([newObj, ...newRows], true);
			}
		}
	};

	/**
	 * Helper function used by handleDrop and paste. Copies an object mfi, updating setup information and attaches it to the tree
	 * May be able to be combined with the copyObjectMfiAndAttachTo method in StandardObject.js
	 * @param newObj
	 * @param mfiRecord
	 * @param newRef
	 * @param sibling
	 * @param linkToStandardObjectUuid
	 * @param newMfi
	 * @returns {Promise<void>}
	 */
	const copyMfiForDataWarehouseRecordAndAttach = async (
		newObj,
		newMfi,
		mfiRecord,
		newRef,
		sibling,
		linkToStandardObjectUuid
	) => {
		/**
		 * If the dragged object isn't tied to a standard object what should we do?
		 * We can do 1 of 2 things, we can either only let the object be draggable if it is already tied to a standard object
		 * OR as we create the new object, we can update the MFI row to tie to the newly created object.
		 * What makes the most sense? From a user standpoint? You should be able to make an object out of anything
		 * so I think it does make sense to do the second option.
		 */
		let changes = [];

		newObj.standardObjectUuid = linkToStandardObjectUuid;
		//If there isn't a standard object uuid associated with the dragged object, this means I am dragging a row from the mfi table without an associated object
		if (!newObj.standardObjectUuid) {
			//Update the master file index record to point to this new standard object
			mfiRecord.standardObjectUuid = newObj.uuid;

			//Update the new object to point to itself
			newObj.standardObjectUuid = newObj.uuid;

			newObj.generalTypeUuid = sharedState.dbConstants.objectType.referenceUuid;

			//Add a variable to the object to let changedRows to know that this record is an mfi record rather than a standard object
			mfiRecord.mfiRow = true;

			//Add dragObj to the changedRows array
			changes.push(mfiRecord);
		}

		if (!newMfi)
			newMfi = await copyObjectMfiAndAttachToId(
				mfiRecord.standardObjectUuid,
				mfiRecord.standardObjectVersionUuid,
				newObj,
				newRef.reference,
				sharedState.currentUser?.uuid
			);
		// let objMfi = await getCall(getUrl('getObjectMfi', [dragObj.standardObjectUuid]));

		//Save an array of setup links we've set, later we will go through the newMfi and update any attributes that were pointing to the ones we updated
		let rowsWithUpdatedLinks = [];
		//TODO: The default-type values are hard coded throughout the app. Not a very good design and may need fixed in the future
		//Set the value of each row based on the default type in the setupRow
		newMfi.forEach((row) => {
			//If the row's setupType is 'link' but there's no setupLinkUuid check for a setup attribute with matching title in the destination
			//object starting at owner of row draggedOver
			if (row.setupType === "link" && !row[linkAttributeName]) {
				//Find the nearest setup sheet attribute with the same title as this one
				let nearestSetupAttribute = findNearestSetupAttributeWithTitle(sibling, row.title, topNodeId, {
					data,
					setupSheetMap,
				});
				if (nearestSetupAttribute.uuid) {
					row[linkAttributeName] = nearestSetupAttribute.uuid;
					row.setupLinkType = "alias";
					row.setupValue = nearestSetupAttribute.value;
					row.value = nearestSetupAttribute.value;

					rowsWithUpdatedLinks.push(row.uuid);
				}
			}
		});

		//Recursively update any rows that pointed to the rows with updated links
		rowsWithUpdatedLinks.forEach((uuid) => {
			updateRowsThatReference(uuid, newMfi);
		});

		if (updateSetupInfo) updateSetupInfo(newMfi, newObj);

		changes.objectHierarchy = newMfi.objectHierarchy;

		return changes;
	};

	const updateTreeMfi = (data) => {
		dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				mfi: data,
			},
		});
	};

	/**
	 * Checks if the uuid passed in is the node that is being dragged over, if so return the border style, if not return border: none
	 * This is called from the render method. Right now is causing the entire tree to re render everytime anything changes, rather than
	 *  only re rendering the node that needs updated
	 * @param uuid
	 * @returns {*}
	 */
	const getBorderStyle = (uuid) => {
		if (dragOverObj.id === uuid) {
			// return {border: '1px solid blue'}
			return dragOverObj.section.border;
		} else return { border: "none" };
	};

	/**
	 * Handle the right click for the tree. When a node is right clicked verify we can pull up the context menu.
	 * Get the id of the row that was right clicked and update the row selected
	 * @param event
	 */
	const handleRightClick = (event) => {
		event.stopPropagation();

		//Get the rowId of the node right clicked on, navigating the DOM, getting the target's parent's parent
		let element = event.currentTarget;
		//TODO: Shouldn't need this anymore, VERIFY
		// if(element.nodeName !== 'LI')
		//     element = element.parentNode;

		let rowId = element.dataset.rowid;
		let node = mapOfTreeNodes.current.get(rowId);

		//We want the top node to be right clickable because that's the only way to add rows when there are noe
		if (
			!editable ||
			(node.data.deleted !== undefined && node.data.deleted) ||
			(rowId === topNodeId && data.length > 0)
		)
			return;

		//Set the selected row to update the DOM adding a border around element to show selection
		selectedRowRef.current = { uuid: rowId };

		event.preventDefault();

		setMouseCoord({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		});
	};

	const handleContextMenuClose = () => {
		setMouseCoord({ mouseY: null, mouseX: null });
	};

	const getObjectTypeConst = async () => {
		if (sharedState.dbConstants.objectType) return sharedState.dbConstants.objectType;

		let objTpConst = await getCall(getUrl("getDbConstByRef", ["OBJTP"]));

		dispatch({ type: "SET_DB_CONSTANT", constant: "objectType", data: objTpConst });
		// updateSharedObjects('dbConstants', {...sharedState.dbConstants, objectType: objTpConst} );
		return objTpConst;
	};

	//TODO: This method is duplicated multiple times in each component that needs it, consolidate them to one place
	const getDefaults = async () => {
		if (sharedState.defaultClasses.length > 0) return sharedState.defaultClasses;

		let defaultClasses = await getCall(getUrl("getDefaultClasses"));
		let defaultFields = await getCall(getUrl("getDefaultFields"));

		let mappedData = defaultClasses.map((defaultClass) => {
			return {
				...defaultClass,
				fields: defaultFields.filter((field) => field.defaultClassUuid === defaultClass.uuid),
			};
		});

		dispatch({ type: "SET_DEFAULT_CLASSES", data: mappedData });
		// updateSharedObjects('defaultClasses', mappedData);
		return mappedData;
	};

	/**
	 * Add a node under the selected node. Add the node to the list of added nodes
	 */
	const insertNode = async () => {
		//Get selected node
		let node = _data.find((item) => item.uuid === selectedRowRef.current.uuid);

		if (sharedState.setupForms.length < 1) dispatch({ type: "SET_SETUP_FORMS", data: await getSetupForms() });
		// updateSharedObjects('setupForms', await getSetupForms());

		let objectType = sharedState.dbConstants.objectType;
		if (!objectType) objectType = await getObjectTypeConst();

		//Get the parent and if it has inputType of 'association' send warning and don't insert
		let parentNode = _data.find((row) => row.uuid === node.parentUuid);
		if (parentNode.inputType === INPUT_FIELD_TYPES.ASSOCIATION.value) {
			dispatch({
				type: "SHOW_ERROR_MESSAGE",
				data: "You can only modify this level of the tree using the setup sheet",
			});
			nodeClicked({}, parentNode, false);
			return;
		}

		let newNode;
		if (createNewNode) {
			newNode = createNewNode(getNextRef(node.reference).reference);
			newNode.parentUuid = node.parentUuid;
		} else
			newNode = createNewStandardObject({
				title: "",
				reference: getNextRef(node.reference).reference,
				parentUuid: node.parentUuid,
				setupForms: sharedState.setupForms,
				createdByUuid: sharedState.currentUser?.uuid,
			});

		newNode.setupSheets = node.setupSheets;
		newNode.indeterminateSetupSheets = node.indeterminateSetupSheets;

		let changes = insertRowAndReRef(node, _data, newNode);

		if (updateMfiInState) updateTreeMfi(_data);
		else setData(_data);

		changes.push(newNode);

		changes = filterOutSubObjects(changes);

		//Let parent know that changes were made and pass them up.
		if (addChangedRows) addChangedRows(changes);

		if (reRenderOnInsertOrDelete) reRenderOnInsertOrDelete([newNode], true);

		setReRender((prev) => !prev);
		handleContextMenuClose();
	};

	/**
	 * Add a node under the selected node. Add the node to the list of added nodes
	 */
	const duplicateNode = () => {
		//Get selected node
		let node = data.find((item) => item.uuid === selectedRowRef.current.uuid);

		copyAndAttachNodeAndMfi(
			node,
			node,
			data.filter((row) => row.reference.startsWith(node.reference)),
			"Copy Of " + node.title
		);

		handleContextMenuClose();
	};

	const copyAndAttachNodeAndMfi = (node, siblingNode, copyMfi, copyTitle) => {
		let nodeCopy = copyStandardObject(
			node,
			getNextRef(siblingNode.reference).reference,
			siblingNode.parentUuid,
			sharedState.currentUser?.uuid,
			true
		);
		//Update title to include 'Copy of';

		if (copyTitle) nodeCopy.title = copyTitle;

		//Copy the descendants
		let descendantCopies = copyObjectMfi(
			copyMfi,
			node.uuid,
			nodeCopy.uuid,
			nodeCopy.reference,
			sharedState.currentUser?.uuid,
			{ sameTree: true }
		);

		let changes = insertRowAndReRef(siblingNode, data, nodeCopy);

		changes = [...changes, nodeCopy, ...descendantCopies];

		//TODO We need to verify this isn't attaching all of the rows to the closest primus object, but is separating it by sub-object
		descendantCopies.forEach((desc) => data.push(desc));
		data.sort(sortByReference);

		//Let parent know that changes were made and pass them up.
		if (addChangedRows) addChangedRows(changes);

		if (reRenderOnInsertOrDelete) reRenderOnInsertOrDelete([nodeCopy, ...descendantCopies], true);

		//Update the tree data
		if (visibleData) setTreeData(listToTree(visibleData.sort(sortByReference)));
		else {
			setData(_data);
			setTreeData(listToTree(_data));
		}
	};

	/**
	 * Remove selected node. If it's not a new node, add the id to the list of deleted ids
	 */
	const deleteNode = () => {
		//Get the selected node
		let node = _data.find((item) => item.uuid === selectedRowRef.current.uuid);

		//Get the nodes descendants
		let descendants = _data.filter(
			(item) => item.uuid !== selectedRowRef.current.uuid && item.reference.startsWith(node.reference)
		);
		descendants.sort(sortByReference);
		descendants.reverse();

		//Get the ids we want to remove from the tree
		let ids = descendants.map((desc) => desc.uuid);
		ids.push(node.uuid);

		let rowsToDelete = descendants;
		rowsToDelete.push(node);

		let changes = deleteRow(node, _data);

		if (origin === "workspace-panel") {
			if (changes.length > 0) {
				//This should only apply to re-references any other change should be added
				changes = filterOutSubObjects(changes);
			}
		}

		//TODO we need to add the closest ancestor... if ti is a

		//Remove the selected node along with its children from the tree
		_data = _data.filter((item) => !ids.includes(item.uuid));

		if (updateMfiInState) updateTreeMfi(_data);
		else setData(_data);

		//Add the ids to the array storing all the ids that got deleted
		deleteUuids.current = deleteUuids.current.concat(ids);

		//Alert the parent component as to what nodes were deleted
		// if (addDeleteRows) addDeleteRows(rowsToDelete);

		// if (changes.length > 0) addChangedRows(changes);

		//Right now deleteNode only happens on an Object's Master File Index, I think I can assume that if this is called and addChangedRows and addDeletedRows is there we can just call updateChangeData
		if (addChangedRows && addDeleteRows) {
			let update = {};
			if (changes.length > 0) update.objectRows = changes;
			if (rowsToDelete.length > 0) update.deletedRows = rowsToDelete;

			if (origin === "master-file-index") {
				addChangedRows(changes);
				addDeleteRows(rowsToDelete);
			} else updateChangeData(dispatch, update);
		}

		if (reRenderOnInsertOrDelete) reRenderOnInsertOrDelete([node, ...descendants], false);

		//Update the tree
		setTreeData(listToTree(visibleData || _data));
		handleContextMenuClose();
	};

	/**
	 *  When a node is clicked update the selected node
	 *  Alert the parent component that node is selected if needed
	 */
	const nodeClicked = (event, node, doubleClick = false) => {
		if (!node.deleted) {
			if (rowSelected) rowSelected(node, event.metaKey, event.shiftKey, doubleClick);
			if (selectedRow.uuid !== node?.uuid) setSelectedRow(node || { uuid: " " });
			selectedRowRef.current = node;
		}
	};

	const singleClick = (e, node) => {
		nodeClicked(e, node);
		onSingleClick && onSingleClick(e, node);
	};
	const doubleClick = (e, node) => {
		nodeClicked(e, node);
		onDoubleClick && onDoubleClick(e, node);
	};
	const tripleClick = (e, node) => {
		nodeClicked(e, node);
		onTripleClick && onTripleClick(e, node);
	};

	const nodeToggled = (event, nodeIds, nodeToggled) => {
		if (filteredList) filterExpanded.current = nodeIds;
		else setExpanded(nodeIds);

		let toggledNode = data.filter((row) => row.uuid === nodeToggled)?.[0];

		singleClick(event, toggledNode);
	};

	const toggleNodesOn = (nodeIds) => {
		if (filteredList) {
			filterExpanded.current = addIdsToList(nodeIds, filterExpanded.current);
		} else {
			setExpanded(addIdsToList(nodeIds, expanded));
		}
	};

	const addIdsToList = (idsToAdd, targetList) => {
		let newList = [...targetList];
		idsToAdd.forEach((id) => {
			if (!newList.includes(id)) {
				newList.push(id);
			}
		});
		return newList;
	};

	const handleKeyPress = async (e) => {
		//copy: command or window key + c = 67
		// if ((e.metaKey || e.ctrlKey) && e.keyCode === 67) {
		// 	//If we are copying from the data warehouse, get the object's mfi
		// 	if (origin === "datawarehouse-panel") {
		// 		dispatch({ type: "SET_COPY_MFI", data: selectedRowRef.current });
		// 		// copyButtonRef.current.click();
		// 	}
		// 	//Else if we are from the 'source object template' panel  or 'New / Modified Object Workspace' Panel copy the row and its descendants
		// 	//For now we will assume that we can only be in one of these three trees
		// 	else
		// 		dispatch({
		// 			type: "SET_COPY_MFI",
		// 			data: {
		// 				mfi: data.filter((row) => row.reference.startsWith(selectedRowRef.current.reference)),
		// 			},
		// 		});
		// }
		// //paste: command or window key + v = 86
		// else if ((e.metaKey || e.ctrlKey) && e.keyCode === 86 && droppable) {
		// 	//If there is already an mfi, create a copy of that and put it under the selected row
		// 	if (copyMfi.current.mfi) {
		// 		let mfi = copyMfi.current.mfi;
		// 		//Should be able to copy the first item in mfi array
		// 		copyAndAttachNodeAndMfi(mfi[0], selectedRowRef.current, mfi);
		// 	}
		// 	//Else if there is a uuid and versionUuid, create a copy of the object, it's mfi, and attach it underneath the selected row
		// 	else if (
		// 		droppable &&
		// 		sharedState.copyMfi.standardObjectUuid &&
		// 		sharedState.copyMfi.standardObjectVersionUuid
		// 	) {
		// 		let mfiRecord = sharedState.copyMfi;
		// 		let { standardObjectUuid, standardObjectVersionUuid } = mfiRecord;
		//
		// 		let changes = [];
		// 		//Get the object's mfi
		// 		let mfi = await getCall(getUrl("getObjectMfi", [standardObjectUuid, standardObjectVersionUuid]));
		// 		let obj = mfi[0];
		// 		//Create a copy of the top object
		// 		let copy = copyStandardObject(
		// 			obj,
		// 			getNextRef(selectedRowRef.current.reference).reference,
		// 			selectedRowRef.current.parentUuid,
		// 			sharedState.currentUser?.uuid
		// 		);
		//
		// 		//TODO set the copy's versionUuid to zero because
		// 		//Create a copy of the mfi
		// 		let newMfi = copyObjectMfi(mfi, obj.uuid, copy.uuid, copy.reference, sharedState.currentUser?.uuid);
		//
		// 		//Call the copy data warehouse record mfi
		// 		changes = newMfi;
		// 		let otherChanges = await copyMfiForDataWarehouseRecordAndAttach(
		// 			copy,
		// 			newMfi,
		// 			mfiRecord,
		// 			copy.reference,
		// 			selectedRowRef.current,
		// 			standardObjectUuid
		// 		);
		// 		changes = [...changes, ...otherChanges, ...insertRowAndReRef(selectedRowRef.current, data, copy)];
		//
		// 		//TODO Verify the changes that are being made are limited to the top object
		// 		// We may also need the hierarchy... because of the add changed rows
		// 		changes.objectHierarchy = otherChanges.objectHierarchy;
		//
		// 		//Add new mfi tto data and addChangedRows
		// 		newMfi.forEach((row) => data.push(row));
		// 		changes.push(copy);
		// 		data.sort(sortByReference);
		// 		if (changes.length > 0 && addChangedRows) addChangedRows(changes);
		// 	}
		// }
	};

	const getNodeData = (node, nestingLevel) => {
		let data = {};
		if (node.data) data.row = node.data;
		else {
			data.row = node;
		}

		data.id = data?.row?.uuid;
		data.isLeaf = !node.children || node.children.length === 0;
		data.nestingLevel = nestingLevel;
		data.children = node.children;
		//Check if the node should be open
		let open = false;
		if (filteredList && filterExpanded.current.includes(data.id)) {
			open = true;
		} else {
			open = data.id === topNodeId || expanded.includes(data.id);
		}

		data.isOpenByDefault = open;

		return {
			data,
			nestingLevel,
			node,
		};
	};

	// The `treeWalker` function runs only on tree re-build which is performed
	// whenever the `treeWalker` prop is changed.
	function* treeWalker() {
		// Step [1]: Define the root node of our tree. There can be one or
		// multiple nodes.
		for (let i = 0; i < treeData.length; i++) {
			yield getNodeData(treeData[i], 0);
		}

		while (true) {
			// Step [2]: Get the parent component back. It will be the object
			// the `getNodeData` function constructed, so you can read any data from it.
			const parent = yield;

			for (let i = 0; parent.node.children && i < parent.node.children.length; i++) {
				// Step [3]: Yielding all the children of the provided component. Then we
				// will return for the step [2] with the first children.
				yield getNodeData(parent.node.children[i], parent.nestingLevel + 1);
			}
		}
	}

	// Node component receives all the data we created in the `treeWalker` +
	// internal openness state (`isOpen`), function to change internal openness
	// state (`setOpen`) and `style` parameter that should be added to the root div.
	const Node = React.memo(
		({ data, isOpen, style, setOpen }) => {
			// const [row, setRow] = useState({});
			const row = data.row;
			let [checked, setChecked] = useState(false);
			let [indeterminate, setIndeterminate] = useState(false);
			const { handleClick } = useClickHandler(singleClick, doubleClick, tripleClick);

			// useEffect(() => {
			//     setRow(data.row);
			// }, [data?.row, data?.row?.uuid, data?.row?.versionUuid, data?.row?.standardObjectUuid, data?.row?.standardObjectVersionUuid]);

			useEffect(() => {
				if (row.setupSheets?.includes(origin)) setChecked(true);
				else if (row.indeterminateSetupSheets?.includes(origin)) setIndeterminate(true);
			}, [row.setupSheets?.length, row.indeterminateSetupSheets?.length]);

			let checkbox = (
				<>
					{checkboxes.length > 0
						? checkboxes.map((checkbox, index) => (
								<Checkbox
									checked={checked}
									// checked={row.setupForms && row.setupForms.indexOf(include) > -1 || row.uuid === topNodeId}
									onClick={(e, checked) => {
										e.stopPropagation();
										checkboxToggled(row, checkbox, e.target.checked);
										// addChangedRows(row, include, e.target.checked);
									}}
									style={
										index + 1 === checkboxes.length
											? { marginRight: data.nestingLevel * 15 + 5, padding: "0px" }
											: {}
									}
									key={checkbox}
									color={"primary"}
									indeterminate={indeterminate}
								/>
						  ))
						: ""}
				</>
			);

			let ref = row[refField];
			if (
				!sharedState.showFullRef &&
				// !filteredList &&
				allowSmallRef
			) {
				if (row[refField]) ref = getSmallRef(row[refField]);
				else ref = getSmallRef(row.reference);
			}

			let leftPadding = data.nestingLevel * 10 + (data.isLeaf ? 0 : 0);
			let selectedBorderStyle = {};
			if (borderIds.includes(row.uuid))
				selectedBorderStyle = {
					margin: "2px",
					boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
				};

			let highlightStyle = {};

			// Any warnings or errors we want to show on each row. Array of strings, each string will be iterated over in the tooltip to list errors and warnings
			let warnings = [];
			let errors = [];

			if (!data.row.stockNumber?.uuid && !data.row.stockNo) {
				errors.push("No stock number found");
			}

			let label = (
				<div
					style={{
						backgroundColor: highlightIds.includes(row.uuid) ? "rgb(255,255,0, .2)" : "",
						...selectedBorderStyle,
						display: "flex",
					}}
				>
					<Tooltip
						title={`${row[refField]} - ${row.title}`}
						disableInteractive
						placement="bottom-start"
						followCursor
					>
						<div className={"fade-overflow"} style={{ flexGrow: "2" }}>
							<Reference reference={ref} tree />
							<TreeNodeIcon
								row={{
									...row,
									isPacket:
										row.objectTypeUuid === sharedState.dbConstants.packetObject?.referenceUuid,
								}}
							/>
							{editable && !row.deleted ? (
								<EditableLabel
									initialValue={row.title}
									changed={changeIds.includes(row.uuid)}
									onBlur={(value) =>
										updateRow(row.uuid, "title", value, mapOfTreeNodes.current.get(row.uuid))
									}
									uuid={row.uuid !== "emptyId" ? row.uuid : undefined}
									leftPadding={leftPadding}
									version={showVersions && row.versionControl}
								/>
							) : (
								<span>{row.title}</span>
							)}
							{showVersions && row.versionControl ? <Version version={row.versionControl} /> : ""}
						</div>
					</Tooltip>
					{row.added && (
						<span title="Added" style={{ paddingRight: "10px" }}>
							<PlusIcon color="#166534" width="24" height="24" />
						</span>
					)}
					{row.deleted && (
						<span title="Deleted" style={{ paddingRight: "10px" }}>
							<MinusIcon color="red" width="24" height="24" />
						</span>
					)}
					{row.descendantModified && (
						<span title="Contains modified rows" style={{ paddingRight: "10px" }}>
							<AsteriskIcon color="#ca8a04" width="12" height="12" />
						</span>
					)}
					{row.changes && (
						<Tooltip
							classes={{ tooltip: "MUILight-tooltip" }}
							title={
								<React.Fragment>
									<h5>Changes:</h5>
									{Object.keys(row.changes).map((key) => (
										// Change array is reversed, because if it was changed from null to a value, the array only has one value, which means the first item could be either the new value or the old value, but the last item in the array is always the new value
										<p className="m-0 d-flex justify-content-between">
											<strong>{humanize(key)}: </strong>{" "}
											<span className="text-danger mx-1">{row.changes[key].reverse()[1]}</span>{" "}
											<ArrowRightIcon />
											<span className="text-success mx-1">{row.changes[key][0]}</span>
										</p>
									))}
								</React.Fragment>
							}
						>
							<span style={{ paddingRight: "10px" }}>
								<PlusSlashMinusIcon color="#ca8a04" width="24" height="24" />
							</span>
						</Tooltip>
					)}
					{warnings.length > 0 && showWarnings && (
						<span title={warnings.join(", ")} style={{ paddingRight: "5px" }}>
							<WarningIcon />
						</span>
					)}
					{errors.length > 0 && showErrors && (
						<span title={errors.join(", ")} style={{ paddingRight: "5px" }}>
							<ErrorIcon />
						</span>
					)}
					{rowButtons?.length > 0 && rowButtonsForObjects && showVersions && row.versionControl && (
						<div className={"d-flex"}>
							{rowButtons.map((button) => (
								<button
									className={button.classes}
									onClick={() => button.onClick(row)}
									style={{ padding: "2px 4px", marginRight: "5px" }}
								>
									{button.text}
								</button>
							))}
						</div>
					)}
				</div>
			);
			if (data.isOpenByDefault) setOpen(true);

			let children = data.children;

			highlightStyle = {
				// boxShadow: '0px 0px 2px 3px black',
				backgroundColor: "#F6F9FC",
				color: "#16181B",
				textDecoration: "none",
				outline: "5px auto #2962FF",
				borderRadius: "5",
				borderWidth: "2",
				...highlightStyle,
			};

			let changeStyle = {
				backgroundColor: "rgba(255, 255, 0, 0.2)",
			};

			if (dragOverObj.id === row.uuid) {
				highlightStyle.fontWeight = 700;
				delete highlightStyle.outline;

				if (dragOverObj.section.third === "bottom")
					highlightStyle = {
						...highlightStyle,
						borderBottom: "5px solid #2962FF",
						borderLeft: "2px solid #2962FF",
						borderRight: "2px solid #2962FF",
					};
				if (dragOverObj.section.third === "top")
					highlightStyle = {
						...highlightStyle,
						borderTop: "5px solid #2962FF",
						borderLeft: "2px solid #2962FF",
						borderRight: "2px solid #2962FF",
					};
				if (dragOverObj.section.third === "middle")
					highlightStyle = {
						...highlightStyle,
						border: "3px groove #2962FF",
					};
			} else if (row.uuid !== highlightId) highlightStyle = {};

			if (!changeIds || changeIds.indexOf(row.uuid) === -1) changeStyle = {};
			if (row.added) highlightStyle.backgroundColor = "hsl(142.78deg 64.23% 24.12% / 20%)";
			if (row.deleted) highlightStyle.backgroundColor = "hsl(0deg 100% 50% / 20%)";
			if (row.changes) highlightStyle.backgroundColor = "hsl(40.61deg 96.12% 40.39% / 20%)";
			if (row.descendantModified) highlightStyle.backgroundColor = "hsl(40.61deg 96.12% 40.39% / 5%)";

			return (
				<TreeItem
					key={row.uuid}
					nodeId={row.uuid + ""}
					label={label}
					checkbox={checkbox}
					draggable={true}
					onDragOver={(e) => handleDragOver(e, row)}
					onDrop={(e) => handleDrop(e, row)}
					onDragEnd={resetDragOverObj}
					data-rowid={row.uuid}
					styledNode={{}}
					className={`tree-node`}
					// className={dragOverObj.id === uuid ? 'border' : 'no-border'}
					onContextMenu={handleRightClick}
					onClick={(e) => handleClick(e, row)}
					onKeyDown={handleKeyPress}
					paddingAfterCheckbox={checkboxes?.length > 0}
					setOpen={setOpen}
					childNodes={children || []}
					style={{
						...style,
						overflowY: "auto",
						overflowX: "hidden",
						paddingLeft: leftPadding,
						...changeStyle,
						...highlightStyle,
					}}
				></TreeItem>
			);
		},
		(prevProps, nextProps) => {
			let prevUuid = prevProps.data.row.uuid;
			let nextUuid = nextProps.data.row.uuid;
			let prevOpen = prevProps.isOpen;
			let nextOpen = nextProps.isOpen;
			const { style: prevStyle } = prevProps;
			const { style: nextStyle } = nextProps;
			let styleDiff = !shallowDiffers(prevStyle, nextStyle);
			return prevUuid === nextUuid && prevOpen === nextOpen && styleDiff;
		}
	);

	//#itemHeight #height #size #rowSize #rowHeight #itemSize #nodeHeight #nodeSize
	let spacing = rowButtons?.length > 0 ? 35 : editable ? 30 : 27;
	let defaultExpanded = ["root", topNodeId];

	return (
		<div className={`${stylesModule.tree} tree`} ref={treeContainer} style={{ ...treeDivStyles }}>
			<TreeView
				className={stylesModule.tree + " tree-view"}
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpanded={defaultExpanded}
				expanded={filteredList ? filterExpanded.current : expanded}
				onNodeToggle={nodeToggled}
				defaultExpandIcon={<ChevronRightIcon />}
				draggable={true}
				onDragStart={handleDragStart}
				style={{ marginBottom: "0px", width: "100%", paddingLeft: "0px" }}
				// selected={rowToSelect.uuid}
				selected={selectedRow?.uuid}
			>
				{!treeData || !treeData.length ? (
					isLoading ? (
						<h4>Loading Data</h4>
					) : (
						<EmptyPanelMessage message={emptyMessage} />
					)
				) : (
					<Tree
						treeWalker={treeWalker}
						itemSize={spacing}
						height={treeHeight || Math.floor(treeContainer.current.clientHeight) || 100}
						width={"100%"}
						className={props.className + " tree-in-tree-view"}
						ref={tree}
						async
					>
						{Node}
					</Tree>
				)}
			</TreeView>
			<Menu
				keepMounted
				open={mouseCoord.mouseY !== null}
				onClose={handleContextMenuClose}
				anchorReference="anchorPosition"
				anchorPosition={
					mouseCoord.mouseY !== null && mouseCoord.mouseX !== null
						? { top: mouseCoord.mouseY, left: mouseCoord.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={insertNode}>Insert</MenuItem>
				<MenuItem onClick={duplicateNode}>Duplicate</MenuItem>
				<MenuItem onClick={deleteNode}>Delete</MenuItem>
			</Menu>
		</div>
	);
}

/**
 * Grabs the object's MFI, builds the Model Master File Index from the Object Master File Index and returns it
 * @param newMfiRecord
 * @param standardObjectUuid
 * @param standardObjectVersionUuid
 */
export const createModelMfiCopy = async (
	newMfiRecord,
	standardObjectUuid,
	standardObjectVersionUuid,
	sharedState,
	dispatch
) => {
	//Get the object's MFI and build the MFI view
	let mfi = await getSingleLevelObjectMfi(standardObjectUuid, standardObjectVersionUuid, dispatch);
	let newMfi = [];
	let descendants = getMfiView(mfi, sharedState);

	if (descendants.length > 0) {
		//TODO-HOLOGRAM_DELETION: Should this method use copyStandardObject for each row? That way it would get all the version and other stuff that happens
		newMfi = copyObjectMfi(
			descendants,
			mfi[0].uuid,
			newMfiRecord.uuid,
			newMfiRecord.reference,
			sharedState.currentUser?.uuid,
			{ updateStandardObjectUuid: true }
		);

		//Assign new uuids and version to sub-objects
		descendants.forEach((row) => {
			if (row.versionUuid) {
				row.uuid = uuidv4();
				//TODO-HOLOGRAM_DELETION: Do we need to do all the version stuff that happens in copyStandardObject?
				row.versionUuid = uuidv4();
			}
		});

		//Re-reference the new mfi
		fixReferences(newMfi, newMfiRecord.uuid, newMfiRecord.reference, {});
	}
	return { changedMfiRows: newMfi, changedObjectRows: descendants.filter((row) => row.versionUuid) };
};

export const getMfiView = (mfi, sharedState) => {
	//What should I do if there are multiple mfi view objects?
	//Run the build type of tree and return it.zo
	return buildTypeOfTreeList(mfi, mfi[0], sharedState.dbConstants.mfiViewType.referenceUuid);
};
