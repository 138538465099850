import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import TitleAutocomplete from "../Autocomplete/TitleAutocomplete";
import { useTrackedState } from "../../utils/store";
import { formatDate } from "../../utils/DateUtils";
import DatePicker from "@mui/lab/DatePicker";
import BootstrapSelect from "../BootstrapComponents/Select/BootstrapSelect";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import { getCall, getSingleLevelObjectMfi, getUrl } from "../../utils/ApiUtils";

import stylesModule from "./OpenPointForm.module.scss";
import { convertStandardObjectMfiToObject } from "../../utils/StandardObject";

/**
 * A sub component for managing the state of each Open Point row individually
 * @param { openPoint: _openPoint, updateOpenPoint, removeOpenPoint }
 * @constructor
 */
const OpenPointRow = ({ openPoint: _openPoint, updateOpenPoint, removeOpenPoint }) => {
	//state variables
	const [openPointRow, setOpenPointRow] = useState({});

	useEffect(() => {
		setOpenPointRow(_openPoint);
	}, [_openPoint]);

	//Other Methods
	const onInputChange = (event, name) => {
		setOpenPointRow({ ...openPointRow, [name]: event.target.value });
	};

	const onSelectChange = (event, name, newValue) => {
		setOpenPointRow({ ...openPointRow, [name]: newValue });
	};

	const onInputBlur = (event, name) => {
		updateOpenPoint(openPointRow, name);
	};

	const onDateInputChange = (date, name) => {
		setOpenPointRow({ ...openPointRow, [name]: date });
	};

	return (
		<>
			<Grid container columns={{ xs: 11 }} className={""} style={{}}>
				<Grid container item xs={1} className={stylesModule.thickBorder} style={{}} alignContent="stretch">
					<Grid
						container
						item
						xs={12}
						className={stylesModule.fullWidthBody}
						style={{}}
						alignContent="stretch"
					>
						<Grid container item xs={6} className={stylesModule.border} style={{}} alignContent="stretch">
							<TextField
								label={""}
								value={openPointRow.reference}
								onChange={(e) => onInputChange(e, "reference")}
								onBlur={(e) => onInputBlur(e, "reference")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
						<Grid container item xs={3} className={stylesModule.border} style={{}} alignContent="stretch">
							<Typography paragraph={true} variant={"body1"}>
								{openPointRow.lineNumber || 1}
							</Typography>
						</Grid>
						<Grid container item xs={3} className={stylesModule.border} style={{}} alignContent="stretch">
							<TextField
								label={""}
								value={openPointRow.priority}
								onChange={(e) => onInputChange(e, "priority")}
								onBlur={(e) => onInputBlur(e, "priority")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={1} className={stylesModule.thickBorder} style={{}}>
					<Grid container item xs={12} className={""} style={{}} alignContent="stretch">
						<Grid container item xs={3} className={stylesModule.border} style={{}} alignContent="stretch">
							<TextField
								label={""}
								value={openPointRow.openedBy}
								onChange={(e) => onInputChange(e, "openedBy")}
								onBlur={(e) => onInputBlur(e, "openedBy")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
						<Grid container item xs={3} className={stylesModule.border} style={{}} alignContent="stretch">
							<DatePicker
								autoOk
								variant="inline"
								label=""
								format="MM/DD/yyyy"
								value={openPointRow.dateOpened}
								InputAdornmentProps={{
									position: "start",
									style: { display: openPointRow.dateOpened ? "none" : "visible" },
								}}
								className={"full-width full-height"}
								style={{ height: "100%" }}
								onChange={(date) => onDateInputChange(date, "dateOpened")}
								InputProps={{ className: "full-height" }}
							/>
						</Grid>
						<Grid container item xs={3} className={stylesModule.border} style={{}} alignContent="stretch">
							<TextField
								label={""}
								value={openPointRow.ccode}
								onChange={(e) => onInputChange(e, "ccode")}
								onBlur={(e) => onInputBlur(e, "ccode")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
						<Grid container item xs={3} className={stylesModule.border} style={{}} alignContent="stretch">
							<TextField
								label={""}
								value={openPointRow.contactInfo}
								onChange={(e) => onInputBlur(e, "contactInfo")}
								onBlur={(e) => onInputBlur(e, "contactInfo")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={7} className={stylesModule.thickBorder} style={{}}>
					<Grid container item xs={6} className={stylesModule.fullWidthBody} style={{}}>
						<Grid container item xs={12} className={""} style={{}}>
							<Grid container item xs={5} className={""} style={{}}>
								<Grid item xs={4} className={stylesModule.border} style={{}}>
									<TextField
										label={""}
										value={openPointRow.approvalLevel}
										onChange={(e) => onInputChange(e, "approvalLevel")}
										onBlur={(e) => onInputBlur(e, "approvalLevel")}
										className={"full-width full-height"}
										multiline
									/>
								</Grid>
								<Grid item xs={4} className={stylesModule.border} style={{}}>
									<TextField
										label={""}
										value={openPointRow.modelTitle}
										onChange={(e) => onInputChange(e, "modelTitle")}
										onBlur={(e) => onInputBlur(e, "modelTitle")}
										className={"full-width full-height"}
										multiline
									/>
								</Grid>
								<Grid item xs={2} className={stylesModule.border} style={{}}>
									<TextField
										label={""}
										value={openPointRow.modelId}
										onChange={(e) => onInputChange(e, "modelId")}
										onBlur={(e) => onInputBlur(e, "modelId")}
										className={"full-width full-height"}
										multiline
									/>
								</Grid>
								<Grid item xs={2} className={stylesModule.border} style={{}}>
									<TextField
										label={""}
										value={openPointRow.version}
										onChange={(e) => onInputChange(e, "version")}
										onBlur={(e) => onInputBlur(e, "version")}
										className={"full-width full-height"}
										multiline
									/>
								</Grid>
							</Grid>
							<Grid container item xs={7} className={""} style={{}}>
								<Grid item xs={3} className={stylesModule.border} style={{}}>
									<TitleAutocomplete
										name={"changeType"}
										label={""}
										value={openPointRow.changeType}
										options={[
											{ title: "Change Request/Open Point" },
											{ title: "Checklist/Protocol/Procedures" },
											{ title: "Syntax" },
										]}
										getOptionLabel={(option) => option.title}
										handleChange={(e, name, newValue) => onSelectChange(e, "changeType", newValue)}
										handleBlur={(e) => onInputBlur(e, "changeType")}
										clearOnBlur={false}
										multiple={false}
										className={"full-width full-height"}
									/>
								</Grid>
								<Grid item xs={5} className={stylesModule.border} style={{}}>
									<TitleAutocomplete
										name={"reviewPointType"}
										label={""}
										value={openPointRow.reviewPointType}
										options={[
											{ title: "UP.1 UBM Protocol" },
											{ title: "CL 2. Checklist Work Performed" },
											{ title: "FA 3.Financial Analysis" },
											{ title: "BE 4.Business Enterprise Performance" },
										]}
										getOptionLabel={(option) => option.title}
										handleChange={(e, name, newValue) =>
											onSelectChange(e, "reviewPointType", newValue)
										}
										handleBlur={(e) => onInputBlur(e, "reviewPointType")}
										clearOnBlur={false}
										multiple={false}
										className={"full-width full-height"}
									/>
								</Grid>
								<Grid item xs={2} className={stylesModule.border} style={{}}>
									<TitleAutocomplete
										name={"newVersionNewDocument"}
										label={""}
										value={openPointRow.newVersion}
										options={[{ title: "New Version" }, { title: "New Document" }]}
										getOptionLabel={(option) => option.title}
										handleChange={(e, name, newValue) =>
											onSelectChange(e, "newVersionNewDocument", newValue)
										}
										handleBlur={(e) => onInputBlur(e, "newVersionNewDocument")}
										clearOnBlur={false}
										multiple={false}
										className={"full-width full-height"}
									/>
								</Grid>
								<Grid item xs={2} className={stylesModule.border} style={{}}>
									<TextField
										label={""}
										value={openPointRow.title}
										onChange={(e) => onInputChange(e, "title")}
										onBlur={(e) => onInputBlur(e, "title")}
										className={"full-width full-height"}
										multiline
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} className={stylesModule.border} style={{}}>
						<TextField
							label={""}
							value={openPointRow.description}
							onChange={(e) => onInputChange(e, "description")}
							onBlur={(e) => onInputBlur(e, "description")}
							className={"full-width full-height"}
							multiline
							rows={3}
						/>
					</Grid>
				</Grid>
				<Grid container item xs={3} className={stylesModule.thickBorder} style={{}}>
					<Grid container item xs={3} className={stylesModule.fullWidthBody} style={{}}>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<TextField
								label={""}
								value={openPointRow.assignedTo}
								onChange={(e) => onInputChange(e, "assignedTo")}
								onBlur={(e) => onInputBlur(e, "assignedTo")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<DatePicker
								autoOk
								variant="inline"
								label=""
								format="MM/DD/yyyy"
								value={openPointRow.dateAssigned}
								InputAdornmentProps={{
									position: "start",
									style: { display: openPointRow.dateAssigned ? "none" : "visible" },
								}}
								className={"full-width full-height"}
								onChange={(date) => onDateInputChange(date, "dateAssigned")}
								InputProps={{ className: "full-height" }}
							/>
						</Grid>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<DatePicker
								autoOk
								variant="inline"
								label=""
								format="MM/DD/yyyy"
								value={openPointRow.dueDate}
								InputAdornmentProps={{
									position: "start",
									style: { display: openPointRow.dueDate ? "none" : "visible" },
								}}
								className={"full-width full-height"}
								onChange={(date) => onDateInputChange(date, "dueDate")}
								InputProps={{ className: "full-height" }}
							/>
						</Grid>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<TextField
								label={""}
								value={openPointRow.changeRequestPacketNumber}
								onChange={(e) => onInputChange(e, "changeRequestPacketNumber")}
								onBlur={(e) => onInputBlur(e, "changeRequestPacketNumber")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
					</Grid>
					<Grid container item xs={6} className={""} style={{}}>
						<Grid item xs={12} className={stylesModule.border} style={{}}>
							<TextField
								label={""}
								value={openPointRow.disposition}
								onChange={(e) => onInputChange(e, "disposition")}
								onBlur={(e) => onInputBlur(e, "disposition")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
					</Grid>
					<Grid container item xs={3} className={""} style={{}}>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<TextField
								label={""}
								value={openPointRow.approvedBy}
								onChange={(e) => onInputChange(e, "approvedBy")}
								onBlur={(e) => onInputBlur(e, "approvedBy")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<DatePicker
								autoOk
								variant="inline"
								label=""
								format="MM/DD/yyyy"
								value={openPointRow.dateApproved}
								InputAdornmentProps={{
									position: "start",
									style: { display: openPointRow.dateApproved ? "none" : "visible" },
								}}
								className={"full-width full-height"}
								onChange={(date) => onDateInputChange(date, "dateApproved")}
								InputProps={{ className: "full-height" }}
							/>
						</Grid>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<TextField
								label={""}
								value={openPointRow.closedBy}
								onChange={(e) => onInputChange(e, "closedBy")}
								onBlur={(e) => onInputBlur(e, "closedBy")}
								className={"full-width full-height"}
								multiline
							/>
						</Grid>
						<Grid item xs={3} className={stylesModule.border} style={{}}>
							<DatePicker
								autoOk
								variant="dialog"
								label=""
								format="MM/DD/yyyy"
								value={openPointRow.dateClosed}
								InputAdornmentProps={{
									position: "start",
									style: { display: openPointRow.dateClosed ? "none" : "visible" },
								}}
								className={"full-width full-height"}
								onChange={(date) => onDateInputChange(date, "dateClosed")}
								InputProps={{ className: "full-height" }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={1} className={stylesModule.centerText}>
				<Button
					variant="contained"
					color="secondary"
					onClick={(e) => removeOpenPoint(openPointRow)}
					className={stylesModule.buttonTopMargin}
				>
					Delete
				</Button>
			</Grid>
		</>
	);
};

/**
 * A component that allows a user to build a list of objects based on the Row Sub Component
 * @param { openPoints: _openPoints, updateOpenPoints }
 * @constructor
 */
const OpenPoints = ({ openPoints: _openPoints, updateOpenPoints, itemToBeChanged }) => {
	//state variables
	const [openPoints, setOpenPoints] = useState([]);
	const [objects, setObjects] = useState([]);
	const [currentObject, setCurrentObject] = useState({});
	const [height, setHeight] = useState(0);

	const listContainer = useRef();
	const sharedState = useTrackedState();

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		//For each open point get the associated standard object git
		setOpenPoints(_openPoints);
		getOpenPointContexts();
	}, [_openPoints]);

	//Other Methods
	// useEffect( () => {
	// 	setHeight(listContainer.current.clientHeight)
	// }, []);

	const getOpenPointContexts = async () => {
		let promises = [];
		_openPoints.forEach((op) => {
			if (op.objectUuid && op.objectVersionUuid) {
				//Get the standard object git
				promises.push(getCall(getUrl("getStandardObjectGit", [op.objectUuid, op.objectVersionUuid])));
			}
		});

		Promise.all(promises).then((res) => {
			let map = new Map();
			res.forEach((obj) => map.set(obj.uuid, obj));
			setObjects([...map.values()]);

			//Get the current object and if it is in the list of objects set the current object to that, otherwise set it to 'all'
			let topMost = getTopMostObject(sharedState);

			if (map.has(topMost.uuid)) setCurrentObject(topMost);
			else setCurrentObject("all");
		});
	};

	/**
	 * Adds a Open Point with blank title and value to array
	 * @param
	 */
	const addOpenPoint = () => {
		let newOpenPoint = createNewOpenPoint(sharedState);
		newOpenPoint.lineNumber = openPoints.length + 1;

		let newArr = [...openPoints, newOpenPoint];
		setOpenPoints(newArr);
	};

	/**
	 * Update the openPoints array removing the row to delete, pass the updated array back to parent
	 * @param rowToDelete
	 */
	const removeOpenPoint = (rowToDelete) => {
		let newArr = openPoints.filter((row) => row.uuid !== rowToDelete.uuid);
		setOpenPoints(newArr);
		updateOpenPoints(newArr);
	};

	/**
	 * Find the row in the array update it, update state and pass the updated array back to the parent
	 * @param updatedRow, name
	 */
	const updateOpenPoint = (updatedRow, name) => {
		//Find row in array
		let rowToUpdate = openPoints.find((row) => row.uuid === updatedRow.uuid);
		rowToUpdate[name] = updatedRow[name];
		updateOpenPoints(openPoints);
	};

	const handleOpenPointContextChange = (newValue) => {
		if (newValue !== "all") setCurrentObject(objects.find((row) => row.uuid === newValue));
		else setCurrentObject("all");
	};

	return (
		<>
			<BootstrapSelect
				label={"Object:"}
				value={currentObject.uuid ? currentObject.uuid : "all"}
				optionGroups={[
					{
						title: "Select an object",
						options: [
							{ value: "all", label: "All Objects" },
							...objects.map((row) => ({ value: row.uuid, label: `${row.title} : ${row.mfiReference}` })),
						],
					},
				]}
				handleChange={handleOpenPointContextChange}
				colStyles={{ width: "300px", margin: "0 auto" }}
				labelStyle={{ paddingRight: "10px", fontSize: "18px", fontWeight: 800 }}
			/>
			<Grid container ref={listContainer} className={`${"contentEnd"}`}>
				<Grid container columns={{ xs: 11 }} className={""} style={{}}>
					<Grid container item xs={1} className={stylesModule.thickBorder} style={{}}>
						<Grid item xs={12} className={""} style={{}}>
							<Typography paragraph={true} variant={"body1"}>
								&nbsp;
							</Typography>
						</Grid>
						<Grid container item xs={12} className={stylesModule.fullWidthHeader} style={{}}>
							<Grid
								item
								xs={6}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Form Reference
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Line
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Priority
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item xs={1} className={stylesModule.thickBorder} style={{}}>
						<Grid container item xs={12} className={""} style={{ height: "2em" }}>
							<Grid item xs={12} className={stylesModule.heading} style={{}}>
								<Typography paragraph={true} variant={"body1"} className={stylesModule.bold}>
									Requested By:
								</Typography>
							</Grid>
						</Grid>
						<Grid container item xs={12} className={""} style={{}}>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Opened/Requested By
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Date Opened
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									LE/Company/CCODE
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Contact: #/Email
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item xs={7} className={stylesModule.thickBorder} style={{}}>
						<Grid container item xs={12} className={stylesModule.heading} style={{ height: "2em" }}>
							<Typography paragraph={true} variant={"body1"} className={stylesModule.bold}>
								Item to be Changed:
							</Typography>
						</Grid>
						<Grid container item xs={6} className={stylesModule.fullWidthHeader} style={{}}>
							<Grid container item xs={12} className={""} style={{}}>
								<Grid container item xs={5} className={""} style={{}}>
									<Grid
										item
										xs={4}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography
											paragraph={true}
											variant={"body1"}
											className={stylesModule.smallText}
										>
											Approval Level Required <br /> UBM BM APP <br /> CEO 1. <br /> BM Mgr 2.
										</Typography>
									</Grid>
									<Grid
										item
										xs={4}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography paragraph={true} variant={"body1"}>
											Model/App Title
										</Typography>
									</Grid>
									<Grid
										item
										xs={2}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography paragraph={true} variant={"body1"}>
											Model/App ID #
										</Typography>
									</Grid>
									<Grid
										item
										xs={2}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography paragraph={true} variant={"body1"}>
											Version #
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={7} className={""} style={{}}>
									<Grid
										item
										xs={3}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography
											paragraph={true}
											variant={"body1"}
											className={stylesModule.smallText}
										>
											UBM Change Type: <br /> Change Request/Open Point <br />{" "}
											Checklist/Protocol/Procedures <br /> Syntax
										</Typography>
									</Grid>
									<Grid
										item
										xs={5}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography
											paragraph={true}
											variant={"body1"}
											className={stylesModule.smallText}
										>
											Review Point Type: <br /> UP 1.UBM Protocol <br /> CL.2 Checklist Work
											Performed <br /> FA 3.Financial Analysis <br /> BE 4.Business Enterprise
											Performance
										</Typography>
									</Grid>
									<Grid
										item
										xs={2}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography paragraph={true} variant={"body1"}>
											New Version <br /> New Document
										</Typography>
									</Grid>
									<Grid
										item
										xs={2}
										className={`${stylesModule.rotateText} ${stylesModule.border}`}
										style={{}}
									>
										<Typography paragraph={true} variant={"body1"}>
											Document Title
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} className={stylesModule.border} style={{}}>
							<Typography paragraph={true} variant={"body1"}>
								Action Required/Description of Change to be Made:
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={3} className={stylesModule.thickBorder} style={{}}>
						<Grid container item xs={12} className={stylesModule.heading} style={{ height: "2em" }}>
							<Typography paragraph={true} variant={"body1"} className={stylesModule.bold}>
								Change Task Assigned To:
							</Typography>
						</Grid>
						<Grid container item xs={3} className={stylesModule.fullWidthHeader} style={{}}>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Assigned To
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Date Assigned
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Due Date
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Change Request #
								</Typography>
							</Grid>
						</Grid>
						<Grid container item xs={6} className={""} style={{}}>
							<Grid item xs={12} className={stylesModule.border} style={{}}>
								<Typography paragraph={true} variant={"body1"}>
									Disposition <br /> -Complete <br /> -Approved Next Version <br /> -Approved Future
									Version <br /> -Rejected
								</Typography>
							</Grid>
						</Grid>
						<Grid container item xs={3} className={""} style={{}}>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Approved By
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Date Approved
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Closed By
								</Typography>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${stylesModule.rotateText} ${stylesModule.border}`}
								style={{}}
							>
								<Typography paragraph={true} variant={"body1"}>
									Date Closed
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container columns={{ xs: 1 }} className={""} style={{}}>
					<Button variant="contained" color="primary" onClick={addOpenPoint}>
						New
					</Button>
				</Grid>

				{currentObject?.uuid
					? openPoints
							.filter((row) => row.objectUuid === currentObject.uuid)
							.map((row) => (
								<Grid container item key={row.uuid}>
									<OpenPointRow
										openPoint={row}
										updateOpenPoint={updateOpenPoint}
										removeOpenPoint={removeOpenPoint}
									/>
								</Grid>
							))
					: openPoints.map((row) => (
							<Grid container item key={row.uuid}>
								<OpenPointRow
									openPoint={row}
									updateOpenPoint={updateOpenPoint}
									removeOpenPoint={removeOpenPoint}
								/>
							</Grid>
					  ))}
			</Grid>
		</>
	);
};

export default OpenPoints;

export const createNewOpenPoint = (sharedState) => {
	let top = getTopMostObject(sharedState);
	let { currentUser } = sharedState;

	return {
		uuid: uuidv4(),
		title: top?.title || "",
		value: "",
		version: top.computerVersion,
		reference: top?.mfiReference || "",
		openedBy: (currentUser?.firstName[0] || "") + (currentUser?.lastName[0] || ""),
		dateOpened: Date.now(),
		contactInfo: currentUser.email,
		objectUuid: top.uuid,
		objectVersionUuid: top.versionUuid,
		autofocus: true,
	};
};

export const createNewOpenPointFromObject = async (sharedState, dispatch) => {
	//Get the open point object from the Data Warehouse
	let op = sharedState.destinationModel.find(
		(row) => row.standardObjectUuid === sharedState.dbConstants.openPointObject.referenceUuid
	);

	let mfi = await getSingleLevelObjectMfi(op.standardObjectUuid, op.standardObjectVersionUuid);
	let newOp = await convertStandardObjectMfiToObject(mfi, sharedState, dispatch);
	newOp.sourceObjectUuid = op.standardObjectUuid;
	newOp.sourceObjectVersionUuid = op.standardObjectVersionUuid;
	newOp.autofocus = true;
	newOp.uuid = uuidv4();
	return newOp;
};
