import { toAst } from "./VisitorUtil";
import { getTopMostObject } from "../../components/ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import { getStandardObjectRowFromPath } from "../StandardObject";

// ------------------------- Demo Calls -------------------------
// const inputText = "SUM(SUM(SUM(1,1),SUM(1,1)),SUM(1,1))"
// const inputText = "SUM(1,1)"
// const inputText = 'AGE("January 27, 1995")';
// const inputText = 'CURRENTUSER()';
// --------------------------------------------------------------

const AsyncFunction = new Function(`return Object.getPrototypeOf(async function(){}).constructor`)();

const evaluateAst = async (astFromVisitor, api, functions, passedParameters) => {
	const functionName = astFromVisitor.children.FunctionName[0].image;
	let fn = functions.filter((fn) => fn.name === functionName);
	if (fn.length !== 1) {
		throw new Error(`Function ${functionName} is not defined (or is duplicated)`);
	}
	fn = fn[0];

	const fnParams = fn.parameter;
	let parameters = astFromVisitor.children.parameters[0].children;
	// Filter out the commas and parentheses
	parameters = Object.fromEntries(
		Object.entries(parameters).filter(
			([key]) =>
				key === "fn" ||
				key === "StringLiteral" ||
				key === "NumberLiteral" ||
				key === "JavaScriptObject" ||
				key === "JavaScriptArray"
		)
	);

	let passedArguments = await Promise.all(
		Object.keys(parameters).map(async (p) => {
			switch (p) {
				case "fn":
					return await Promise.all(parameters[p].map(async (n) => await evaluateAst(n, api, functions)));
				case "NumberLiteral":
					return parameters[p].map((n) => Number(n.image));
				case "StringLiteral":
					return parameters[p].map((n) => JSON.parse(n.image));
				case "JavaScriptObject":
					return parameters[p].map((n) => JSON.parse(n.image));
				case "JavaScriptArray":
					return parameters[p].map((n) => JSON.parse(n.image));
				default:
					throw new Error(`Unrecognized parameter type: ${p}`);
			}
		})
	);

	passedArguments = passedArguments.flat();

	let fnParamNames = fnParams.map((fP) => fP.name);
	fnParamNames.push("api");

	const newFn = AsyncFunction(...fnParamNames, fn.method);

	let params = [];

	fnParams.forEach((fnParam, i) => {
		if (fnParam.repeating === "true") {
			// If the param is repeating, all the remaining passed params belong to it
			params.push(passedArguments.slice(i));
			return;
		}
		params.push(passedArguments[i]);
	});

	if (passedParameters) params = passedParameters;

	return newFn(...params, api);
};

const evaluateExpression = async (expression, sharedState, { harvestedMfi, top, mfi }, params, functions) => {
	let astFromVisitor = toAst(expression);
	const api = {
		currentUser: () => sharedState.currentUser,
		currentObject: () => getTopMostObject(sharedState),
		this: () => harvestedMfi,
		path: (pathArray) => {
			if (mfi) return getStandardObjectRowFromPath(pathArray, mfi);
			else return getStandardObjectRowFromPath(pathArray, sharedState.contextMfi);
		},
		context: () => {
			if (top) return top;
			else return sharedState.contextTop;
		},
		getLinkToCurrentContext: () => {
			let { contextTop: context, selectedWorkspaceRow } = sharedState;

			let obj = {
				linkToSource: "object-master-file-index",
				linkToObjectUuid: context.uuid,
				linkToObjectVersionUuid: context.versionUuid,
				value: null,
				linkToAttributeUuid: null,
			};
			if (selectedWorkspaceRow?.uuid) {
				obj.value = selectedWorkspaceRow.reference;
				obj.linkToAttributeUuid = selectedWorkspaceRow.uuid;
			} else obj.value = context.reference;

			return obj;
		},
	};

	if (!functions || functions?.length < 1) functions = document.functions;
	try {
		return await evaluateAst(astFromVisitor.fn[0], api, functions, params);
	} catch (error) {
		console.warn(error, expression);
		return `ERR`;
	}
};

export default evaluateExpression;
