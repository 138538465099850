import React, { useEffect, useRef, useState } from "react";
import ListInputFieldType from "./ListInputFieldType";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import { updateChangeData } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import {
	ASSOCIATED_OBJECT_GENERAL_TYPE_UUID,
	createObjectHierarchyRecord,
	getObjectIdAndVersionUuid,
	linkAttributeName,
	linkVersionAttributeName,
	ZERO_ROW_UUID,
} from "../../utils/StandardObject";
import { v4 as uuidv4 } from "uuid";
import BootstrapSelect from "../BootstrapComponents/Select/BootstrapSelect";
import { getFilter, getObjectsOfTypeInDataWarehouseByFilter } from "../../utils/ApiUtils";
import { useTrackedState } from "../../utils/store";

const AssociationOutputType = ({
	label,
	field,
	children,
	focus,
	objectClicked,
	updateFieldToAttachTo,
	setShowLinkToObjectMfiDialog,
	updateRelatableObjects,
	setShowRelatableObjectDialog,
	removeObject,
	mfiUpdates,
	dataUpdates,
}) => {
	const [options, setOptions] = useState([]);
	const [value, setValue] = useState("");

	const optionsRef = useRef([]);

	const sharedState = useTrackedState();

	useEffect(() => {
		//Load the options based on the filter
		loadOptions();
	}, []);

	const loadOptions = async () => {
		let opts;
		if (field.filterUuid) {
			let filter = await getFilter(field.filterUuid);
			if (!filter) filter = field.filter;
			else filter = JSON.parse(filter.filter).criteria;

			opts = await getObjectsOfTypeInDataWarehouseByFilter(
				field[linkAttributeName],
				sharedState.destinationModel[0]?.uuid,
				filter
			);
		} else {
			opts = await getObjectsOfTypeInDataWarehouseByFilter(
				field[linkAttributeName],
				sharedState.destinationModel[0]?.uuid
			);
		}

		opts = opts
			.filter((row) => row.uuid !== sharedState.contextTop.uuid)
			.sort((a, b) => a.title.localeCompare(b.title));
		optionsRef.current = opts;
		setOptions(opts);

		//Get the value
		let val;
		let child;
		if (children) child = children[0];

		if (child)
			val = getObjectIdAndVersionUuid({
				uuid: child[linkAttributeName],
				versionUuid: child[linkVersionAttributeName],
			});
		else val = "";
		setValue(val);
	};

	const plusClick = () => {
		updateFieldToAttachTo(field);

		if (field.linkToSource === "object-master-file-index") {
			//If linking to something in my object master file index pull up the dialog for navigating down the object master file index and selecting the object / attribute to link to
			setShowLinkToObjectMfiDialog(true);
		} else {
			updateRelatableObjects(optionsRef.current.map((row) => ({ ...row, parentUuid: ZERO_ROW_UUID })));
			setShowRelatableObjectDialog(true);
		}
	};

	const dropdownChange = (newVal) => {
		let [uuid, versionUuid] = newVal.split("/");
		newVal = options.find((row) => row.uuid === uuid);
		//If the value is reset, delete the child if there is one
		if (newVal === "0") {
			if (children?.length > 0) {
				setValue([]);

				//Send an mfiUpdate and send deleted row as a change
				mfiUpdates({ deletedRows: children });
				dataUpdates({ deletedRows: children });
			}
		}
		//Otherwise we are adding a new object
		else {
			let update = {};
			//Create the hierarchy record
			let hierarchy = sharedState.contextObjectHierarchy;
			let topHierarchyRecord = hierarchy.find(
				(row) =>
					row.descendantStandardObjectUuid === sharedState.contextTop.uuid &&
					row.descendantStandardObjectVersionUuid === sharedState.contextTop.versionUuid
			);
			let newRecord = createObjectHierarchyRecord(topHierarchyRecord, newVal);
			newRecord.pathEnum = topHierarchyRecord.pathEnum + ">" + getObjectIdAndVersionUuid(newVal);
			newRecord.hierarchyTypeUuid = ASSOCIATED_OBJECT_GENERAL_TYPE_UUID;
			update.hierarchyRecords = [newRecord];

			//Create the row referencing the associated object
			newVal = { ...newVal };
			newVal.parentUuid = field.uuid;
			newVal.reference = field.reference + ".01";
			newVal.referenceNo = 1;
			newVal.setupSheets = field.setupSheets;
			newVal.indeterminateSetupSheets = field.indeterminateSetupSheets;
			newVal[linkAttributeName] = newVal.uuid;
			newVal[linkVersionAttributeName] = newVal.versionUuid;
			newVal.generalTypeUuid = ASSOCIATED_OBJECT_GENERAL_TYPE_UUID;
			newVal.uuid = uuidv4();

			//Send mfiUpdate, updated, and deleted rows
			if (children?.length > 0) update.deletedRows = children;

			update.objectRows = [newVal];

			mfiUpdates(update);
			dataUpdates(update);
		}
	};

	return (
		<>
			{field.cardinality === "0+" || field.linkToSource === "object-master-file-index" ? (
				<ListInputFieldType
					label={label}
					field={field}
					children={children}
					plusClick={plusClick}
					editTitles={false}
					objectClicked={objectClicked}
					removeObject={removeObject}
				/>
			) : (
				<BootstrapSelect
					label={label}
					value={value}
					optionGroups={[
						{
							title: "Select",
							options: [
								{ label: "Choose...", value: "0" },
								...options.map((option) => ({
									label: option.title,
									value: getObjectIdAndVersionUuid(option),
								})),
							],
						},
					]}
					handleChange={dropdownChange}
					focus={focus}
				/>
			)}
		</>
	);
};

export default React.memo(AssociationOutputType, (prevProps, nextProps) => {
	let { field: prevField, children: prevChildren, focus: prevFocus } = prevProps;
	let { field: nextField, children: nextChildren, focus: nextFocus } = nextProps;

	return !(
		prevField.uuid !== nextField.uuid ||
		prevField.versionUuid !== nextField.versionUuid ||
		prevField.inputType !== nextField.inputType ||
		prevFocus !== nextFocus ||
		prevChildren.length !== nextChildren.length ||
		(prevChildren && nextChildren && prevChildren[0]?.uuid !== nextChildren[0]?.uuid)
	);
});
