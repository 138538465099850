import { tokenVocabulary } from "./LexerUtil";

const CstParser = require("chevrotain").CstParser;

const FunctionName = tokenVocabulary.FunctionName;
const LParen = tokenVocabulary.LParen;
const RParen = tokenVocabulary.RParen;
const Comma = tokenVocabulary.Comma;
const NumberLiteral = tokenVocabulary.NumberLiteral;
const StringLiteral = tokenVocabulary.StringLiteral;
const JavaScriptObject = tokenVocabulary.JavaScriptObject;
const JavaScriptArray = tokenVocabulary.JavaScriptArray;

// ----------------- parser -----------------
class ExpressionParser extends CstParser {
	constructor() {
		super(tokenVocabulary);

		// for conciseness
		const $ = this;
		$.RULE("expression", () => {
			$.SUBRULE($.fn);
		});

		$.RULE("fn", () => {
			$.CONSUME(FunctionName);
			$.CONSUME(LParen);
			$.SUBRULE($.parameters);
			$.CONSUME(RParen);
		});

		$.RULE("parameters", () => {
			$.MANY_SEP({
				SEP: Comma,
				DEF: () => {
					$.OR([
						{ ALT: () => $.SUBRULE($.fn) },
						{ ALT: () => $.CONSUME(StringLiteral) },
						{ ALT: () => $.CONSUME(NumberLiteral) },
						{ ALT: () => $.CONSUME(JavaScriptObject) },
						{ ALT: () => $.CONSUME(JavaScriptArray) },
					]);
				},
			});
		});

		// very important to call this after all the rules have been defined.
		// otherwise the parser may not work correctly as it will lack information
		// derived during the self analysis phase.
		this.performSelfAnalysis();
	}
}

export { ExpressionParser };
