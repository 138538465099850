import React, { useEffect, useRef, useState } from "react";
import { useTrackedState } from "../../utils/store";
import { getPossibleSourceObjects } from "../VersionControl/VersionUtils";
import DraggableDialog from "./DraggableDialog/Dialog";
import Version from "../VersionControl/Version";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";

import "./ChangeRequestSelectionDialog.scss";
import { CopyIcon, DownArrowIcon, ExclamationTriangle } from "../BootstrapComponents/Icons/Icons";
import { getSmallRef } from "../../utils/StringUtils";
import { ZERO_ROW_UUID } from "../../utils/StandardObject";
import { _updateRow } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import SelectionDialog from "./DraggableDialog/SelectionDialog/SelectionDialog";
import { getObjectGitRecord } from "../../utils/ApiUtils";
import { CardSelector } from "../ItemSelector/CardSelector";

export const ChangeRequestSelectionDialog = ({ changeRequestSourceSelected }) => {
	const [possibleSources, setPossibleSources] = useState([]);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const [showDataWarehouse, setShowDataWarehouse] = useState(false);

	const topObject = useRef({});
	const sharedState = useTrackedState();

	useEffect(() => {
		topObject.current = getTopMostObject(sharedState);
		//get the possible sources
		loadSources();
	}, [sharedState.contextTop.uuid, sharedState.contextTop.versionUuid]);

	useEffect(() => {
		if (sharedState.changeRequestSelectionDialog) setOpen((prev) => !prev);
	}, [sharedState.changeRequestSelectionDialog]);

	const loadSources = async () => {
		let sources = await getPossibleSourceObjects(sharedState);

		sources = sources.map((source) => {
			source.onClick = () => setSelected(source);
			if (source.newerVersion) source.secondaryClick = (e) => reviewLatestVersionClicked(e, source.newerVersion);
			return source;
		});

		//Add the two additional options for
		sources.push({
			hover: "Search for an object in the Data Warehouse",
			title: "An Object in the Data Warehouse",
			value: "Data Warehouse",
			onClick: (row) => {
				setShowDataWarehouse(true);
			},
		});

		setPossibleSources(sources);
	};

	const handleSave = (object) => {
		if (!object && selected?.value === "Data Warehouse") {
			setShowDataWarehouse(true);
			return;
		}
		changeRequestSourceSelected(object || selected);
		setOpen(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const reviewLatestVersionClicked = (e, newerVersion) => {
		e.stopPropagation();
		let url = `?uuid=${newerVersion.uuid}&versionUuid=${newerVersion.versionUuid}${
			newerVersion.objectHierarchy[0] &&
			newerVersion.objectHierarchy[0].ancestorStandardObjectUuid !== ZERO_ROW_UUID
				? `&path=${newerVersion.objectHierarchy[0].pathEnum}`
				: ""
		}`;

		//Open the newer version in a new tab
		window.open(url, "_blank");
	};

	//Add search for data warehouse object
	//Add a general option

	return (
		<>
			{!showDataWarehouse ? (
				<DraggableDialog
					style={{ minHeight: "600px", minWidth: "1200px" }}
					PaperProps={{ style: { minWidth: "900px", width: "75%" } }}
					header={"Suggest a Change to"}
					showDialog={open}
					handleClose={handleClose}
					handleSave={handleSave}
					saveButtonText={"Choose"}
					disableSave={selected < 0}
				>
					{/*<div style={{ display: "flex", justifyContent: "start" }}>*/}
					<CardSelector items={possibleSources} />
					{/*</div>*/}
				</DraggableDialog>
			) : (
				<SelectionDialog
					dialogTitle={"Suggest a change to an object in the data warehouse"}
					treeData={sharedState.destinationModel}
					treeTitle={"Data Warehouse"}
					open={true}
					submitButtonText={"Choose"}
					rowSelected={async (row) => {
						if (!row) return;

						//Verify we have a standardObjectUuid and standardObjectVersionUuid
						if (row?.uuid && row?.standardObjectUuid && row?.standardObjectVersionUuid) {
							setSelected(row);
						}

						row = await getObjectGitRecord(row.standardObjectUuid, row.standardObjectVersionUuid);

						handleSave(row);
					}}
				/>
			)}
		</>
	);
};
