import React, { useState, useEffect, useRef } from "react";
import { getNextRef, getReferenceNo, sortByReference } from "../../../utils/Referencing";
import {
	copyStandardObject,
	createAssociatedObjectCopy,
	createObjectHierarchyRecord,
	findClosestPrimusAncestor,
	getAncestors,
	getObjectIdAndVersionUuid,
	linkAttributeName,
	linkVersionAttributeName,
	ZERO_ROW_UUID,
} from "../../../utils/StandardObject";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useTrackedState } from "../../../utils/store";
import SelectionDialog from "../../Dialog/DraggableDialog/SelectionDialog/SelectionDialog";
import DraggablePrompt from "../../Dialog/DraggablePrompt/DraggablePrompt";
import { updateChangeData } from "../Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { getTopMostObject } from "../Body/CreatorPanel/CreatorPanel";
import SetupField from "./SetupField";
import { getSingleLevelObjectMfi } from "../../../utils/ApiUtils";

export const SetupSheetWindowWithData = ({
	data,
	top,
	windowUuid,
	setupSheet,
	context,
	breadcrumbClicked,
	dataUpdates,
	mfiUpdates,
	openSubObject,
	subObjectWindow = false,
	hierarchy,
}) => {
	const [fields, setFields] = useState([]);
	const [showObjectSelectDialog, setShowObjectSelectDialog] = useState(false);
	const [showRelatableObjectDialog, setShowRelatableObjectDialog] = useState(false);
	const [showLinkToObjectMfiDialog, setShowLinkToObjectMfiDialog] = useState(false);

	const fieldToAttachTo = useRef({});
	const approvedObjectMfi = useRef([]);
	const attachableTypeMfi = useRef([]);
	const relatableObjects = useRef([]);
	const selectedRelatableObjects = useRef([]);
	const selectedLinkToObjectMfiRow = useRef([]);
	const veryTopUuidAndVersion = useRef({});
	const topMfi = useRef([]);
	const breadcrumbs = useRef([]);

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		//Whenever the data or the windowUuid changes get the corresponding setup fields
		getSetupFields();

		//Some setup fields need to be able to link to another object within this object, ensure we have the topMost MFI we can start from
		let top = getTopMostObject(sharedState);
		if (
			top.uuid !== veryTopUuidAndVersion.current.uuid ||
			top.versionUuid !== veryTopUuidAndVersion.current.versionUuid
		) {
			getSingleLevelObjectMfi(top.uuid, top.versionUuid).then((singleMfi) => (topMfi.current = singleMfi));
			veryTopUuidAndVersion.current = { uuid: top.uuid, versionUuid: top.versionUuid };
		}
	}, [data.length, windowUuid]);

	const getSetupFields = () => {
		//Reset the breadcrumbs
		breadcrumbs.current = [];

		//Build the breadcrumbs from the ancestors of this window
		//Get the window row
		let windowRow = data.find((row) => row.uuid === windowUuid);
		if (!windowRow) windowRow = top;

		//Get the ancestors and build the breadcrumbs from them
		let ancestors = [windowRow, ...getAncestors(windowRow, data)].sort(sortByReference);
		ancestors.forEach((ancestor, index) => {
			let text;
			if (index === 0) text = ancestor.reference + "." + ancestor.title;
			else if (ancestor.uuid !== context.uuid) text = getReferenceNo(ancestor.reference) + "." + ancestor.title;
			else text = ancestor.title;
			breadcrumbs.current.push({
				key: ancestor.uuid,
				link: index !== ancestors.length - 1,
				text,
			});
		});

		//Get the fields
		setFields(data.filter((row) => row.parentUuid === windowUuid));
	};

	/**
	 * Call the appropriate function, if I clicked on a subObject I want to open, open it. Otherwise treat it as as breadcrumb click
	 */
	const objectClicked = (uuid) => {
		//If a sub object was clicked call openSubObject, we can find this out by grabbing the corresponding row and check if 'object' is true
		let row = data.find((row) => row.uuid === uuid);
		if (row.isObject) openSubObject(row);
		//Otherwise call breadCrumbClicked
		else breadcrumbClicked(uuid, windowUuid);
	};

	/**
	 * Stores the field that something is being attached to
	 * @param attachTo
	 */
	const updateFieldToAttachTo = (attachTo) => {
		fieldToAttachTo.current = attachTo;
		let children = data.filter((row) => row.parentUuid === attachTo.uuid && row.setupSheets.includes(setupSheet));
		selectedRelatableObjects.current = children.map((row) => ({
			uuid: row[linkAttributeName],
			versionUuid: row[linkVersionAttributeName],
			reference: "0",
			parentUuid: ZERO_ROW_UUID,
			title: row.title,
		}));
	};

	/**
	 * This is called when clicking plus in the setup sheet and choosing an object in the data warehouse to create an instance of
	 * @param obj
	 * @param attachToId
	 */
	const objectToCopySelected = (obj, attachToId) => {
		if (!obj) return;

		if (!obj.uuid) {
			setShowObjectSelectDialog(false);
			fieldToAttachTo.current = {};
			return;
		}

		//Create a copy of the object and it's mfi
		//Get the reference this object should have
		let attachTo = data.find((row) => row.uuid === attachToId);
		let children = data.filter((row) => row.parentUuid === attachTo.uuid);
		let lastChild = children[children.length - 1];

		if (!lastChild)
			//The record we are attaching to has an input type of 'LIST', this row should'nt have it so we'll reset it
			copyAndAttach({ ...attachTo, inputType: "" }, obj, attachTo.reference + ".01", attachTo.uuid);
		else copyAndAttach(lastChild, obj, getNextRef(lastChild.reference).reference, attachTo.uuid);

		//attach copy to current mfi in the correct location using the field to attach to

		//Set open to false
		setShowObjectSelectDialog(false);
	};

	/**
	 * Creates a copy of the object passed in and attaches it in the MFI.
	 * Also creates a matching hierarchy record. This is called by objectToCopySelected.
	 * @param object
	 * @param objectTypeUuid
	 * @param generalTypeUuid
	 * @param standardObjectUuid
	 * @param standardObjectVersionUuid
	 * @param reference
	 * @param attachToUuid
	 */
	const copyAndAttach = (
		object,
		{ objectTypeUuid, generalTypeUuid, standardObjectUuid, standardObjectVersionUuid },
		reference,
		attachToUuid
	) => {
		let rowCopy = copyStandardObject(object, reference, attachToUuid, sharedState.currentUser?.uuid, true, true);

		rowCopy.standardObjectUuid = standardObjectUuid;
		rowCopy.standardObjectVersionUuid = standardObjectVersionUuid;
		rowCopy.generalTypeUuid = generalTypeUuid;
		rowCopy.objectTypeUuid = objectTypeUuid;
		rowCopy.isObject = true;

		rowCopy.title = "(Edit Title)";

		createMatchingHierarchyRecordAndUpdateState(standardObjectUuid, standardObjectVersionUuid, rowCopy);
		fieldToAttachTo.current = {};
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	/**
	 * Creates a hierarchy record for the `rowCopy` being created and
	 * TODO: It seems like we do this in a couple places, we should consolidate them.
	 * @param standardObjectUuid
	 * @param standardObjectVersionUuid
	 * @param rowCopy
	 * @param oldMfi
	 */
	const createMatchingHierarchyRecordAndUpdateState = (
		standardObjectUuid,
		standardObjectVersionUuid,
		rowCopy,
		hierarchyType,
		updateStockNumber = true
	) => {
		//TODO Verify the Object Hierarchy will also be copied
		// let mfiToCopy = await getObjectMfi(standardObjectUuid, standardObjectVersionUuid, dispatch);
		// let topNode = { ...mfiToCopy[0] };
		// let rowsToAdd = copyObjectMfi(mfiToCopy, mfiToCopy[0].uuid, rowCopy.uuid, rowCopy.reference, sharedState.currentUser.uuid);
		rowCopy.standardObjectUuid = standardObjectUuid;
		rowCopy.standardObjectVersionUuid = standardObjectVersionUuid;

		//Create the corresponding hierarchy record
		let hierarchyToAttachTo = findClosestPrimusAncestor(data, rowCopy.reference);
		let ancestorHierarchyRecord = data[0].objectHierarchy.find(
			(row) =>
				row.descendantStandardObjectUuid === hierarchyToAttachTo.uuid &&
				row.descendantStandardObjectVersionUuid === hierarchyToAttachTo.versionUuid
		);

		let newHierarchyRecord = createObjectHierarchyRecord(ancestorHierarchyRecord, { ...rowCopy });
		if (hierarchyType) newHierarchyRecord.hierarchyTypeUuid = hierarchyType;
		sharedState.contextObjectHierarchy.push(newHierarchyRecord);
		sharedState.contextMfi.push(rowCopy);

		if (updateStockNumber && rowCopy.stockNumber && rowCopy.stockNumber.uuid)
			dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: rowCopy.stockNumber });

		let update = {
			objectRows: [rowCopy],
			objectHierarchy: [newHierarchyRecord],
		};

		if (data[0].uuid !== sharedState.contextTop.uuid) update.subObjectToUpdate = data[0];

		//Call dataUpdates passing in the new hierarchy record and the new row
		dataUpdates(update);
	};

	/**
	 * Stock # - 116-511210.226.FUNC1.4100: 0 - JS Functional Method
	 * Called when relatableObjects are selected, attaches them and creates the object hierarchy record for them
	 * @param selectedObjects
	 */
	const relatableObjectsSelected = (selectedObjects) => {
		if (!selectedObjects) return;

		//Get the starting reference  to attach the selected objects at
		let children = data.filter((row) => row.parentUuid === fieldToAttachTo.current.uuid);
		let nextRef = getNextRef(fieldToAttachTo.current.reference + ".00");
		let objectRows = [];
		let newHierarchyRows = [];

		let topHierarchyRecord = hierarchy.find(
			(row) =>
				row.descendantStandardObjectUuid === top.uuid &&
				row.descendantStandardObjectVersionUuid === top.versionUuid
		);

		//Filter out the children that aren't in the selected nodes
		let selectedIds = selectedObjects.map((row) => getObjectIdAndVersionUuid(row));

		let deletedRows = children.filter(
			(row) =>
				!selectedIds.includes(
					getObjectIdAndVersionUuid({
						uuid: row[linkAttributeName],
						versionUuid: row[linkVersionAttributeName],
					})
				)
		);

		let rowsToReference = [];

		//Iterate over the selected objects and attach each one as a changed row to the current object and also create a matching hierarchy record
		selectedObjects.forEach((obj, index) => {
			//Check to see if there is already a child attached
			let child = children.find(
				(row) => row[linkAttributeName] === obj.uuid && row[linkVersionAttributeName] === obj.versionUuid
			);
			if (child) {
				rowsToReference.push(child);
				return;
			}

			let copy = createAssociatedObjectCopy(obj, fieldToAttachTo.current, nextRef);

			let hierarchyRecord = createObjectHierarchyRecord(topHierarchyRecord, obj);

			hierarchyRecord.pathEnum = topHierarchyRecord.pathEnum + ">" + getObjectIdAndVersionUuid(obj);

			objectRows.push(copy);
			hierarchyRecord.hierarchyTypeUuid = sharedState.dbConstants.associatedObjectType.referenceUuid;
			newHierarchyRows.push(hierarchyRecord);

			rowsToReference.push(copy);
			data.push(copy);
			nextRef = getNextRef(nextRef.reference);
		});

		rowsToReference.forEach((row, index) => {
			let ref = getNextRef(fieldToAttachTo.current.reference + "." + index);
			row.reference = ref.reference;
			row.referenceNo = ref.referenceNo;
		});

		let update = {
			deletedRows,
			objectRows,
			hierarchyRecords: newHierarchyRows,
		};
		mfiUpdates(update);
		dataUpdates(update);

		setShowRelatableObjectDialog(false);
	};

	/**
	 * Similar to attaching relatable objects like above
	 * Adds a row pointing to the selected object and attribute.
	 * Creates a hierarchy record attaching the open object with the selected one
	 */
	const linkToObjectMfiSelected = (row, object) => {
		//I will make the assumption that there will always be an object
		if (!row || !object) return;

		//For now assume we can only have one attached
		let update = {};
		let nextRef = getNextRef(fieldToAttachTo.current.reference + ".00");
		let topHierarchyRecord = hierarchy.find(
			(row) =>
				row.descendantStandardObjectUuid === top.uuid &&
				row.descendantStandardObjectVersionUuid === top.versionUuid
		);

		//Check if there is already a child, if so remove it
		let children = data.filter((row) => row.parentUuid === fieldToAttachTo.current.uuid);
		if (children.length > 0) update.deletedRows = children;

		let copy = createAssociatedObjectCopy(object, fieldToAttachTo.current, nextRef);

		let hierarchyRecord = createObjectHierarchyRecord(topHierarchyRecord, object);
		hierarchyRecord.hierarchyTypeUuid = sharedState.dbConstants.associatedObjectType.referenceUuid;
		//Update the path to include the linking character
		hierarchyRecord.pathEnum = topHierarchyRecord.pathEnum + ">" + object.objectHierarchy[0].pathEnum;

		if (row.uuid !== object.uuid) copy.linkToAttributeUuid = row.uuid;

		//Update the value with the reference of the row selected
		fieldToAttachTo.current.value = row.reference;

		update.objectRows = [copy, fieldToAttachTo.current];
		update.hierarchyRecords = [hierarchyRecord];

		mfiUpdates(update);
		dataUpdates(update);
		setShowLinkToObjectMfiDialog(false);
	};

	/**
	 * When a row is updated we check if it is in the curernt object or a sub-object and check if we are updating multiple attributes vs one
	 * @param uuid
	 * @param attribute
	 * @param value
	 */
	const updateRow = (uuid, attribute, value) => {
		let row = data.find((row) => row.uuid === uuid);
		let update = {};

		//Updating multiple attributes
		if (attribute === "multi") Object.keys(value).forEach((key) => (row[key] = value[key]));
		//Updating one attribute
		else row[attribute] = value;

		update.objectRows = [row];

		//If the top object in our data is not the same as the contextTop we need to pass it in as the subObjectToUpdate
		if (data[0].uuid !== sharedState.contextTop.uuid && data[0].parentUuid !== sharedState.contextTop.uuid)
			update.subObjectToUpdate = data[0];

		dataUpdates(update);
	};

	return (
		<>
			{!subObjectWindow ? (
				<button className={"btn-close float-end"} onClick={() => breadcrumbClicked(undefined, windowUuid)} />
			) : (
				""
			)}

			<Breadcrumbs
				breadcrumbs={breadcrumbs.current}
				breadcrumbClicked={(uuid) => breadcrumbClicked(uuid, windowUuid)}
			/>
			<div
				key={windowUuid}
				className="card flex-fill"
				style={{
					height: "calc(100% - 25px)",
					overflowY: "auto",
				}}
			>
				<div className="card-body d-flex flex-wrap flex-row" style={{ overflowY: "auto", height: "100%" }}>
					{fields.map((field) => (
						// <div></div>
						<SetupField
							key={field.uuid}
							data={data}
							top={top}
							field={field}
							updateRow={updateRow}
							setShowObjectSelectDialog={setShowObjectSelectDialog}
							setShowRelatableObjectDialog={setShowRelatableObjectDialog}
							setShowLinkToObjectMfiDialog={setShowLinkToObjectMfiDialog}
							objectClicked={objectClicked}
							setupSheet={setupSheet}
							updateRelatableObjects={(objs) => (relatableObjects.current = objs)}
							updateFieldToAttachTo={updateFieldToAttachTo}
							updateAttachableTypeMfi={(list) => (attachableTypeMfi.current = list)}
							mfiUpdates={mfiUpdates}
							dataUpdates={dataUpdates}
							hierarchy={hierarchy}
						/>
					))}
				</div>

				<SelectionDialog
					dialogTitle={"Select the object you wish to add"}
					treeData={
						attachableTypeMfi.current.length > 0 ? attachableTypeMfi.current : approvedObjectMfi.current
					}
					treeTitle={
						attachableTypeMfi.current.length > 0 ? "Attachable Types" : approvedObjectMfi.current[0]?.title
					}
					rowSelected={(selected) => objectToCopySelected(selected, fieldToAttachTo.current.uuid)}
					open={showObjectSelectDialog}
				/>

				{/*Relatable Object Dialog*/}
				<SelectionDialog
					dialogTitle={"Select the object(s) you wish to attach"}
					treeData={relatableObjects.current}
					treeTitle={"Relatable Objects"}
					rowSelected={relatableObjectsSelected}
					open={showRelatableObjectDialog}
					handleClose={() => setShowRelatableObjectDialog(false)}
					selectedRows={selectedRelatableObjects.current}
					multiSelect={true}
				/>

				{/*Link to Object Master File Index Dialog*/}
				<SelectionDialog
					dialogTitle={"Select the object or attribute you wish to link to"}
					treeData={topMfi.current}
					treeTitle={"Object Master File Index"}
					rowSelected={linkToObjectMfiSelected}
					open={showLinkToObjectMfiDialog}
					handleClose={() => setShowLinkToObjectMfiDialog(false)}
					selectedRows={selectedLinkToObjectMfiRow.current}
					allowSubObjectNavigation={true}
					requireFullObject={false}
				/>

				{/*Overwrite Object Prompt Dialog*/}
				{/*<DraggablePrompt*/}
				{/*	showPrompt={showPrompt}*/}
				{/*	handleNo={() => setShowPrompt(false)}*/}
				{/*	handleYes={handleYes}*/}
				{/*	header={"Overwrite Object"}*/}
				{/*	message={*/}
				{/*		<div style={{ textAlign: "center", marginTop: "20px" }}>*/}
				{/*			<p>*/}
				{/*				This will delete{" "}*/}
				{/*				<span*/}
				{/*					style={{ fontWeight: "600" }}*/}
				{/*				>{`${objectReplacement.current.currentObject?.reference} - ${objectReplacement.current.currentObject?.title}`}</span>*/}
				{/*			</p>*/}
				{/*			<p>*/}
				{/*				and replace it with{" "}*/}
				{/*				<span*/}
				{/*					style={{ fontWeight: "600" }}*/}
				{/*				>{`${objectReplacement.current.currentObject?.reference} - ${objectReplacement.current.newObject?.title}`}</span>*/}
				{/*				.*/}
				{/*			</p>*/}
				{/*			<h3>Apply Changes?</h3>*/}
				{/*		</div>*/}
				{/*	}*/}
				{/*/>*/}
			</div>
		</>
	);
};

/**
 * Renders a series of breadcrumbs
 * @param breadcrumbs = [ 	{ 		key: 1, 		link: false, 		text: 'Test 1' 	}, 	{ 		key: 2, 		link: false, 		text: 'Test 2' 	}, 	{ 		key: 3, 		link: false, 		text: 'Test 3' 	} ],
 * @constructor
 */
export const Breadcrumbs = ({
	breadcrumbs = [
		{ key: 1, link: false, text: "Test 1" },
		{ key: 2, link: false, text: "Test 2" },
		{ key: 3, link: false, text: "Test 3" },
	],
	breadcrumbClicked,
}) => {
	return (
		<ol className="breadcrumb">
			{breadcrumbs.map((breadcrumb) => (
				<li key={breadcrumb.key} className={`breadcrumb-item`}>
					<button
						style={{
							textDecoration: "none",
						}}
						className="btn btn-link breadcrumb"
						onClick={() => breadcrumbClicked(breadcrumb.key)}
					>
						<span>{breadcrumb.text}</span>
					</button>
				</li>
			))}
		</ol>
	);
};
