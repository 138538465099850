import { useEffect, useState } from "react";
import stylesModule from "./Notifications.module.scss";
import { getUserNotifications } from "../../utils/ApiUtils";
import { useTrackedState } from "../../utils/store";

// Interval: The time in seconds to check for network connectivity
const Notifications = ({ interval = 10 }) => {
	const [notifications, setNotifications] = useState([]);
	const [unreadNotifications, setUnreadNotifications] = useState(0);

	const sharedState = useTrackedState();

	const getNotifications = async () => {
		let res = await getUserNotifications(sharedState.currentUser.uuid);
		setNotifications(res);
		setUnreadNotifications(res.filter((row) => !row.read).length);
	};

	useEffect(() => {
		const checkInterval = setInterval(() => {
			getNotifications();
		}, interval * 1000);

		return () => clearInterval(checkInterval);
	}, []);

	return (
		<div
			className={`${stylesModule["Online-color"]} ${stylesModule.onlineIndicator}`}
			title={`${unreadNotifications} unread notifications`}
		>
			{unreadNotifications > 0 ? <sup>⬤</sup> : ""}
		</div>
	);
};

export default Notifications;
