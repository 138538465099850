import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CardContent } from "@mui/material";
import { getParentRef, sortByReference } from "../../../utils/Referencing";
import Reference from "../../Reference/Reference";
import Card from "@mui/material/Card";
import ReactDiffViewer from "react-diff-viewer-continued";
import { VariableSizeList as List } from "react-window";
import {
	ObjectHeader,
	updateChangeData,
} from "../../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import stylesModule from "./Comparison.module.scss";
import { getObjectHierarchy, getObjectMfi, getSingleLevelObjectMfi } from "../../../utils/ApiUtils";
import {
	ASSOCIATED_OBJECT_GENERAL_TYPE_UUID,
	copyStandardObject,
	createObjectHierarchyRecord,
	findClosestPrimusAncestorByMap,
	getLinkToKey,
	getObjectDescendantIdAndVersionUuid,
	getObjectIdAndVersionUuid,
	getPrimusObjectMap,
	getReferenceMap,
	getSourceKey,
	splitObjectIdAndVersion,
} from "../../../utils/StandardObject";

/**
 * This is designed to work with a comparison between 2 mfis. This contains the diff information between one item on the mfis
 * @param {}
 * @constructor
 */
export const ComparisonCard = ({ diff, updateMethod, index, setCardHeight, style, ...other }) => {
	let [showDetails, setShowDetails] = useState(false);

	const cardRef = useRef({});

	useEffect(() => {
		if (cardRef.current) {
			setCardHeight(index, cardRef.current.scrollHeight);
		}
	}, [cardRef]);

	let changeType = diff?.changeType || diff?.changed.changeType;

	//Other Methods

	//If a diff item has more than one sub field, it gives the user the ability to
	//Apply just one sub filed, or the entire item
	//If there is only one sub field, the top button will be singular
	// let individualButtons = false;
	// if (diff.item1) {
	// 	individualButtons = Object.values(diff.item1).length > 1;
	// }

	let showDetailStyles = showDetails ? { zIndex: showDetails ? 10 : "auto", background: "grey" } : {};
	let changeTypeStyles =
		changeType === "Added"
			? //Green
			  { background: "#02b502ad" }
			: changeType === "Deleted"
			? //Red
			  { background: "#ff57579e" }
			: //Yellow
			  { background: "rgb(255 255 2 / 45%)" };

	return (
		<Grid className="full-width" style={{ ...style, ...showDetailStyles }} {...other} ref={cardRef}>
			{/*<Grid key={diff.data.uuid + '-comparison'} className="card flex-fill"*/}
			{/*     style={{*/}
			{/*         // width: '350px',*/}
			{/*         // width: 'calc(50% - 20px)',*/}
			{/*         margin: '5px',*/}
			{/*     }}*/}
			{/*>*/}
			<Card variant="outlined" style={{ padding: "5px" }}>
				{/*<CardContent>*/}
				{/*Value Field*/}
				<Grid container>
					<Grid item container xs={6}>
						<Reference reference={diff?.changed?.reference || diff?.reference} tree />
						<label className="form-label" style={{ fontWeight: "600", margin: "auto 0px" }}>{`${
							diff?.changed?.title || diff?.title
						}`}</label>
						<label
							className="form-label"
							style={{ fontWeight: "600", margin: "auto 0px", ...changeTypeStyles }}
						>
							{" "}
							- {diff?.changed?.changeType || diff?.changeType}
						</label>
						{/*{updateMethod ? (*/}
						{/*	<button*/}
						{/*		className={"ms-auto btn"}*/}
						{/*		onClick={(e) => updateMethod("*", diff.item1, diff.data)}*/}
						{/*	>*/}
						{/*		{individualButtons ? "Revert All" : "Revert"}*/}
						{/*	</button>*/}
						{/*) : (*/}
						{/*	""*/}
						{/*)}*/}
					</Grid>
					<Grid item xs={6} container>
						{/*<Reference reference={diff?.changed?.reference || diff?.reference} tree />*/}
						{/*<label className="form-label" style={{ fontWeight: "600", margin: "auto 0px" }}>{`${*/}
						{/*	diff?.changed?.title || diff?.title*/}
						{/*}`}</label>*/}
						{/*{updateMethod ? (*/}
						{/*	<button*/}
						{/*		className={"ms-auto btn"}*/}
						{/*		onClick={(e) => updateMethod("*", diff.item2, diff.data)}*/}
						{/*	>*/}
						{/*		{individualButtons ? "Apply All" : "Apply"}*/}
						{/*	</button>*/}
						{/*) : (*/}
						{/*	""*/}
						{/*)}*/}
						<button className={"ml btn btn-light"} onClick={(e) => setShowDetails(!showDetails)}>
							Details
						</button>
					</Grid>
					{showDetails ? (
						diff?.diff ? (
							<>
								{[...filterOutMetadataAttributes(diff.diff.modified).entries()].map((entry) => (
									<ComparisonCardItem
										//Field name
										field={entry[0]}
										//Old Value
										item1={entry[1][0]}
										//New Value
										item2={entry[1][1]}
									/>
								))}
								{[...diff.diff.added.entries()].map((entry) => (
									<ComparisonCardItem field={entry[0]} item1={""} item2={entry[1][0]} />
								))}
								{[...diff.diff.deleted.entries()].map((entry) => (
									<ComparisonCardItem field={entry[0]} item1={entry[1][0]} item2={""} />
								))}
							</>
						) : (
							<ComparisonCardItem
								item1={changeType == "Deleted" ? JSON.stringify(diff) : ""}
								item2={changeType == "Added" ? JSON.stringify(diff) : ""}
							/>
						)
					) : (
						""
					)}
					{/*Title Field*/}
					{
						// diff.item1?.title || diff.item2?.title ?
						//     <ComparisonCardItem
						//         field={'Title'}
						//         item1={diff.item1?.title || ''}
						//         item2={diff.item2?.title || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('title', updateItem1 ? diff.item1?.title : diff.item2?.title, diff.data) : undefined}/>
						//     : ''
					}
					{
						// diff.item1?.description || diff.item2?.description ?
						//     <ComparisonCardItem
						//         field={'Description'}
						//         item1={diff.item1?.description || ''}
						//         item2={diff.item2?.description || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('description', updateItem1 ? diff.item1?.description : diff.item2?.description, diff.data) : undefined}/>
						//     : ''
					}
					{
						// diff.item1?.value || diff.item2?.value ?
						//     <ComparisonCardItem
						//         field={'Value'}
						//         item1={diff.item1?.value || ''}
						//         item2={diff.item2?.value || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('value', updateItem1 ? diff.item1?.value : diff.item2?.value, diff.data) : undefined}/>
						//     : ''
					}
					{
						// diff.item1?.stockNo || diff.item2?.stockNo ?
						//     <ComparisonCardItem
						//         field={'Stock #'}
						//         item1={diff.item1?.stockNo || ''}
						//         item2={diff.item2?.stockNo || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('stockNo', updateItem1 ? diff.item1?.stockNo : diff.item2?.stockNo, diff.data) : undefined}/>
						//     : ''
					}
					{
						// diff.item1?.attachableTypes || diff.item2?.attachableTypes ?
						//     <ComparisonCardItem
						//         field={'Attachable Types'}
						//         item1={diff.item1?.attachableTypes || ''}
						//         item2={diff.item2?.attachableTypes || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('attachableTypes', updateItem1 ? diff.item1?.attachableTypes : diff.item2?.attachableTypes, diff.data) : undefined}/>
						//     : ''
					}
					{
						// diff.item1?.objectType || diff.item2?.objectType ?
						//     <ComparisonCardItem
						//         field={'Type'}
						//         item1={diff.item1?.objectType?.title || ''}
						//         item2={diff.item2?.objectType?.title || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('objectType', updateItem1 ? diff.item1?.objectType : diff.item2?.objectType, diff.data) : undefined}/>
						//     : ''
					}
					{
						// diff.item1?.tags || diff.item2?.tags ?
						//     <ComparisonCardItem
						//         field={'Tags'}
						//         item1={diff.item1?.tags || ''}
						//         item2={diff.item2?.tags || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('tags', updateItem1 ? diff.item1?.tags : diff.item2?.tags, diff.data) : undefined}/>
						//     : ''
					}
					{
						// diff.item1?.setup || diff.item2?.setup ?
						//     <ComparisonCardItem
						//         field={'Setup'}
						//         item1={diff.item1?.setup || ''}
						//         item2={diff.item2?.setup || ''}
						//         updateMethod={individualButtons ? (e, updateItem1) => updateMethod('setup', updateItem1 ? diff.item1?.setup : diff.item2?.setup, diff.data) : undefined}/>
						//     : ''
					}
				</Grid>
				{/*</CardContent>*/}
			</Card>
		</Grid>
	);
};

/**
 * Shows the difference between the individual fields on an object
 * @param {field, item1, item2}
 * @constructor
 */
const ComparisonCardItem = ({ field, item1, item2, updateMethod, ...other }) => {
	//Other Methods
	let includeSuggestedTitleInDiff = item2 !== "REMOVED";
	let includeCurrentTitleInDiff = item1 !== "ADDED";

	return (
		<Grid container {...other}>
			<Grid item container xs={6}>
				<label className="form-label" style={{ paddingLeft: "3px" }}>
					{field}:{" "}
				</label>
				{updateMethod ? (
					<button className={"ms-auto btn btn-light"} onClick={(e) => updateMethod(e, false)} href="#">
						Revert
					</button>
				) : (
					""
				)}
			</Grid>
			<Grid item container xs={6} style={{ borderRight: "1px solid #ddd" }}>
				<label className="form-label" style={{ paddingLeft: "3px" }}>
					{field}:{" "}
				</label>
				{updateMethod ? (
					<button className={"ms-auto btn btn-outline-dark"} onClick={(e) => updateMethod(e, true)} href="#">
						Apply
					</button>
				) : (
					""
				)}
			</Grid>
			<ReactDiffViewer
				oldValue={
					includeCurrentTitleInDiff
						? Array.isArray(item1)
							? item1.join(",\n")
							: typeof item1 !== "string"
							? JSON.stringify(item1)
							: item1
						: ""
				}
				newValue={
					includeSuggestedTitleInDiff
						? Array.isArray(item2)
							? item2.join(",\n")
							: typeof item2 !== "string"
							? JSON.stringify(item2)
							: item2
						: ""
				}
				splitView={true}
			/>
		</Grid>
	);
};

/**
 * Takes in 2 master file indexes and compares them
 * @param { original: _original, suggestedChange: _suggestedChange, ...other }
 * @constructor
 */
const Comparison = ({
	original: _original,
	// suggestedChange: _suggestedChange,
	newVersion: _newVersion,
	updateMethod,
	showMfis = true,
	update: newVersionDiff,
	...other
}) => {
	//state variables
	const [originalMfi, setOriginalMfi] = useState([]);
	// const [suggestedChangeMfi, setSuggestedChangeMfi] = useState([]);
	// const [submittingSuggestedChangeMfi, setSubmittingSuggestedChangeMfi] = useState([]);
	const [newVersionMfi, setNewVersionMfi] = useState([]);
	const [mfiDiff, setMfiDiff] = useState();
	const cardHeights = useRef({});
	const [height, setHeight] = useState(0);
	const listRef = useRef({});
	// const [mfiDiff, setMfiDiff] = useState({});

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		setHeight(document.getElementsByClassName(stylesModule.comparisonPanel)[0].clientHeight);
	}, []);

	useEffect(() => {
		function updateSize() {
			setHeight(document.getElementsByClassName(stylesModule.comparisonPanel)[0].clientHeight);
		}
		window.addEventListener("resize", updateSize);
		// updateSize();
		return () => window.removeEventListener("resize", updateSize);
	});

	useEffect(() => {
		if (!_original && !_newVersion) return;

		let tempOriginal = _original.sort ? _original.sort(sortByReference) : _original;
		setOriginalMfi(changeToComparisonMfi(tempOriginal));

		setNewVersionMfi(changeToComparisonMfi(_newVersion));

		if (originalMfi && newVersionMfi && !newVersionDiff) {
			let mfiDiffTemp = compareMfis(originalMfi, newVersionMfi);
			setMfiDiff(mfiDiffTemp);
		}
	}, [originalMfi, _original, _newVersion]);

	if (newVersionMfi && !newVersionDiff) {
		let newVersionComparison = compareMfis(originalMfi, newVersionMfi);
		newVersionDiff = newVersionComparison;
	}

	//Other Methods
	/**
	 * Strips down the mfi to only the objects that need to be compared
	 * @param mfi
	 */
	const changeToComparisonMfi = (mfi) => {
		return mfi;
	};

	// const compareObject = (obj1, obj2) => {
	//
	// }

	{
		/**
      *TODO When we have a comparison for an update we don't care about "deleted" rows
     *Somehow we need to just show the updated items, not all differences
     * For example: If someone upgrades from 1.0 to 1.1 but doesn't accept everything
     * then later upgrades from 1.1 to 1.2, they shouldn't need to worry about the differences
     * between their original object and version 1.1, they should only need to see the updates that were
     * made to 1.1 to get to 1.2

    */
	}
	let compareSpacing = 6;
	let diffToDisplay = [];

	if (newVersionDiff && newVersionDiff.objectChanges) {
		Object.keys(newVersionDiff.objectChanges).forEach((key) => {
			diffToDisplay.push(
				...newVersionDiff?.objectChanges?.[key].added,
				...newVersionDiff?.objectChanges?.[key].deleted,
				...newVersionDiff?.objectChanges?.[key].modified
			);
		});
	}

	const setCardHeight = (index, size) => {
		listRef.current.resetAfterIndex(0);
		cardHeights.current = { ...cardHeights.current, [index]: size };
	};

	const getCardHeight = (index) => {
		return cardHeights.current[index] || 50;
	};

	function Card({ data, index, style }) {
		return (
			<ComparisonCard
				diff={data[index]}
				changeType={data.changeType}
				updateMethod={updateMethod}
				setCardHeight={setCardHeight}
				index={index}
				key={index + "-comparison-card"}
				id={index + "-comparison-card"}
				style={style}
			/>
		);
	}
	let obj1 = originalMfi?.[0];
	let obj3 = newVersionMfi?.[0];

	return (
		<div className={stylesModule.comparisonPanel} style={{ height: "calc(100% - 20px)" }}>
			<Grid container item xs={12} className={""}>
				<Grid container item xs={compareSpacing} className={""}>
					{/*<Typography>Current Object </Typography>*/}
					{/*<ObjectHeader*/}
					{/*	objectTitle={""}*/}
					{/*	reference={obj1?.mfiReference}*/}
					{/*	stockNumber={obj1?.stockNo}*/}
					{/*	version={{*/}
					{/*		...obj1?.versionControl,*/}
					{/*		computerVersion: obj1?.computerVersion,*/}
					{/*	}}*/}
					{/*/>*/}
					<Typography variant="subtitle2" component="div" style={{ background: "#02b502ad" }}>
						Light red = current object
					</Typography>
					<Typography variant="subtitle2" component="div">
						Darker red = text that was removed
					</Typography>
				</Grid>
				{_newVersion ? (
					<Grid container item xs={compareSpacing} className={""}>
						<Typography>New Version </Typography>
						<ObjectHeader
							objectTitle={""}
							reference={obj3?.mfiReference}
							stockNumber={obj3?.stockNo}
							version={{
								...obj3?.versionControl,
								computerVersion: obj3?.computerVersion,
							}}
						/>
						<Typography variant="subtitle2" component="div">
							Light green = new version
						</Typography>
						<Typography variant="subtitle2" component="div">
							Darker green = text that was added
						</Typography>
					</Grid>
				) : (
					""
				)}
				{
					<List
						className="List"
						height={height - 25}
						itemCount={diffToDisplay?.length}
						itemSize={getCardHeight}
						ref={listRef}
						width={"99%"}
						style={{ minWidth: "800px" }}
						itemData={diffToDisplay}
					>
						{Card}
					</List>
				}
			</Grid>
		</div>
	);
};

export default Comparison;

const UpdateSummaryView = (update) => {
	return (
		<>
			Changes: {Object.keys(update.modified).length}
			Additions: {Object.keys(update.added).length}
			Deletions: {Object.keys(update.deleted).length}
		</>
	);
};

const fieldsToCompare = [
	"uuid",
	"versionUuid",
	"reference",
	"title",
	"description",
	"value",
	"stockNo",
	// 'attachableTypes',
	// 'objectType',
	"tags",
	"setup",
	// 'versionControl',
	"standardObjectUuid",
	"standardObjectVersionUuid",
];

const filterEmptyDiffValues = (diff) => {
	//If item1 is not null and item2 is, add fields with truthy values from item 1
	if (diff.item1 && !diff.item2) {
		let item1 = {};
		fieldsToCompare.forEach((field) => {
			if (diff.item1[field]) item1[field] = diff.item1[field];
		});
	}
	//If item2 is not null and item1 is, add fields with truthy values from item 2
	else if (diff.item2 && !diff.item1) {
		let item2 = {};
		fieldsToCompare.forEach((field) => {
			if (diff.item2[field]) item2[field] = diff.item2[field];
		});
	}
	//If both items are not null check the individual fields for either truthy values and add them
	else if (diff.item1 && diff.item2) {
		let item1 = {};
		let item2 = {};
		fieldsToCompare.forEach((field) => {
			if (diff.item2[field] || diff.item1[field]) {
				item2[field] = diff.item2[field];
				item1[field] = diff.item1[field];
			}
		});
	}
};

//Takes 2 arrays and compares the values. This returns false if they aren't the same and true if they are
export const compareArray = (arr1, arr2) => {
	if (arr1 == undefined || arr2 == undefined) {
		return false;
	}
	if (arr1.length != arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] != arr2[i]) return false;
	}

	return true;
};

export const compareReference = (ref1, ref2) => {
	if ((ref1 == undefined && ref2 != undefined) || (ref1 != undefined && ref2 == undefined)) return false;

	if ((ref1 == "" && ref2 == "0") || (ref1 == "0" && ref2 == "")) return true;

	if (ref1.length > ref2.length) return ref1.endsWith(ref2);

	if (ref1.length < ref2.length) return ref2.endsWith(ref1);

	if (ref1 != ref2) return false;

	return true;
};

export const compareMfiByReferences = (mfi1, mfi2, detailed = false) => {
	let mfiDiff = [];
	let mfi2Index = 0;

	//Create a parent map where we can check / find stuff on a parent level
	//We need 2 maps, but we need to associate the 2 maps together either in the map or when accessing the map
	console.time("Compare Mfi");

	for (let i = 0; i < mfi1.length; i++) {
		let obj1 = mfi1[i],
			obj2 = mfi2[mfi2Index];

		//If we've deleted items at the end in our suggested change add the rest of the items in the array to the diff list
		//We don't have to worry about items deleted throughout the mfi because that is already taken care of
		if (mfi2Index >= mfi2.length) {
			let removedRows = mfi1.slice(i);
			removedRows.forEach((item) => {
				let diff = {
					data: item,
					style: { backgroundColor: "#fbe9eb" },
					prepend: "- ",
					item1: item,
					item2: { title: "REMOVED" },
				};

				// filterEmptyDiffValues(diff);

				mfiDiff.push(diff);
			});
			i = mfi1.length;
			break;
		}

		if (!compareReference(obj1.reference, obj2.reference)) {
			//TODO should be able to optimize this to only check down to the next parent mfi
			//TODO add an index diff map so we know which items to compare for items other than reference
			//Check to see if obj1.reference appears further down in mfi2
			//If it does add all of the rows between the current item and the index of the item that appears and mark them as green
			//Add an equivalent blank row to mfiDiff.mfi1
			//TODO figure out how to start find from certain index
			let matchingReference = mfi2
				.slice(mfi2Index)
				.findIndex((item) => compareReference(item.reference, obj1.reference));
			if (matchingReference > 0) {
				let addedRows = mfi2.slice(mfi2Index, matchingReference);
				addedRows.forEach((item) => {
					let diff = {
						data: item,
						style: { backgroundColor: "#ecfdf0" },
						prepend: "+ ",
						item1Diff: { title: "ADDED" },
						item2: item,
					};

					// filterEmptyDiffValues(diff);

					mfiDiff.push(diff);
				});
				mfi2Index = matchingReference;
			}
			//Else check if obj2.reference appears further down in mfi1
			//If it does add all of the rows between the current item and the index of the item that appears and mark them as red
			//Add an equivalent blank row to mfiDiff.mfi2
			//If there is an equivalent record in object1Mfi matching the current objectmfi2 record
			//we need to decrement the counters so we can handle the matched record on the next loop
			else {
				matchingReference = mfi1.slice(mfi2Index).findIndex((item) => item.reference == obj2.reference);
				if (matchingReference > 0) {
					let removedRows = mfi1.slice(i, matchingReference);
					removedRows.forEach((item) => {
						let diff = {
							data: item,
							//Red
							style: { backgroundColor: "#fbe9eb" },
							prepend: "- ",
							item1: item,
							item2Diff: { title: "REMOVED", reference: " " },
						};

						// filterEmptyDiffValues(diff);

						mfiDiff.push(diff);
					});
					//The we decrement the index so on the next loop through it will compare the 2 records which should now match up
					if (matchingReference > i) i = matchingReference - 1;
					mfi2Index--;
				}
				//If it doesn't appear further down in the list there is a new row added
				//We will need to decrement the mfi1Index so we can check it against the next record
				//This should only happen if the rows were added and deleted right next to each other
				else {
					let diff = {
						data: obj2,
						style: { backgroundColor: "#ecfdf0" },
						prepend: "+ ",
						item1Diff: { title: "ADDED" },
						item2: obj2,
					};
					i--;

					// filterEmptyDiffValues(diff);

					mfiDiff.push(diff);
				}
			}
			//TODO Add a comparison method so this isn't so stupid
		} else if (
			obj1.title !== obj2.title ||
			obj1.value !== obj2.value ||
			obj1.description !== obj2.description ||
			obj1.stockNo !== obj2.stockNo ||
			obj1.objectTypeUuid !== obj2.objectTypeUuid ||
			obj1.tags !== obj2.tags ||
			obj1.attachableTypes !== obj2.attachableTypes ||
			obj1.setup !== obj2.setup ||
			obj1.uuid !== obj2.uuid ||
			obj1.versionUuid !== obj2.versionUuid ||
			obj1.standardObjectUuid !== obj2.standardObjectUuid ||
			obj1.standardObjectVersionUuid !== obj2.standardObjectVersionUuid
		) {
			let diff = {
				data: obj1,
				//Set the style of the modified object to be a yellow
				style: { paddingLeft: "12px", backgroundColor: "#ffffbf" },
			};

			let item1Diff = {};
			let item2Diff = {};

			//Checks each of the fields for equality
			fieldsToCompare.forEach((field) => {
				//If they aren't equal do another check for objectness or arrayness
				if (obj1[field] !== obj2[field]) {
					if (Array.isArray(obj1[field]) || Array.isArray(obj2[field])) {
						if (!compareArray(obj1[field], obj2[field])) {
							item1Diff[field] = obj1[field];
							item2Diff[field] = obj2[field];
						}
					} else if (typeof obj1[field] == "object" || typeof obj2[field] == "object") {
						if (obj1[field]?.title != obj2[field]?.title) {
							item1Diff[field] = obj1[field];
							item2Diff[field] = obj2[field];
						}
					} else {
						item1Diff[field] = obj1[field];
						item2Diff[field] = obj2[field];
					}
				}
			});

			if (Object.values(item1Diff).length > 0 || Object.values(item2Diff).length > 0) {
				diff.item1Diff = item1Diff;
				diff.item2Diff = item2Diff;
				diff.item1 = obj1;
				diff.item2 = obj2;

				//Add the items to the diff array
				mfiDiff.push(diff);
			}
		}
		//Reference (reference),
		// Title (title),
		// MFI Reference (mfiReference),

		/*TODO add a check to see if there is another object where the only difference is the reference, if it is it may have just been re-referenced
		 * This is
		 */

		//If we have gone through the entire original list and there are still rows on the end of the suggested change list we need to mark them as added
		//This means there were rows added to the end
		if (i + 1 == mfi1.length && mfi2Index + 1 != mfi2.length) {
			let addedRows = mfi2.slice(mfi2Index + 1);
			addedRows.forEach((item) => {
				let diff = {
					data: item,
					style: { backgroundColor: "#ecfdf0" },
					prepend: "+ ",
					item1Diff: { title: "ADDED" },
					item2: item,
				};

				// filterEmptyDiffValues(diff);

				mfiDiff.push(diff);
			});
		}

		mfi2Index++;
	}

	console.timeEnd("Compare Mfi");

	return mfiDiff;
};

//Checks to see if the row already has changes if it doesn't set it up, if it does, return it
const getOrInitObj = (obj, row) => {
	const updateTemplate = { added: [], deleted: [], modified: [] };
	if (!obj[row.uuid]) {
		obj[row.uuid] = { ...updateTemplate, obj: row };
		return obj[row.uuid];
	} else {
		return obj[row.uuid];
	}
};

export const compareFullObject = async (obj1, obj2) => {
	if (!obj1.objectHierarchy) {
		obj1.objectHierarchy = await getObjectHierarchy(obj1);
	}
	if (!obj2.objectHierarchy) {
		obj2.objectHierarchy = await getObjectHierarchy(obj2);
	}
	let objUpdate = compareObjectHierarchy(
		obj1.objectHierarchy.filter((row) => row.hierarchyTypeUuid !== ASSOCIATED_OBJECT_GENERAL_TYPE_UUID),
		obj2.objectHierarchy.filter((row) => row.hierarchyTypeUuid !== ASSOCIATED_OBJECT_GENERAL_TYPE_UUID)
	);

	let fullObjChanges = new Map();
	let objMap = new Map();

	//Added objectHierarchies can be added just like adding a new sub-object
	objUpdate.added.forEach((addedObj) => {
		//Each of these added objects should have a matching modified object
		//The ancestor should have it
		//If the ancestor is in the added list, ignore this
	});
	//Deleted objectHierarchies can be deleted like a sub-object
	objUpdate.deleted.forEach((deletedObj) => {
		//Each of these deleted objects should have a matching modified object
		//The ancestor should have it
		//If the ancestor is in the deleted list, ignore this
	});

	let linkingRecordsWithoutLinkedRecord = [];

	//TODO may be able to change this to work asynchronously, but for now we are going to have it be linear
	//Changed objectHierarchies will be individually ran through the compareMfis
	for (let i = 0; i < objUpdate.modified.length; i++) {
		let modifiedObj = objUpdate.modified[i];
		let oldObj = {
			uuid: modifiedObj.original.descendantStandardObjectUuid,
			versionUuid: modifiedObj.original.descendantStandardObjectVersionUuid,
			mfi: obj1[modifiedObj.original.descendantStandardObjectUuid],
		};
		let newObj = {
			uuid: modifiedObj.changed.descendantStandardObjectUuid,
			versionUuid: modifiedObj.changed.descendantStandardObjectVersionUuid,
			mfi: obj2[modifiedObj.changed.descendantStandardObjectUuid],
		};
		let subObjUpdate = await compareTwoVersionMfis(oldObj, newObj);
		objMap.set(modifiedObj.changed.descendantStandardObjectUuid, subObjUpdate.newMfi);

		delete subObjUpdate.newMfi;
		fullObjChanges.set(oldObj.uuid, subObjUpdate);

		let linkingRecords = subObjUpdate.linkingRecords;
		if (subObjUpdate.linkingRecords.length > 0) {
			subObjUpdate.linkedRecords = [];
			linkingRecords.forEach((linkingRecord) => {
				let linkToUuid = linkingRecord.linkToObjectUuid;
				if (objMap.has(linkToUuid)) {
					let linkToAttribute = objMap
						.get(linkToUuid)
						.find((row) => row.uuid === linkingRecord.linkToAttributeUuid);
					if (linkToAttribute) subObjUpdate.linkedRecords.push(linkToAttribute);
					else
						console.warn(
							"There was no attribute in the given object. This means the link was already broken. Broken link: ",
							linkingRecord
						);
				} else {
					linkingRecord.forObject = oldObj.uuid;
					linkingRecordsWithoutLinkedRecord.push(linkingRecord);
				}
			});
		}
	}

	//Iterate over linking records without the linked record
	linkingRecordsWithoutLinkedRecord.forEach((linkingRecord) => {
		let linkToUuid = linkingRecord.linkToObjectUuid;

		//Check to see if their object is in the map
		if (objMap.has(linkToUuid)) {
			let linkToAttribute = objMap.get(linkToUuid).find((row) => row.uuid === linkingRecord.linkToAttributeUuid);
			if (linkToAttribute) fullObjChanges.get(linkingRecord.forObject).linkedRecords.push(linkToAttribute);
			else
				console.warn(
					"There was no attribute in the given object. This means the link was already broken. Broken link: ",
					linkingRecord
				);
		} else {
			console.warn(
				"The linkedObject was a new object and should be taken care of after the save. If it isn't taken care of here's how to fix it (future nate).\n" +
					"Actually I haven't figured it out, so go to Future Linked Object Thoughts for the starting point"
			);
		}
	});

	console.info(
		"Sub-objects:\n\tAdded:" +
			objUpdate.added.length +
			"\tDeleted:" +
			objUpdate.deleted.length +
			"\tModified:" +
			objUpdate.modified.length
	);

	let rowAdditions = 0;
	let rowDeletions = 0;
	let rowModifications = 0;

	[...fullObjChanges.values()].forEach((upd) => {
		rowAdditions += upd.added.length;
		rowDeletions += upd.deleted.length;
		rowModifications += upd.modified.length;
	});

	console.info(
		"Sub-object Rows:\n\tAdded:" + rowAdditions + "\tDeleted:" + rowDeletions + "\tModified:" + rowModifications
	);

	return { added: objUpdate.added, deleted: objUpdate.deleted, modified: fullObjChanges };
};

//The hierarchies passed in should only contain sub-object hierarchies
export const compareObjectHierarchy = (hierarchy1, hierarchy2) => {
	const added = [];
	const deleted = [];
	const modified = [];

	hierarchy1.forEach((objRecord) => {
		let matchingObjs = hierarchy2.filter(
			(objRecord2) => objRecord2.descendantStandardObjectUuid === objRecord.descendantStandardObjectUuid
		);
		if (matchingObjs.length == 0) {
			deleted.push(objRecord);
		} else if (matchingObjs.length > 1) {
			console.warn("There shouldn't be multiple sub-objects");
		} else {
			let matchingObj = matchingObjs[0];
			if (objRecord.descendantStandardObjectVersionUuid !== matchingObj.descendantStandardObjectVersionUuid) {
				modified.push({
					diff: {
						versionUuid: [
							objRecord.descendantStandardObjectVersionUuid,
							matchingObj.descendantStandardObjectVersionUuid,
						],
					},
					original: objRecord,
					changed: matchingObj,
				});
			}
		}
	});

	hierarchy2.forEach((objRecord) => {
		let matchingObjs = hierarchy1.filter(
			(objRecord2) => objRecord2.descendantStandardObjectUuid === objRecord.descendantStandardObjectUuid
		);
		if (matchingObjs.length == 0) {
			added.push(objRecord);
		}
	});

	return { added, deleted, modified };
};

export const compareMfis = (mfi1, mfi2, detailed = false) => {
	const added = [];
	const deleted = [];
	const modified = [];
	const linkingRecords = [];

	let useSource = false;

	//Determine the type of comparison to use
	if (mfi1[0] && mfi2[0]) {
		if (mfi1[0].uuid === mfi2[0].uuid) useSource = false;
		else if (mfi1[0].uuid === mfi2[0].standardObjectUuid) useSource = true;
		else
			console.warn(
				"These mfis don't match up with each other, either the mfis needs sorted, or a different comparison method needs used"
			);
	}

	// Check for deleted and modified objects
	mfi1.forEach((obj1) => {
		const obj2 = mfi2.find((obj) => (useSource ? obj.standardObjectUuid === obj1.uuid : obj.uuid === obj1.uuid));
		//Checks for deleted objects
		if (!obj2) {
			obj1.changeType = "Deleted";
			deleted.push(obj1);
		} else {
			//Check for changed objects
			let rowDiff = compareMfiRow(obj1, obj2);
			if (rowDiff && (rowDiff.added.size > 0 || rowDiff.deleted.size > 0 || rowDiff.modified.size > 0)) {
				modified.push({
					diff: rowDiff,
					original: obj1,
					changed: obj2,
					changeType: "Modified",
				});

				if (rowDiff["linkToAttribute"]) linkingRecords.push(obj2);
			}
		}
	});

	// Check for added objects
	mfi2.forEach((obj2) => {
		const obj1 = mfi1.find((obj) => (useSource ? obj.uuid === obj2.standardObjectUuid : obj.uuid === obj2.uuid));
		if (!obj1) {
			obj2.changeType = "Added";
			added.push(obj2);

			if (obj2.linkToObjectUuid) {
				linkingRecords.push(obj2);
			}
		}
	});

	//Create the primus object map
	// For each of the added, deleted and modified find the closestPrimusAncestor and stick it there
	// We are going to have 2 separate maps, one for the first mfi and one for the second which one do we check?
	// Adding check map2, deleting check map1, updating check map2
	// When adding if we find a new sub-object nothing underneath needs to be checked
	// When deleting if we find a sub-object nothing needs to be deleted
	// If modified, those changes need to be added under their sub-object

	return { added, deleted, modified, linkingRecords, newMfi: mfi2 };
};

export const compareMfiRow = (row1, row2) => {
	const added = new Map();
	const deleted = new Map();
	const modified = new Map();

	// Recursive function for deep comparison
	const compareValues = (value1, value2, keyPath) => {
		if (Array.isArray(value1) && Array.isArray(value2)) {
			if (value1.length !== value2.length || !value1.every((val, index) => val === value2[index])) {
				modified.set(keyPath, [value1, value2]);
			}
		} else if (typeof value1 === "object" && typeof value2 === "object") {
			const keys = new Set([...Object.keys(value1), ...Object.keys(value2)]);
			keys.forEach((key) => {
				const nestedPath = keyPath ? `${keyPath}.${key}` : key;
				if (!value1.hasOwnProperty(key)) {
					added.set(nestedPath, value2[key]);
				} else if (!value2.hasOwnProperty(key)) {
					deleted.set(nestedPath, value1[key]);
				} else {
					compareValues(value1[key], value2[key], nestedPath);
				}
			});
		} else if (value1 !== value2) {
			modified.set(keyPath, [value1, value2]);
		}
	};

	compareValues(row1, row2, "");

	// // Check for added properties
	// Object.keys(row2).forEach((key) => {
	// 	if (!row1.hasOwnProperty(key)) {
	// 		added[key] = row2[key];
	// 	} else if (row1[key] !== row2[key]) {
	// 		modified[key] = row2[key];
	// 	}
	// });
	//
	// // Check for deleted properties
	// Object.keys(row1).forEach((key) => {
	// 	if (!row2.hasOwnProperty(key)) {
	// 		deleted[key] = row1[key];
	// 	}
	// });

	return { added, deleted, modified };
};

export const compareTwoVersionMfis = async (oldVersion, newVersion, dispatch) => {
	if (!oldVersion.mfi && oldVersion.uuid && oldVersion.versionUuid) {
		oldVersion.mfi = await getSingleLevelObjectMfi(oldVersion.uuid, oldVersion.versionUuid, dispatch);
	}
	if (!newVersion.mfi && newVersion.uuid && newVersion.versionUuid) {
		newVersion.mfi = await getSingleLevelObjectMfi(newVersion.uuid, newVersion.versionUuid, dispatch);
	}
	return compareMfis(oldVersion.mfi, newVersion.mfi, true);
};

//This will take in two diffs, it needs to merge the 2 parts of each diff and print out each one
export const compareTwoDiffs = (oldDiff, newDiff) => {};

//This will take in a mfi and compare it to the diff
export const compareMfiToDiff = (mfi, diff) => {};

//This will take in an update in the form of a diff and apply it to an existing mfi
export const applyUpdateToMfi = (topObject, fullObject, update) => {
	//These methods should all do the same thing we do in the store
	//This should probably only show the changes for the open objects allowing for the descent into the object
	// with the object showing a summary of the additions, deletions, and modifications
	//Get a copy of the mfi (This will need to include copies of the objects, but we will try without first)
	//Changes this to support sub-objects instead of the full mfi in a list format
	let mfi = fullObject.subObjects;
	let newMfi = new Map();
	let objectChanges = new Map();
	let objectHierarchy = fullObject.objectHierarchy;

	//Iterate over each of the sub-objects
	objectHierarchy
		.filter((record) => record.hierarchyTypeUuid !== ASSOCIATED_OBJECT_GENERAL_TYPE_UUID)
		.forEach((subObject) => {
			let subObjKey = getObjectDescendantIdAndVersionUuid(subObject);

			let subObj = mfi[subObjKey][0];
			newMfi.set(subObjKey, [...mfi[subObjKey]]);
			//Check for a matching update
			//	Use the first row's standardObjectUuid
			let subObjUpdate = update.modified?.get(subObj.standardObjectUuid);
			let newMfiRefMap = getReferenceMap(newMfi.get(subObjKey));

			if (subObjUpdate) {
				let changedRows = [];
				let deletedRows = [];
				//This is used for the change summary title.
				// This merges the update and the object that is being updated
				subObjUpdate.itemChanging = subObj;

				let deleted = applyDeletionsToMfi(newMfi.get(subObjKey), subObjUpdate.deleted);
				newMfi.set(subObjKey, deleted.mfi);
				deletedRows = deleted.deletedRows;
				changedRows.push(...applyChangesToMfi(newMfi.get(subObjKey), subObjUpdate.modified, subObj));
				newMfiRefMap = getReferenceMap(newMfi.get(subObjKey));
				let additions = applyAdditionsToMfi(
					newMfi.get(subObjKey),
					subObjUpdate.added,
					newMfiRefMap,
					update.added
				);
				changedRows.push(...additions.listOfAddedRows);

				let ancestorRecord = objectHierarchy.find(
					(objH) =>
						getObjectDescendantIdAndVersionUuid(objH) === subObjKey &&
						objH.hierarchyTypeUuid !== ASSOCIATED_OBJECT_GENERAL_TYPE_UUID
				);

				let newHierarchyRecords = [];
				additions.listOfAddedObjects.forEach((addedObj) => {
					newHierarchyRecords.push(createObjectHierarchyRecord(ancestorRecord, addedObj));
				});

				//The association records are any records pointing to something in the object's mfi
				//Iterate over each association record and update the linkToObjectUuid and VersionUuid to point to the correct object
				additions.listOfAssociationRecords.forEach((associationRecord) => {
					//Find the object associated with the linked attribute (Most accurate)
					let associatedObject =
						topObject.standardObjectUuid === associationRecord.linkToObjectUuid ? topObject : undefined;

					//If the accurate one didn't find an object, do a more general check (Less accurate)
					if (!associatedObject) {
						let associatedKey = [...newMfi.keys()].find(
							(key) => newMfi.get(key)[0].standardObjectUuid === associationRecord.linkToObjectUuid
						);
						associatedObject = newMfi.get(associatedKey)[0];
					}

					//If we found the associated object, we need to get the uuid and version of the object and find the associated attribute in that object
					if (associatedObject) {
						let associatedKey = getObjectIdAndVersionUuid(associatedObject);

						associationRecord.linkToObjectUuid = associatedObject.uuid;
						associationRecord.linkToObjectVersionUuid = associatedObject.versionUuid;
						let linkedAttribute = subObjUpdate.linkedRecords.find(
							(linkedRecord) => linkedRecord.uuid === associationRecord.linkToAttributeUuid
						);

						//Find the rowIndex
						let rowIndex = findMatchingRowIndex(newMfi.get(associatedKey), linkedAttribute);
						let newLinkedToAttribute = newMfi.get(associatedKey)[rowIndex];
						associationRecord.linkToAttributeUuid = newLinkedToAttribute.uuid;
					} else {
						console.warn(
							"There was no object matching the associated object. The association link wasn't changed",
							associationRecord
						);
					}
				});

				objectChanges.set(subObj.uuid, {
					obj: subObj,
					changedRows,
					deletedRows,
					objectHierarchy: newHierarchyRecords.length > 0 ? newHierarchyRecords : undefined,
				});
			}
			//Iterate over each item in the update and apply them to the sub-object mfi
			//Store the changes by the topObject
		});

	update.added.forEach((addedObj) => {
		// Applying them should be just adding the top row for the object to the changedRows
		// Same with adding the new rows, just add the top row. Check the handleDrop
		// Sections that apply NewModifiedWorkspacePanel.js 1223, Tree.js 715
		// You need to create a new hierarchyRecord for each of the new hierarchy objects
		// Make sure the ancestor is correct
		// The new uuids also need to match up (descendant row and new object uuid)
		//The individual rows should already be in their ancestor object
	});

	//This might be taken care of above as any deleted rows are already in their ancestor objects
	// update.deleted.forEach(deletedObj => {
	// 	// For deleting rows see NewModifiedWorkspacePanel.js 1211, Tree.js 1137
	// 	// You need to just delete the row for the object
	// 	updateChangeData(dispatch, {
	// 		objectToUpdate
	// 	});
	// });

	return { mfi: newMfi, objectChanges, standardObject: topObject };
};

const applyAdditionsToMfi = (mfi, added, refMap, addedObjects) => {
	let listOfAddedRows = [];
	let listOfAddedObjects = [];
	let listOfAssociationRecords = [];
	//Added rows, this should copy each of the rows stopping at the top of a sub-object
	added.forEach((addition) => {
		let parentRef = getParentRef(addition.reference);
		let addedKey = getObjectIdAndVersionUuid(addition);
		//How would we find the parentUuid for the new row?
		let newParent = refMap.get(parentRef);
		if (newParent) {
			//How should we check if the row is a new object?
			let isObject = addedObjects.find((obj) => getObjectDescendantIdAndVersionUuid(obj) === addedKey);
			let newCopy = isObject
				? copyStandardObject(addition, addition.reference, newParent.uuid, null, true, true, false, true)
				: copyStandardObject(addition, addition.reference, newParent.uuid);

			if (addition.linkToObjectUuid !== undefined && newParent.linkToSource === "object-master-file-index") {
				listOfAssociationRecords.push(newCopy);
			}

			mfi.push(newCopy);
			listOfAddedRows.push(newCopy);
			if (isObject) {
				listOfAddedObjects.push(newCopy);
			}
			refMap.set(addition.reference, newCopy);
		}
	});
	return { listOfAddedRows, listOfAddedObjects, listOfAssociationRecords };
};

const applyDeletionsToMfi = (mfi, deleted) => {
	let listOfDeletedRows = [];
	//Deleted rows, this should add the rows to the deletedRows this should stop at the top of a sub-object
	//We are also comparing the standardObjectUuids because that row isn't updated... Either that's fine or when creating a new object we need to point it to the source row (We should do the second one, but this may work for now)
	let newMfi = mfi.filter((row) => {
		if (
			deleted.filter(
				(dRow) =>
					dRow.uuid === row.standardObjectUuid ||
					(dRow.standardObjectUuid && dRow.standardObjectUuid === row.standardObjectUuid)
			).length === 0
		)
			return true;
		else {
			listOfDeletedRows.push(row);
			return false;
		}
	});

	return { mfi: newMfi, deletedRows: listOfDeletedRows };
	// deleted.forEach(deletion => {
	// 	mfi.
	// });
};

const applyChangesToMfi = (mfi, modified, primus) => {
	let listOfChangedRows = [];
	modified.forEach((modification) => {
		let rowIndex = findMatchingRowIndex(mfi, modification.original);

		if (rowIndex === -1) {
			console.warn(
				"While updating the row that was changed did not exist. Original->Changed",
				modification.original,
				modification.changed
			);
			return;
		}

		//If the row is updated, don't use this again
		//This only applies to multiple rows being updated with the same title
		mfi[rowIndex].updated = true;

		//Copy the object and apply the additions
		//This won't work because the diffs need to be changed to match the copy
		let updatedRow = { ...mfi[rowIndex] };

		//If this is pointing to the modified row (This needs to be fixed, because very shortly this won't determine we are looking at the top
		// Somehow we need to more reliably detect the top
		if (updatedRow.uuid === primus.uuid) {
			mergeTopChanges(updatedRow, modification);
		} else {
			//If just the version is changing we want to update the sourceVersion
			addAttributes(updatedRow, filterOutMetadataAttributes(modification.diff.added));
			updateAttributes(
				updatedRow,
				filterOutMetadataAttributes(modification.diff.modified),
				modification.original
			);

			//Remove any of the attributes that were deleted, this equates to any attributes where the value was removed
			removeAttributes(updatedRow, filterOutMetadataAttributes(modification.diff.deleted));
		}

		//I don't think we need these
		// let primusChanged = Object.values(refMap).find((row) => row.uuid === updatedRow.uuid);
		//
		// if (primusChanged) {
		// 	refMap[primusChanged.reference] = updatedRow;
		// }

		mfi[rowIndex] = updatedRow;
		listOfChangedRows.push(updatedRow);
	});
	return listOfChangedRows;
};

const findMatchingRowIndex = (mfi, originalRow) => {
	let rowIndex = mfi.findIndex((row) => row.standardObjectUuid === originalRow.uuid);

	if (rowIndex === -1) {
		rowIndex = mfi.findIndex(
			(row) =>
				row.standardObjectUuid &&
				row.standardObjectUuid === originalRow.standardObjectUuid &&
				row.title === originalRow.title &&
				!row.updated
		);
	}

	return rowIndex;
};

const mergeTopChanges = (row, modification) => {
	// Changes that should be applied:
	// the updated standardObjectVersionUuid,
	// updating the computer version,
	// updating the referenceObjectTitle,
	// the list of changes to transfer
	row.standardObjectUuid = modification.original.uuid;
	row.standardObjectVersionUuid = modification.diff.modified.get("versionUuid")[1];
	if (modification.diff.modified?.has("title")) row.referenceObjectTitle = modification.diff.modified.get("title")[1];
	row.computerVersion++;

	let changesToTransfer = ["objectTypeVersionUuid", "objectTypeUuid", "title"];
	addAttributes(row, filterAttributes(modification.diff.added, changesToTransfer));
	updateAttributes(row, filterAttributes(modification.diff.modified, changesToTransfer));
	removeAttributes(row, filterAttributes(modification.diff.deleted, changesToTransfer));
};

//Removes any keys that were deleted in the change
function removeAttributes(sourceObj, attributesToRemoveObj) {
	[...attributesToRemoveObj.keys()].forEach((attr) => {
		if (sourceObj.hasOwnProperty(attr)) {
			delete sourceObj[attr];
		}
	});
}

//Adds any new attributes from the change
function addAttributes(sourceObj, attributesToAdd) {
	[...attributesToAdd.keys()].forEach((attr) => {
		sourceObj[attr] = attributesToAdd.get(attr);
	});
}

//Updates the values to the updated version
function updateAttributes(sourceObj, attributesToUpdate, original) {
	if (attributesToUpdate.has("versionUuid")) {
		sourceObj["standardObjectUuid"] = original.uuid;
		sourceObj["standardObjectVersionUuid"] = attributesToUpdate.get("versionUuid")[1];
		attributesToUpdate.delete("versionUuid");
	}

	[...attributesToUpdate.keys()].forEach((attr) => {
		sourceObj[attr] = attributesToUpdate.get(attr)[1];
	});
}

//Filters out any attributes not in the attributesToKeep list
function filterAttributes(attributes, attributesToKeep, attributesToRemove) {
	if (attributesToKeep) {
		let newAttributes = new Map();
		attributesToKeep.forEach((attr) => {
			if (attributes.has(attr)) newAttributes.set(attr, attributes.get(attr));
		});
		return newAttributes;
	}
	if (attributesToRemove) {
		let filteredAttributes = new Map(attributes);
		attributesToRemove.forEach((attr) => {
			filteredAttributes.delete(attr);
		});
		return filteredAttributes;
	}
	return attributes;
}

//Remove any attributes that are computer controlled such as computerVersion, logRecord, versionControl
//Also change the versionUuid to be a standardObjectVersionUuid
const filterOutMetadataAttributes = (attributes) => {
	let attributesToRemove = [
		"computerVersion",
		"logRecord.computerVersion",
		"logRecord.createdAt",
		"logRecord.uuid",
		"versionControl.createdAt",
		"versionControl.standardObjectGitUuid",
		"versionControl.uuid",
	];
	return filterAttributes(attributes, null, attributesToRemove);
};
