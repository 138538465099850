import React, { useState, useEffect, useRef, useCallback } from "react";
import TitleAutoComplete from "../Autocomplete/TitleAutocomplete";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import BootstrapSelect from "../BootstrapComponents/Select/BootstrapSelect";
import { getFilter, getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import {
	componentsNodeTitle,
	linkAttributeName,
	linkVersionAttributeName,
	oldComponentsNodeTitle,
} from "../../utils/StandardObject";
import DraggableDialog from "../Dialog/DraggableDialog/Dialog";
import CheckboxAndFilterList from "../CheckboxAndFilterList/CheckboxAndFilterList";
import { BasicConfig, Builder, Query, Utils as QbUtils } from "@react-awesome-query-builder/ui";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useTrackedState } from "../../utils/store";
import { getReferenceNo } from "../../utils/Referencing";

/**
 * Stock # - 116-511210.228.RCFNCMPT.415101: 0 - React Functional Component
 * Renders the extra fields needed to make the association setup type work, the object type, attribute filter, object filter criteria, and cardinality
 * @param {objectType, filterCriteria, attributeFilter, cardinality}
 * @constructor
 */
const AssociationSetupType = ({
	objectTypeUuid,
	objectTypeVersionUuid,
	objectType: _objectType,
	linkToSource: _linkToSource,
	linkToSourceIsDataWarehouse = false,
	linkToSourceIsObjectMasterFileIndex = false,
	filterUuid,
	cardinality: _cardinality,
	showCardinality = true,
	objectTypes,
	updateRow,
	focus,
}) => {
	//state variables
	//Stock # - **NO STOCK # FOUND**: 04.04.01 - objectType
	const [objectType, setObjectType] = useState({});
	const [linkToSource, setLinkToSource] = useState("");
	//Stock # - **NO STOCK # FOUND**: 04.04.03 - attributeFilter
	const [attributeFilter, setAttributeFilter] = useState([]);
	const [attributes, setAttributes] = useState([]);
	const [checked, setChecked] = useState({});
	//Stock # - **NO STOCK # FOUND**: 04.04.04 - cardinality
	const [cardinality, setCardinality] = useState(0);
	const [showObjectAttributeDialog, setShowObjectAttributeDialog] = useState(false);
	const [showFilter, setShowFilter] = useState(false);

	const filter = useRef({});
	const attributeFilterChanges = useRef({ checkboxChanges: new Map(), criteriaChanges: {} });

	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		setCardinality(_cardinality);
	}, [_cardinality]);

	useEffect(() => {
		setLinkToSource(_linkToSource);
	}, [_linkToSource]);

	useEffect(() => {
		getObjectType(_objectType?.uuid, _objectType?.versionUuid);
	}, [_objectType?.uuid, _objectType?.versionUuid]);

	/**
	 * When I receive a new uuid or versionUuid grab that object and its MFI
	 */
	useEffect(() => {
		if (objectType.uuid !== objectTypeUuid || objectType.versionUuid !== objectTypeVersionUuid)
			getObjectType(objectTypeUuid, objectTypeVersionUuid);
	}, [objectTypeUuid, objectTypeVersionUuid]);

	useEffect(() => {
		if (objectType && objectType.uuid && objectType.versionUuid)
			getObjectType(objectType.uuid, objectType.versionUuid);
	}, [objectType?.uuid, objectType?.versionUuid]);

	/**
	 * When I receive a new filterUuid retrieve the filter from the database and update our filter in memory
	 */
	useEffect(() => {
		//If there's not a filter, create one
		if (!filterUuid) {
			filter.current = createNewFilter();
		} else if (filterUuid !== attributeFilter.uuid) getAttributeFilter(filterUuid);
	}, [filterUuid]);

	const createNewFilter = () => {
		return {
			uuid: uuidv4(),
			filter: {
				attributes: [],
				criteria: {},
			},
		};
	};

	//Other Methods
	const getObjectType = async (objectTypeUuid, objectTypeVersionUuid) => {
		if (!objectTypeUuid || !objectTypeVersionUuid) return;

		let mfi = await getSingleLevelObjectMfi(objectTypeUuid, objectTypeVersionUuid);
		let components = mfi.filter((row) => row.uuid !== objectTypeUuid);
		let componentsNode = components.find(
			(row) =>
				row.parentUuid === objectTypeUuid &&
				(row.title === componentsNodeTitle || row.title === oldComponentsNodeTitle)
		);
		if (componentsNode)
			components = mfi.filter(
				(row) => row.reference.startsWith(componentsNode.reference) && row.uuid !== componentsNode.uuid
			);

		setObjectType(mfi[0]);
		setAttributes(components);
	};

	const getAttributeFilter = async (filterUuid) => {
		//Get the filter
		let retrievedFilter = await getFilter(filterUuid);
		if (!retrievedFilter) filter.current = createNewFilter();
		else {
			//Update the filter we have in memory
			let parsedFilter = JSON.parse(retrievedFilter.filter);
			setAttributeFilter(parsedFilter.criteria);

			//Does the filter tell me which attributes are checked too?
			setChecked(parsedFilter.attributes);

			filter.current = { ...retrievedFilter, filter: parsedFilter };
		}
	};

	/**
	 * Called when there is a change in one of the inputs
	 * @param newValue, attribute
	 */
	const handleObjectTypeChange = (newVal) => {
		if (newVal.uuid && newVal.uuid !== objectType?.uuid) {
			setObjectType(newVal);

			let newFilter = createNewFilter();
			filter.current = newFilter;
			updateAttributeFilter("criteria", newFilter.filter.criteria, {
				[linkAttributeName]: newVal.uuid,
				[linkVersionAttributeName]: newVal.versionUuid,
			});
			setChecked([]);
		} else if (!newVal) {
			setObjectType({});
			updateRow("type", newVal);
			setChecked([]);
		}
	};

	const handleCardinalityChange = (newVal) => {
		updateRow("cardinality", newVal);
	};

	const updateAttributeFilter = (type, newVal, prop) => {
		if (type === "checkbox") {
			let newChecked = { ...checked, [prop]: newVal };
			attributeFilterChanges.current.checkboxChanges.set(prop, newVal);
			setChecked(newChecked);

			filter.current.filter.attributes = newChecked;
		} else if (type === "criteria") {
			attributeFilterChanges.current.criteriaChanges = newVal;
			filter.current.filter.criteria = newVal;
			setAttributeFilter(newVal);
		}

		//On every change we create a new filter rather than overwriting the previous one in case other objects are using it
		if (filterUuid) filter.current.uuid = uuidv4();

		let update = {
			filterUuid: filter.current.uuid,
			filter: newVal,
		};

		if (type === "criteria" && prop) update = { ...update, ...prop };
		//Update the row to point to the new filter
		updateRow("multi", update);

		dispatch({
			type: "UPDATE_CHANGED_FILTERS",
			data: { ...filter.current, filter: JSON.stringify(filter.current.filter) },
		});
	};

	const filterClicked = (e, attribute) => {
		setShowFilter(true);
	};

	const onFilterChange = (json) => {
		updateAttributeFilter("criteria", json);
		setShowFilter(false);
	};

	return (
		<div style={{}}>
			{/*Allowed Link Sources*/}
			{!linkToSourceIsDataWarehouse && !linkToSourceIsObjectMasterFileIndex
				? INPUT_FIELD_TYPES.DROP_DOWN.render({
						label: "Link To Source",
						value: linkToSource,
						optionGroups: [
							{
								title: "Link To Source",
								options: [
									{ value: 0, label: "Select Source" },
									{ value: "data-warehouse", label: "Data Warehouse Master File Index" },
									{ value: "object-master-file-index", label: "Object Master File Index" },
								],
							},
						],
						handleChange: (val) => {
							setLinkToSource(val);
							updateRow("linkToSource", val);
						},
						focus,
				  })
				: ""}
			{linkToSource === "data-warehouse" || linkToSourceIsDataWarehouse ? (
				<>
					{/*Object Type*/}
					<TitleAutoComplete
						name={"title"}
						label={"Object Type"}
						value={objectType?.title}
						options={objectTypes}
						// objectOptions
						handleChange={handleObjectTypeChange}
						handleBlur={handleObjectTypeChange}
						allowNew={false}
						clearOnBlur={false}
						// error={!objectTypeUuid + ""}
						className={"full-width full-height"}
						focus={focus}
						// helperText={!objectTypeUuid ? 'You must choose a type from the list or leave it empty' : ''}
					/>

					{/*Associated Object Attributes*/}
					{INPUT_FIELD_TYPES.LIST.render({
						label: "Filtered Attributes",
						children: (
							<ol>
								{Object.keys(checked).length > 0
									? Object.keys(checked)
											.filter((key) => checked[key])
											.map((attribute) => <li>{attribute}</li>)
									: ""}
							</ol>
						),
						plusClick: () => setShowObjectAttributeDialog(true),
					})}

					{/*Associated Object Attribute Filter Criteria*/}
					{INPUT_FIELD_TYPES.LIST.render({
						label: "Criteria",
						children: (
							<ol>
								{attributeFilter?.children1?.length
									? attributeFilter.children1.map((attribute) => (
											<li>
												{attribute.properties.field +
													": " +
													attribute.properties.operator +
													' "' +
													attribute.properties.value +
													'"'}
											</li>
									  ))
									: ""}
							</ol>
						),
						plusClick: filterClicked,
					})}

					{/*Cardinality*/}
					{showCardinality ? (
						<BootstrapSelect
							label={"Cardinality"}
							value={cardinality}
							optionGroups={[
								{
									title: "Cardinalities",
									options: [
										{ value: 0, label: "Select Cardinality" },
										{ value: 1, label: 1 },
										{ value: "0+", label: "0 or more" },
									],
								},
							]}
							handleChange={handleCardinalityChange}
						/>
					) : (
						""
					)}
				</>
			) : (
				""
			)}

			<DraggableDialog
				style={{ minHeight: "600px", minWidth: "1200px" }}
				open={showObjectAttributeDialog}
				handleClose={() => setShowObjectAttributeDialog(false)}
				header={"Filter the Available Attributes"}
				saveButton={false}
				cancelButtonText={"Close"}
				// handleSave={attributeFilterSave}
				PaperProps={{ style: { minWidth: "900px", width: "75%" } }}
			>
				<CheckboxAndFilterList
					attributes={attributes}
					checked={checked}
					updateAttributeFilter={updateAttributeFilter}
					filter={attributeFilter}
				/>
			</DraggableDialog>

			<AttributeFilterDialog
				attributes={attributes}
				filter={attributeFilter}
				showing={showFilter}
				objectTitle={objectType?.title}
				handleClose={() => setShowFilter(false)}
				updateFilter={onFilterChange}
			/>
		</div>
	);
};

export default React.memo(AssociationSetupType, (prevProps, nextProps) => {
	let {
		_cardinality: prevCardinality,
		_linkToSource: prevLinkToSource,
		fitlerUuid: prevFilterUuid,
		objectType: prevObjectType,
	} = prevProps;
	let {
		_cardinality: nextCardinality,
		_linkToSource: nextLinkToSource,
		fitlerUuid: nextFilterUuid,
		objectType: nextObjectType,
	} = nextProps;

	if (
		prevCardinality !== nextCardinality ||
		prevLinkToSource !== nextLinkToSource ||
		prevFilterUuid !== nextFilterUuid ||
		prevObjectType?.uuid !== nextObjectType?.uuid ||
		prevObjectType?.versionUuid !== nextObjectType?.versionUuid
	)
		return false;

	return true;
});

const InitialConfig = BasicConfig;

// You need to provide your own config. See below 'Config format'
const config = {
	...InitialConfig,
	fields: {},
};

const AttributeFilterDialog = ({
	objectTitle = "",
	attributes,
	filter = {},
	updateFilter: _updateFilter,
	showing,
	handleClose,
}) => {
	const queryValue = useRef({ id: QbUtils.uuid(), type: "group" });
	const getNewState = (config) => {
		return {
			tree: QbUtils.checkTree(QbUtils.loadTree(queryValue.current), config),
			config: config,
		};
	};

	const [state, setState] = useState(getNewState(config));

	const loadedAttribute = useRef({});

	/**
	 * Update the config for this field
	 */
	useEffect(() => {
		if (filter && Object.keys(filter).length > 0) {
			queryValue.current = filter;
		}
		if (attributes?.length > 0) {
			let fields = {};
			attributes.forEach((attr) => {
				let newField = buildField(attr);
				fields = { ...fields, ...newField };
			});
			config.fields = fields;

			//Add default field if there is not a filter already
			if (Object.keys(filter).length < 1) {
				let { valueType, defaultValue } = getValueTypeAndDefaultValue(attributes[0]);
				let defaultRule = buildDefaultRule(attributes[0].title, [valueType], defaultValue);
				queryValue.current.children1 = [defaultRule];
			}
			setState(getNewState(config));
		}
	}, [attributes, attributes?.length]);

	/**
	 * Builds a default rule based on the parameters passed in
	 */
	const buildDefaultRule = (title, valueType, defaultValue) => {
		return {
			type: "rule",
			id: QbUtils.uuid(),
			properties: {
				field: title,
				operator: "equal",
				value: [defaultValue],
				valueSrc: ["value"],
				valueType,
			},
		};
	};

	/**
	 * Takes in an attribute and returns the valueType and default value for the attribute
	 * @param attribute:
	 */
	const getValueTypeAndDefaultValue = (attribute) => {
		let valueType = "",
			defaultValue = "",
			fieldSettings = {};
		switch (attribute.inputType) {
			case INPUT_FIELD_TYPES.DROP_DOWN.value:
				valueType = "select";
				let listValues = attribute.setupOptions.split(",").map((option) => ({ value: option, title: option }));
				//What should we do here if there are no setupOptions?
				defaultValue = listValues[0];
				fieldSettings = {
					listValues,
				};
				break;
			case INPUT_FIELD_TYPES.CHECK_BOX.value:
				valueType = "boolean";
				defaultValue = true;
				break;
			case INPUT_FIELD_TYPES.TEXT_AREA.value:
			case INPUT_FIELD_TYPES.INCLUDE_SPECIAL_CHARACTERS.value:
			case INPUT_FIELD_TYPES.ALPHA_NUMERIC.value:
				valueType = "text";
				break;
			case INPUT_FIELD_TYPES.NUMERIC.value:
				valueType = "number";
				defaultValue = 0;
				//TODO: technically this should be defined in the attribute not here
				// fieldSettings = {
				//     min: 0
				// }
				// preferWidgets: ["number"]
				break;
			case INPUT_FIELD_TYPES.DATE.value:
				valueType = "date";
				defaultValue = new Date().toString();
				break;
			default:
				valueType = "text";
		}
		return {
			valueType,
			defaultValue,
			fieldSettings,
		};
	};

	/**
	 * Build the field config based on the attributes passed in and their source type
	 * @param attribute: The attribute we are building the field for
	 */
	const buildField = (attribute) => {
		let { valueType, fieldSettings } = getValueTypeAndDefaultValue(attribute);
		return {
			[attribute.title]: {
				label: attribute.title,
				type: valueType,
				valueSources: ["value"],
				fieldSettings,
			},
		};
	};

	const onChange = useCallback((immutableTree, config) => {
		// Tip: for better performance you can apply `throttle` - see `examples/demo`
		setState((prevState) => ({ ...prevState, tree: immutableTree, config: config }));

		const jsonTree = QbUtils.getTree(immutableTree);
		queryValue.current = jsonTree;
	}, []);

	const updateFilter = () => {
		_updateFilter(queryValue.current);
	};

	const renderBuilder = useCallback(
		(props) => (
			<div className="query-builder-container" style={{}}>
				<div className="query-builder">
					<Builder {...props} />
				</div>
			</div>
		),
		[]
	);

	return (
		<DraggableDialog
			header={"Filter to Apply to " + objectTitle}
			showDialog={showing}
			handleClose={handleClose}
			handleSave={updateFilter}
		>
			<div>
				<Query {...config} value={state.tree} onChange={onChange} renderBuilder={renderBuilder} />
			</div>
		</DraggableDialog>
	);
};
