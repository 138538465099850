import { useEffect, useRef, useState } from "react";
import { useDispatch, useTrackedState } from "../../utils/store";
import useKeypress from "../../utils/useKeyPress";
import { Eraser, GripVertical, Pencil, UndoIcon } from "../BootstrapComponents/Icons/Icons";
import BootstrapTextarea from "../BootstrapComponents/Textarea/BootstrapTextarea";
import html2canvas from "html2canvas";
import CanvasDraw from "./CanvasDraw";
import mergeImages from "merge-images";
import { fileServiceUrl } from "../../utils/FileServiceUtils";
import { wopiHost } from "../../utils/WopiServiceUtils";
import { createNewOpenPoint, createNewOpenPointFromObject } from "../OpenPointForm/OpenPoints";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import { saveOpenPoints } from "../../utils/ApiUtils";

const DrawableCanvas = ({ closeCanvas, imgSrc }) => {
	const controlsRef = useRef();
	const draggableAreaRef = useRef();
	const canvasRef = useRef();
	const dispatch = useDispatch();
	const [brushColor, setBrushColor] = useState("#FF0000");
	const [brushRadius, setBrushRadius] = useState(5);
	const [eraseMode, setEraseMode] = useState(false);
	const [eraseBrushRadius, setEraseBrushRadius] = useState(50);

	const messageRef = useRef("");
	const sharedState = useTrackedState();

	useKeypress(["Escape"], () => {
		closeCanvas();
	});

	useKeypress(["s"], (e) => {
		if (e.ctrlKey === true) {
			e.preventDefault();
			save();
		}
	});

	useEffect(() => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		dragElement();
	}, []);

	const save = () => {
		dispatch({
			type: "SHOW_SUCCESS_MESSAGE",
			data: "Thank you for your feedback!",
		});
		html2canvas(canvasRef.current.canvas.drawing, { backgroundColor: null }).then((canvas) => {
			canvas.toBlob((blob) => {
				console.log("Save Data: ", blob);
				let url = window.URL.createObjectURL(blob);
				mergeImages([imgSrc, url]).then((b64) => {
					fetch(b64)
						.then((res) => res.blob())
						.then((blob) => {
							const fd = new FormData();
							const file = new File([blob], "Feedback.png");
							fd.append("file", file);
							fetch(fileServiceUrl(), { method: "POST", body: fd }).then((res) =>
								res.json().then((data) => {
									createNewOpenPointFromObject(sharedState, dispatch).then((newOpenPoint) => {
										newOpenPoint.location = `${wopiHost()}/wopi/files/${data.id}`;
										newOpenPoint.description = messageRef.current;
										saveOpenPoints([newOpenPoint]);
									});
								})
							);
						});
				});
				closeCanvas();
			});
		});
	};

	const dragElement = () => {
		var pos1 = 0,
			pos2 = 0,
			pos3 = 0,
			pos4 = 0;

		const elementDrag = (e) => {
			e = e || window.event;
			e.preventDefault();
			// calculate the new cursor position:
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			// set the element's new position:
			controlsRef.current.style.top = controlsRef.current.offsetTop - pos2 + "px";
			controlsRef.current.style.left = controlsRef.current.offsetLeft - pos1 + "px";
		};

		function closeDragElement() {
			// stop moving when mouse button is released:
			document.onmouseup = null;
			document.onmousemove = null;
		}

		const dragMouseDown = (e) => {
			e = e || window.event;
			e.preventDefault();
			// get the mouse cursor position at startup:
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			// call a function whenever the cursor moves:
			document.onmousemove = elementDrag;
		};
		draggableAreaRef.current.onmousedown = dragMouseDown;
	};

	return (
		<>
			<CanvasDraw
				ref={canvasRef}
				imgSrc={imgSrc}
				canvasWidth={document.body.clientWidth}
				canvasHeight={document.body.clientHeight}
				closeCanvas={closeCanvas}
				brushColor={brushColor}
				lazyRadius={2}
				erase={eraseMode}
				brushRadius={eraseMode ? eraseBrushRadius : brushRadius}
			/>
			<div
				ref={controlsRef}
				style={{
					position: "fixed",
					left: "50%",
					top: 0,
					zIndex: 16,
					padding: "10px",
					paddingRight: "40px",
					border: "1px solid gray",
					boxShadow: "3px 3px 10px gray",
					backgroundColor: "white",
					borderRadius: "8px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "700px",
					flexWrap: "wrap",
				}}
			>
				<button
					className={`btn ${eraseMode ? "btn-primary" : "btn-light"}`}
					title="Erase Mode"
					onClick={() => setEraseMode(true)}
				>
					<Eraser />
				</button>
				<button
					className={`btn ${eraseMode ? "btn-light" : "btn-primary"}`}
					title="Draw Mode"
					onClick={() => setEraseMode(false)}
				>
					<Pencil />
				</button>
				<div style={{ width: "30%", textAlign: "center" }}>
					<label htmlFor="brushSize" className="form-label" style={{ margin: "0" }}>
						Brush Size
					</label>
					<input
						type="range"
						id="brushSize"
						className="form-range"
						min="1"
						value={eraseMode ? eraseBrushRadius : brushRadius}
						onChange={(e) => {
							eraseMode ? setEraseBrushRadius(e.target.value) : setBrushRadius(e.target.value);
						}}
					></input>
				</div>

				<input
					type="color"
					value={brushColor}
					title="Change color"
					onChange={(e) => setBrushColor(e.target.value)}
				/>

				<button className="btn btn-dark" onClick={() => canvasRef.current.undo()} title="Undo">
					<UndoIcon />
				</button>
				<button className="btn btn-warning" onClick={() => canvasRef.current.clear()}>
					Clear
				</button>
				<button className="btn btn-success" onClick={save}>
					Submit
				</button>
				<button className="btn btn-danger" onClick={closeCanvas}>
					Cancel
				</button>
				<div
					style={{
						position: "absolute",
						top: "0",
						right: "0",
						bottom: "0",
						cursor: "move",
						width: "25px",
						borderLeft: "1px solid black",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					ref={draggableAreaRef}
				>
					<div style={{}}>
						<GripVertical />
					</div>
				</div>
				<div style={{ width: "100%" }}>
					{INPUT_FIELD_TYPES.TEXT_AREA.render({
						title: "Leave a message here",
						handleBlur: (newVal) => (messageRef.current = newVal),
					})}
				</div>
			</div>
		</>
	);
};

export default DrawableCanvas;
