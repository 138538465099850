import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useTrackedState } from "../../utils/store";

import "./VersionControlLog.scss";
import { camelize } from "../../utils/StringUtils";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";

const FINAL_REVIEW = "Final Review";
const FINAL_REVIEW_DATE = "Final Review Date";
const REVIEWED_BY = "Reviewed By";
const REVIEWED_BY_DATE = "Reviewed By Date";
const PREPARED_BY = "Prepared By";
const PREPARED_BY_DATE = "Prepared By Date";
const INCLUDED = "Included";
const NEW_REFERENCE = "New Reference";
const BUSINESS_MODEL_REFERENCE = "Business Model Reference";
const OLD_REFERENCE = "Old Reference";
const DOCUMENT = "Document";
const CHANGES_MADE = "Changes Made";
const DOCUMENT_REVISION_DATE = "Document Revision Date";
const DOCUMENT_VERSION_NUMBER = "Document Version Number";
const MODEL_VERSION_NUMBER = "Model Version Number";
const MODEL_PATCH_NUMBER = "Model Version Number";
const MODEL_REVISION_DATE = "Model Revision Date";

/**
 * Takes in an open point and returns the change request form view of it
 * @param { ref, header = false, tree = false }
 * @constructor
 */
export const VersionControlLog = ({ versionControlLog, updateRow, changes: _changes }) => {
	const [render, setRender] = useState(false);
	const [changes, setChanges] = useState([]);

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	//Get the change logs object info
	useEffect(() => {
		if (versionControlLog?.uuid && versionControlLog?.versionUuid) getVersionControlLogData();
	}, [versionControlLog?.uuid, versionControlLog?.versionUuid, _changes.length]);

	const getVersionControlLogData = async () => {
		if (_changes?.length < 1) {
			return;
		}

		let arr = [];
		//Iterate over the list of changes and build the object for each one
		_changes.forEach((changeMfi) => {
			let obj = { uuid: changeMfi[0].uuid };
			obj.finalReview = changeMfi.find((row) => row.title === FINAL_REVIEW).value;
			obj.finalReviewDate = changeMfi.find((row) => row.title === FINAL_REVIEW_DATE).value;
			obj.reviewedBy = changeMfi.find((row) => row.title === REVIEWED_BY).value;
			obj.reviewedByDate = changeMfi.find((row) => row.title === REVIEWED_BY_DATE).value;
			obj.preparedBy = changeMfi.find((row) => row.title === PREPARED_BY).value;
			obj.preparedByDate = changeMfi.find((row) => row.title === PREPARED_BY_DATE).value;
			obj.included = changeMfi.find((row) => row.title === INCLUDED).value;
			obj.newReference = changeMfi.find((row) => row.title === NEW_REFERENCE).value;
			obj.businessModelReference = changeMfi.find((row) => row.title === BUSINESS_MODEL_REFERENCE).value;
			obj.oldReference = changeMfi.find((row) => row.title === OLD_REFERENCE).value;
			obj.document = changeMfi.find((row) => row.title === DOCUMENT).value;
			obj.changesMade = changeMfi.find((row) => row.title === CHANGES_MADE).value;
			obj.documentRevisionDate = changeMfi.find((row) => row.title === DOCUMENT_REVISION_DATE).value;
			obj.documentVersionNumber = changeMfi.find((row) => row.title === DOCUMENT_VERSION_NUMBER).value;
			obj.modelVersionNumber = changeMfi.find((row) => row.title === MODEL_VERSION_NUMBER).value;
			obj.modelPatchNumber = changeMfi.find((row) => row.title === MODEL_PATCH_NUMBER).value;
			obj.modelRevisionDate = changeMfi.find((row) => row.title === MODEL_REVISION_DATE).value;
			arr.push(obj);
		});
		setChanges(arr);
	};

	const updateChangeRecord = (change, attribute, newVal) => {
		let { uuid } = change;
		let mfiToUpdate = _changes.find((mfi) => mfi[0].uuid === uuid);
		let rowToUpdate = mfiToUpdate.find((row) => row.title === attribute);
		if (rowToUpdate.value === newVal) return;

		rowToUpdate.value = newVal;
		change[attribute] = newVal;
		updateRow(rowToUpdate, mfiToUpdate[0]);
	};

	return (
		<div className="versionControlLog" style={{ height: "100%", overflow: "auto" }}>
			<div className="row d-flex">
				<div style={{ width: "60px", backgroundColor: "hsl(147, 100%, 35%)" }}></div>
				<div className="col align-items-end">
					<h3>= Changes made for this version or patch</h3>
				</div>
			</div>
			<table className="table table-sm table-bordered border-dark">
				<thead>
					<tr>
						<td>1</td>
						<td>2</td>
						<td>3</td>
						<td>4</td>
						<td>5</td>
						<td>6</td>
						<td>7</td>
						<td>8</td>
						<td>9</td>
						<td>10</td>
						<td>11</td>
						<td>12</td>
						<td>13</td>
						<td>14</td>
						<td>15</td>
						<td>16</td>
					</tr>
					<tr>
						<td className="vertical">Final Review</td>
						<td className="vertical">Date</td>
						<td className="vertical">Reviewed By</td>
						<td className="vertical">Date</td>
						<td className="vertical">Prepared By</td>
						<td className="vertical">Date</td>
						<td className="vertical">Included</td>
						<td className="text-danger">New Reference</td>
						<td className="text-danger">Old Reference</td>
						<td>Document</td>
						<td>
							UBM Change Type:
							<br />
							Checklist/Protocol/ Procedures Syntax Changes Made
						</td>
						<th>Document Revision Date</th>
						<th>Document Version #</th>
						<th>Model Version</th>
						<th>Model Patch #</th>
						<th>Model Revision Date</th>
					</tr>
				</thead>
				<tbody>
					{changes.map((change) => (
						<tr>
							<td>
								{/*	Final Review */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.finalReview,
									handleBlur: (newVal) => updateChangeRecord(change, FINAL_REVIEW, newVal),
									classes: "invisible-input full-width full-height",
								})}
							</td>
							<td>
								{/*	Final Review Date */}
								{INPUT_FIELD_TYPES.DATE.render({
									value: change.finalReviewDate,
									handleBlur: (newVal) => updateChangeRecord(change, FINAL_REVIEW_DATE, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Reviewed By */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.reviewedBy,
									handleBlur: (newVal) => updateChangeRecord(change, REVIEWED_BY, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Reviewed Date */}
								{INPUT_FIELD_TYPES.DATE.render({
									value: change.reviewedByDate,
									handleBlur: (newVal) => updateChangeRecord(change, REVIEWED_BY_DATE, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Prepared By */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.preparedBy,
									handleBlur: (newVal) => updateChangeRecord(change, PREPARED_BY, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Prepared By Date */}
								{INPUT_FIELD_TYPES.DATE.render({
									value: change.preparedByDate,
									handleBlur: (newVal) => updateChangeRecord(change, PREPARED_BY_DATE, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Included */}
								{INPUT_FIELD_TYPES.CHECK_BOX.render({
									value: change.included,
									handleChange: (newVal) => updateChangeRecord(change, REVIEWED_BY_DATE, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	New Reference */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.newReference,
									handleBlur: (newVal) => updateChangeRecord(change, NEW_REFERENCE, newVal),
									classes: "invisible-input",
								})}
							</td>
							{/*<td>*/}
							{/*	/!*	Business Model Reference *!/*/}
							{/*	{*/}
							{/*		INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({*/}
							{/*			value: change.businessModelReference,*/}
							{/*			handleBlur: (newVal) => updateChangeRecord(change, BUSINESS_MODEL_REFERENCE, newVal),*/}
							{/*			classes: 'invisible-input'*/}
							{/*		})*/}
							{/*	}*/}
							{/*</td>*/}
							<td>
								{/*	Old Reference */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.oldReference,
									handleBlur: (newVal) => updateChangeRecord(change, OLD_REFERENCE, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Document */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.document,
									handleBlur: (newVal) => updateChangeRecord(change, DOCUMENT, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Changes Made */}
								{INPUT_FIELD_TYPES.TEXT_AREA.render({
									value: change.changesMade,
									handleBlur: (newVal) => updateChangeRecord(change, CHANGES_MADE, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Document Revision Date */}
								{INPUT_FIELD_TYPES.DATE.render({
									value: change.documentRevisionDate,
									handleBlur: (newVal) => updateChangeRecord(change, DOCUMENT_REVISION_DATE, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Document Version Number */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.documentVersionNumber,
									handleBlur: (newVal) => updateChangeRecord(change, DOCUMENT_VERSION_NUMBER, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Model Version Number */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.modelVersionNumber,
									handleBlur: (newVal) => updateChangeRecord(change, MODEL_VERSION_NUMBER, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Model Patch Number */}
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									value: change.modelPatchNumber,
									handleBlur: (newVal) => updateChangeRecord(change, MODEL_PATCH_NUMBER, newVal),
									classes: "invisible-input",
								})}
							</td>
							<td>
								{/*	Model Revision Date */}
								{INPUT_FIELD_TYPES.DATE.render({
									value: change.modelRevisionDate,
									handleBlur: (newVal) => updateChangeRecord(change, MODEL_REVISION_DATE, newVal),
									classes: "invisible-input",
								})}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
