import { lex } from "./LexerUtil";
import { ExpressionParser } from "./ParserUtil";

// A new parser instance with CST output (enabled by default).
const parserInstance = new ExpressionParser();
// The base visitor class can be accessed via the a parser instance.
const BaseExpressionVisitor = parserInstance.getBaseCstVisitorConstructor();

class ExpressionToAstVisitor extends BaseExpressionVisitor {
	constructor() {
		super();
		this.validateVisitor();
	}

	expression(ctx) {
		return ctx;
	}

	fn(ctx) {
		return ctx;
	}

	parameters(ctx) {
		return {
			type: "PARAMETERS",
			parameters: ctx,
		};
	}
}

// Our visitor has no state, so a single instance is sufficient.
const toAstVisitorInstance = new ExpressionToAstVisitor();

const toAst = (inputText) => {
	const lexResult = lex(inputText);

	// ".input" is a setter which will reset the parser's internal's state.
	parserInstance.input = lexResult.tokens;

	// Automatic CST created when parsing
	const cst = parserInstance.expression();

	if (parserInstance.errors.length > 0) {
		throw Error("Sad sad panda, parsing errors detected!\n" + parserInstance);
	}

	const ast = toAstVisitorInstance.visit(cst);

	return ast;
};
export { toAst };
